import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactGridLayout, { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";

import { ApolloQueryResult } from "@apollo/client";
import styled, { useTheme } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_TABLE_LABEL,
} from "../../colors";
import {
  DEFAULT_ROW_HEIGHT,
  DEFAULT_VERTICAL_ROW_MARGIN,
  MAX_COLUMN_COUNT,
} from "../../constants/ReportGrid";
import { useUserContext } from "../../contexts/User";
import {
  ReportTemplate,
  useSaveReportTemplateMutation,
  useWidgetTemplatesQuery,
  WidgetTemplatesQuery,
} from "../../graphql";
import {
  useBreakpoint,
  usePrepareLayoutForSaving,
  useScrollToWidget,
  useBottomWidgetResizeData,
  useGetSelectedAccountIdInMonitoring,
} from "../../hooks";
import { Loader } from "../Loader";
import { AthleteWidget, WidgetType } from "../ReportCreateTable/models";
import StyledText from "../StyledText";

import { MobileViewGridLayout } from "./styled";
import { WidgetMode, WidgetWithData } from "./WidgetWithData";

const GridWrapper = styled.div<{ hasContent?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-width: 0.5px;
  border-color: ${COLOR_GREY_ACTIVE};
  border-style: solid;
  border-bottom-width: 0;
  min-height: ${({ hasContent }) => (hasContent ? "360px" : "100vh")};
  justify-content: flex-start;
`;

const NoWidgetsText = styled(StyledText)`
  text-align: center;
  font-size: 16px;
  margin-top: 80px;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const ResponsiveGridLayout = WidthProvider(Responsive);

const DEFAULT_GRID_SM_BREAKPOINT_WIDTH = 768;

interface TabReportProps {
  parentReportId: string;
  tabReportTemplates: ReportTemplate;
  generalReportWidgetTemplates: AthleteWidget[];
  isEditMode: boolean;
  refetchGeneralWidgets: () => Promise<ApolloQueryResult<WidgetTemplatesQuery>>;
}

export const TabReport = ({
  tabReportTemplates,
  generalReportWidgetTemplates,
  parentReportId,
  isEditMode,
  refetchGeneralWidgets,
}: TabReportProps) => {
  const layoutRef = useRef();
  const layoutGridRef = useRef<any>();
  const { isMobile } = useTheme();

  const [currentGridWidth, setCurrentGridWidth] = useState<number | null>(null);

  const { t } = useTranslation();
  const [shouldResetLayout, setShouldResetLayout] = useState(false);

  const { sessionId, language } = useUserContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { normalizeLayout } = usePrepareLayoutForSaving();

  const currentBreakpoint = useBreakpoint();

  const {
    itemsToResize,
    onSetItemToResize,
    onSetItemsToHideInViewMode,
    includeInViewModeFilter,
    resetItemsToResize,
  } = useBottomWidgetResizeData({ isEditMode });

  const [saveReport] = useSaveReportTemplateMutation();

  // We need this to prevent recalculation of grid on mobile devices because react-grid-layout do not use traditional width of screen
  useEffect(() => {
    setCurrentGridWidth(layoutGridRef?.current?.state?.width);
  }, [layoutGridRef?.current?.state?.width]);

  useEffect(() => {
    if (shouldResetLayout) {
      setShouldResetLayout(false);
      resetItemsToResize();
    }
  }, [shouldResetLayout, setShouldResetLayout, resetItemsToResize]);

  const widgetTemplatesVariables = useMemo(
    () => ({
      sessionId,
      accountId: selectedAccountId,
      parentReport: tabReportTemplates?.id,
      language,
    }),
    [language, sessionId, tabReportTemplates?.id, selectedAccountId]
  );

  const { data: widgetsData, loading: widgetsLoading } =
    useWidgetTemplatesQuery({
      variables: widgetTemplatesVariables,
      skip: !tabReportTemplates?.id,
      fetchPolicy: "no-cache",
    });

  const widgets = useMemo(
    () =>
      Object.fromEntries(
        (widgetsData?.reportWidgetTemplates || []).map((widget) => [
          // @ts-ignore
          widget.id,
          {
            ...widget,
            ...("preferences" in widget
              ? JSON.parse(widget.preferences)
              : undefined),
          },
        ])
      ),
    [widgetsData]
  );

  const { getWidgetElementId } = useScrollToWidget(
    !tabReportTemplates?.id || widgetsLoading
  );

  const layout = useMemo<ReactGridLayout.Layout[]>(() => {
    if (tabReportTemplates?.preferences) {
      const { layout } = JSON.parse(tabReportTemplates?.preferences);

      return normalizeLayout(layout || [])
        .map((item) => {
          const resizeItem = itemsToResize.find(
            (resizeItem) => resizeItem.itemId === item.i
          );
          return {
            ...item,
            h:
              resizeItem && !isEditMode
                ? Math.ceil(resizeItem.scrollHeight / DEFAULT_ROW_HEIGHT)
                : item.h,
            isDraggable: item.i.includes(WidgetType.PLACEHOLDER)
              ? false
              : isEditMode,
            isResizable: item.i.includes(WidgetType.PLACEHOLDER)
              ? false
              : isEditMode,
            // static means isDraggable false and isResizable false ->
            // we have this in fact, but collision prevention system needs static to true to work better
            // it is like bug in react-grid lib -> see RB-704
            static: item.i.includes(WidgetType.PLACEHOLDER) && !isEditMode,
          };
        })
        .filter((item) => includeInViewModeFilter(item.i))
        .sort((firstWidget, secondWidget) => firstWidget.y - secondWidget.y);
    }

    return [];
  }, [
    tabReportTemplates,
    isEditMode,
    itemsToResize,
    normalizeLayout,
    includeInViewModeFilter,
  ]);

  const onLayoutChange = useCallback(
    async (layout: ReactGridLayout.Layout[]) => {
      const preferences = JSON.parse(tabReportTemplates?.preferences);

      await saveReport({
        variables: {
          sessionId,
          accountId: selectedAccountId,
          input: {
            preferences: JSON.stringify({
              ...preferences,
              layout:
                currentGridWidth > DEFAULT_GRID_SM_BREAKPOINT_WIDTH
                  ? normalizeLayout(layout)
                  : layout,
            }),
            id: tabReportTemplates?.id,
          },
        },
      });
    },
    [
      sessionId,
      selectedAccountId,
      tabReportTemplates?.id,
      currentGridWidth,
      normalizeLayout,
      saveReport,
      tabReportTemplates?.preferences,
    ]
  );

  if (!layout) return <span>Broken layout</span>;

  const shouldRenderWidgetsWithoutGrid = isMobile && !isEditMode;

  const GridLayoutComponent = shouldRenderWidgetsWithoutGrid
    ? MobileViewGridLayout
    : ResponsiveGridLayout;

  // Show no widgets only if we have no widgets from server, not in case we recalculate filtered widgets
  return (
    <GridWrapper
      // we need this to prevent scrolling to top on mobile while changing tab report
      hasContent={
        layout.length > 0 ||
        (layout.length === 0 &&
          widgetsData?.reportWidgetTemplates?.length === 0)
      }
    >
      {layout.length === 0 &&
      widgetsData?.reportWidgetTemplates?.length === 0 ? (
        <NoWidgetsText>{t("noWidgets")}</NoWidgetsText>
      ) : shouldResetLayout || widgetsLoading ? (
        <LoaderWrapper>
          <Loader size="large" color={COLOR_BLUE} />
        </LoaderWrapper>
      ) : (
        <GridLayoutComponent
          ref={layoutGridRef}
          innerRef={layoutRef as any}
          onDragStop={onLayoutChange}
          onResizeStop={onLayoutChange}
          onBreakpointChange={() => {
            setShouldResetLayout(true);
          }}
          width={1200}
          rowHeight={DEFAULT_ROW_HEIGHT}
          cols={{
            xxs: 1,
            xs: 1,
            sm: MAX_COLUMN_COUNT,
            md: MAX_COLUMN_COUNT,
            lg: MAX_COLUMN_COUNT,
          }}
          layouts={{ lg: layout }}
          containerPadding={{
            xxs: [0, 10],
            xs: [0, 10],
            sm: [0, 10],
            md: [10, 10],
            lg: [10, 10],
          }}
          margin={{
            xxs: [0, DEFAULT_VERTICAL_ROW_MARGIN],
            xs: [0, DEFAULT_VERTICAL_ROW_MARGIN],
            sm: [0, DEFAULT_VERTICAL_ROW_MARGIN],
            md: [10, DEFAULT_VERTICAL_ROW_MARGIN],
            lg: [10, DEFAULT_VERTICAL_ROW_MARGIN],
          }}
          useCSSTransforms={false}
          measureBeforeMount={true}
          style={{ minHeight: "100%", zIndex: 0 }}
        >
          {layout.map((item, index) => {
            const widget = (widgets && widgets[item.i]) ?? {
              id: item.i ?? `${WidgetType.PLACEHOLDER}-${index}`,
              type: WidgetType.PLACEHOLDER,
            };

            const isHighChartWidget =
              widget.type === WidgetType.CHART ||
              widget.type === WidgetType.ACUTE_CHRONIC;

            const itemH =
              isMobile && widget.type === WidgetType.COMBINED_TEST
                ? item.h + 1
                : item.h;

            // Grid is not correctly updated when using layout prop
            // https://github.com/react-grid-layout/react-grid-layout/issues/870
            return (
              <div
                data-id={item.i}
                data-grid={item}
                key={item.i}
                id={getWidgetElementId(index)}
                className={`${isEditMode ? "widget-container" : ""}`}
                style={{
                  ...(shouldRenderWidgetsWithoutGrid
                    ? {
                        height: isHighChartWidget
                          ? itemH * DEFAULT_ROW_HEIGHT
                          : "auto",
                        marginBottom:
                          widget.type === WidgetType.PLACEHOLDER ? 0 : "32px",
                        marginTop:
                          widget.type === WidgetType.PLACEHOLDER ? 0 : "12px",
                      }
                    : {}),
                }}
              >
                <WidgetWithData
                  widget={{
                    ...widget,
                    columnXPosition:
                      currentBreakpoint !== "sm" && currentBreakpoint !== "xs"
                        ? item.x
                        : 0,
                    columnYPosition: item.y,
                  }}
                  report={{ ...tabReportTemplates, parentReportId }}
                  generalWidgets={generalReportWidgetTemplates}
                  layoutIndex={index}
                  readOnly={!isEditMode}
                  mode={WidgetMode.ATHLETE}
                  gridItemId={getWidgetElementId(index)}
                  setItemsToHideInViewMode={onSetItemsToHideInViewMode}
                  setItemToResize={onSetItemToResize}
                  layoutRef={layoutRef}
                  activeTabId={tabReportTemplates?.id}
                  refetchGeneralWidgets={refetchGeneralWidgets}
                />
              </div>
            );
          })}
        </GridLayoutComponent>
      )}
    </GridWrapper>
  );
};
