import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../../colors";
import { FilteredDocuments } from "../../../contexts/workout/WorkoutEditorProvider";
import { normalizeString } from "../../Filters/utils";
import { Spinner, SpinnerSize } from "../../Spinner";

import { FileItem, FileType } from "./FileItem";

type FileListProps = {
  loading: boolean;
  data: FilteredDocuments;
  collectionId: string;
  searchValue?: string;
  doNotFilter?: boolean;
  withExtraPaddingForFiles?: boolean;
  selectedFile?: FileType;
  onFileSingleClick?: (file: FileType) => void;
  onFileDoubleClick?: (file: FileType) => void;
  onFileDragging?: (isDragging: boolean) => void;
};

const EmptyFolder = styled.div`
  margin-left: 13px;
  padding: 5px 0;
  font-size: 12px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
`;

const FileWrapper = styled.div<{ withExtraPaddingForFiles?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: ${({ withExtraPaddingForFiles }) =>
    withExtraPaddingForFiles ? "23px" : "14px"};
`;

export function FileList({
  loading,
  data,
  collectionId,
  searchValue = "",
  doNotFilter,
  withExtraPaddingForFiles,
  selectedFile,
  onFileSingleClick,
  onFileDoubleClick,
  onFileDragging,
}: FileListProps) {
  const { t } = useTranslation();

  const filteredData = useMemo(() => {
    if (!searchValue || doNotFilter) {
      return data;
    }

    return data?.filter(({ node }) =>
      normalizeString(node.name ?? "").includes(normalizeString(searchValue))
    );
  }, [data, searchValue, doNotFilter]);

  if (loading) {
    return <Spinner size={SpinnerSize.SMALL} />;
  }

  if (!loading && !data?.length) {
    return <EmptyFolder>{t("emptyFolder")}</EmptyFolder>;
  }

  return (
    <FileWrapper withExtraPaddingForFiles={withExtraPaddingForFiles}>
      {filteredData?.map(({ node }) => (
        <FileItem
          key={node.id}
          file={node}
          collectionId={collectionId}
          isFavorite={node.isFavorite}
          isSelected={node.id === selectedFile?.id}
          onSingleClick={onFileSingleClick}
          onDoubleClick={onFileDoubleClick}
          onFileDragging={onFileDragging}
        />
      ))}
    </FileWrapper>
  );
}
