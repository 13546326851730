import {
  Document,
  Drill,
  InlineComment,
  PracticeInlineComment,
  Split,
  SubPractice,
} from "../../../graphql";

export enum ItemTypes {
  FILE = "file",
  FOLDER = "folder",
}

export enum TabLabel {
  PLAN = "plan",
  ATTACHMENTS = "attachments",
  FORMS = "forms",
}

export enum FolderStructureTabLabel {
  TESTS = "TEST",
  DRILL = "DRILL",
  EXERCISE_SET = "EXERCISE_SET",
}

type PracticeInlineCommentExtended = PracticeInlineComment & { txt?: string };

export type PracticeLine = (
  | Document
  | InlineComment
  | PracticeInlineCommentExtended
  | Drill
  | Split
  | SubPractice
) & {
  localDuration?: number;
  endTime?: string;
  minutes?: number;
  hasBeenMoved?: boolean;
  originalFileId?: string;
  subPractices?: SubPractice[];
};
