import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useTheme } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../../colors";
import { useUIContext } from "../../contexts/UI";
import { TopModalType, useTopModalContext } from "../../contexts/UI/TopModal";
import { useHandleClickOutside } from "../../hooks";
import { IconButton } from "../IconButton";
import { CrossIcon, NextIcon } from "../Icons";
import StyledText from "../StyledText";

import { AddResourceModal } from "./AddResourceModal";
import { ChangePasswordModal } from "./ChangePasswordModal";
import { CollectionQuestionnaire } from "./CollectionQuestionnaire";
import { ConfirmAccountSwitchModal } from "./ConfirmAccountSwitchModal";
import { ConfirmationModal } from "./ConfirmationModal";
import CreateChatGroupModal from "./CreateChatGroup";
import { CreateNewDrillModal } from "./CreateNewDrillModal";
import Document from "./Document.web";
import {
  BackButton,
  BackButtonLabel,
  BlockWithoutScrollBar,
  BottomBarWrapper,
  ContentWrapper,
  getModalHeight,
  getModalWidth,
  ModalWrapper,
  TitleContainer,
  wrapperStyle,
} from "./Modal";
import { Questionnaire } from "./Questionnaire";
import { SelectAthletesFromGroupModal } from "./SelectAthletesFromGroupModal";
import { SessionDetail } from "./SessionDetail";
import {
  EXCLUDED_AUTO_CLOSE_MODAL_LIST,
  TOP_MODALS_ALLOWED_ROUTES,
} from "./utils";

interface TopModalComponentProps {
  title?: ReactNode;
  children?: ((props) => ReactNode) | ReactNode;
}

const TopModalComponent: FunctionComponent<TopModalComponentProps> = ({
  children,
}) => {
  const {
    open,
    modal,
    params,
    actions,
    style = {},
    title,
    onBack,
  } = useTopModalContext();
  const topModalRef = useRef();
  const { isMobile } = useTheme();
  const ui = useUIContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [modalOriginalPathname, setModalOriginalPathname] = useState(null);

  const handleClickOutsideOfModal = () => {
    if (!EXCLUDED_AUTO_CLOSE_MODAL_LIST.includes(modal)) {
      actions.closeTopModal();
    }
  };

  useHandleClickOutside(topModalRef, handleClickOutsideOfModal);

  // close modal on route change
  useEffect(() => {
    if (
      modal &&
      ((TOP_MODALS_ALLOWED_ROUTES[modal] &&
        !TOP_MODALS_ALLOWED_ROUTES[modal].includes(pathname.substring(1))) ||
        (modalOriginalPathname && pathname !== modalOriginalPathname))
    ) {
      actions.closeTopModal();
      params?.closeModal?.();
      params?.closeTopModal?.();
      setModalOriginalPathname(null);
    }
    if (modal && modalOriginalPathname === null) {
      setModalOriginalPathname(pathname);
    }
  }, [modal, pathname, params, actions, modalOriginalPathname]);

  useEffect(() => {
    if (!open) {
      setModalOriginalPathname(null);
    }
  }, [open]);

  if (!open) {
    return null;
  }

  const handleCloseModal = () => {
    if (params.closeModal) {
      params.closeModal();
    } else {
      actions.closeTopModal();
    }

    params.onShow?.();
  };

  return (
    <ModalWrapper>
      <div
        ref={topModalRef}
        style={{
          ...wrapperStyle,
          backgroundColor: COLOR_WHITE,
          ...getModalHeight(modal),
          ...getModalWidth(modal, ui.width),
          ...style,
        }}
      >
        <ContentWrapper>
          {!!title && (
            <TitleContainer>
              {onBack && (
                <BackButton onClick={onBack}>
                  <NextIcon
                    direction="left"
                    tintColor={COLOR_BLUE}
                    style={{ width: 19, height: 19 }}
                  />
                  <BackButtonLabel>{t("back").toUpperCase()}</BackButtonLabel>
                </BackButton>
              )}
              {typeof title === "string" ? (
                <StyledText
                  fontSize={14}
                  fontWeight={700}
                  color={COLOR_TEXT_DEFAULT}
                  cursor="default"
                  style={{ lineHeight: "17px" }}
                >
                  {title}
                </StyledText>
              ) : (
                title
              )}
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  padding: 10,
                  ...(isMobile ? { top: 0 } : {}),
                }}
              >
                {!params?.disableCancel && (
                  <IconButton
                    icon={
                      <CrossIcon
                        strokeWidth="1"
                        tintColor={COLOR_TEXT_DARK}
                        style={{ width: 32, height: 32 }}
                      />
                    }
                    activeBgColor={COLOR_MEDIUM_GRAY}
                    onClick={handleCloseModal}
                  />
                )}
              </div>
            </TitleContainer>
          )}
          {typeof children === "function" ? (
            children({ params, closeModal: actions.closeTopModal })
          ) : (
            <BlockWithoutScrollBar
              bgColor={modal === TopModalType.SESSION_DETAIL && COLOR_GRAY}
            >
              {(() => {
                switch (modal) {
                  case TopModalType.CONFIRM:
                    return (
                      <ConfirmationModal
                        closeModal={actions.closeTopModal}
                        {...params}
                      />
                    );
                  case TopModalType.ADD_RESOURCE:
                    return (
                      <AddResourceModal
                        closeModal={actions.closeTopModal}
                        {...params}
                      />
                    );
                  case TopModalType.CREATE_CHAT_GROUP:
                    return (
                      <CreateChatGroupModal
                        closeModal={actions.closeTopModal}
                        {...params}
                      />
                    );
                  case TopModalType.SELECT_ATHLETES_FROM_GROUP:
                    return (
                      <SelectAthletesFromGroupModal
                        closeModal={actions.closeTopModal}
                        {...params}
                      />
                    );
                  case TopModalType.CHANGE_PASSWORD:
                    return <ChangePasswordModal {...params} />;
                  case TopModalType.DOCUMENT:
                  case TopModalType.PROGRAM_ITEM_WORKOUT:
                    return (
                      <Document
                        closeModal={actions.closeTopModal}
                        {...params}
                      />
                    );
                  case TopModalType.SESSION_DETAIL:
                    return <SessionDetail {...params} />;
                  case TopModalType.COLLECTION_QUESTIONNAIRE:
                    return <CollectionQuestionnaire {...params} />;
                  case TopModalType.QUESTIONNAIRE:
                    return <Questionnaire {...params} />;
                  case TopModalType.CONFIRM_ACCOUNT_SWITCH:
                    return (
                      <ConfirmAccountSwitchModal
                        closeModal={actions.closeTopModal}
                        {...params}
                      />
                    );
                  case TopModalType.CREATE_NEW_DRILL:
                    return <CreateNewDrillModal {...params} />;
                }
              })()}
            </BlockWithoutScrollBar>
          )}
        </ContentWrapper>
        {params.bottomBar ? (
          <BottomBarWrapper>{params.bottomBar}</BottomBarWrapper>
        ) : null}
      </div>
    </ModalWrapper>
  );
};

export default TopModalComponent;
