import React, { useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import moment from "moment";
import styled, { useTheme } from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_MEDIUM_GRAY } from "../colors";
import { useDefaultDateFormat } from "../hooks";

import { CrossIcon } from "./Icons";
import { ReactCalendar, ReactCalendarProps } from "./ReactCalendar";
import { StyledTextInput } from "./StyledTextInput";

export const CalendarComponentWrapper = styled.div<{
  withBorder?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  border-style: solid;
  border-width: ${({ withBorder }) => (withBorder ? "0.5px" : "0")};
  border-color: ${COLOR_GREY_ACTIVE};
  border-radius: 6px;
  overflow: ${({ theme }) => (theme.isMobile ? undefined : "hidden")};
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const CancelButton = styled.button`
  position: absolute;
  border: none;
  background: transparent;
  top: 15px;
  right: 20px;
  cursor: pointer;
`;

const InputLabel = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR_MEDIUM_GRAY};
  margin-bottom: 5px;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

// TODO: It is broken in mobile app
// const MobileStyledTextInput = styled(StyledTextInput)`
//   font-weight: 700;
//   font-size: 14px;
//   line-height: 17px;
// `;

export function CalendarWithDateInput({
  inputVisibility,
  calendarVisibility,
  inputLabel,
  ...props
}: ReactCalendarProps & {
  inputVisibility?: "hiddenOnMobile";
  calendarVisibility?: "visible";
  inputLabel?: string;
}) {
  const { dateFormat } = useDefaultDateFormat();
  const regexpFormat = dateFormat
    .split("")
    .map((char) => {
      if (["Y", "M", "D"].includes(char)) {
        return "\\d";
      }
      return char;
    })
    .join("");

  const { value, set } = props;
  const [textInputValue, setTextInputValue] = useState(
    value?.format(dateFormat) ?? ""
  );

  const { isMobile } = useTheme();
  const [showCalendar, setShowCalendar] = useState(!isMobile);

  const handleChangeText = useCallback(
    (textValue: string) => {
      setTextInputValue(textValue);
      if (regexpFormat && textValue.match(regexpFormat)) {
        const date = moment(textValue, dateFormat).valueOf();
        if (!Number.isNaN(date)) {
          set(dayjs(date));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [set, regexpFormat]
  );

  const handleBlur = useCallback(() => {
    setTextInputValue(value?.format(dateFormat) ?? "");
  }, [value, dateFormat]);

  useEffect(() => {
    setTextInputValue(value?.format(dateFormat) ?? "");
  }, [value, dateFormat]);

  const inputHiddenOnMobile = isMobile && inputVisibility === "hiddenOnMobile";

  return (
    <Container>
      {!inputHiddenOnMobile && (
        <>
          {inputLabel && <InputLabel>{inputLabel}</InputLabel>}
          <StyledTextInput
            placeholder={dateFormat}
            value={textInputValue}
            onChangeText={handleChangeText}
            onBlur={handleBlur}
            style={{ ...(isMobile ? { fontSize: 14 } : {}) }}
            onFocus={() => {
              if (isMobile) {
                setShowCalendar(true);
              }
            }}
          />
        </>
      )}

      {(showCalendar || calendarVisibility === "visible") && (
        <CalendarComponentWrapper withBorder={!isMobile}>
          <ReactCalendar {...props} />
          {isMobile && calendarVisibility !== "visible" && (
            <CancelButton onClick={() => setShowCalendar(false)}>
              <CrossIcon color={COLOR_MEDIUM_GRAY} />
            </CancelButton>
          )}
        </CalendarComponentWrapper>
      )}
    </Container>
  );
}
