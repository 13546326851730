import React, { CSSProperties } from "react";

import { Avatar as AvatarMui } from "@mui/material";
import styled, { css, keyframes } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_TABLE_LABEL,
} from "../colors";
import { ALL_ACCOUNTS_ID } from "../contexts/accessLevels";
import { useUserContext } from "../contexts/User";
import { getServerBasePath } from "../utils/getServerBasePath";

import StyledText from "./StyledText";

const wave = keyframes`
  0% {
    background-position: 0 50%;
}
  100% {
    background-position: 100% 50%;
}
`;

const overlayStyle = {
  backgroundColor: COLOR_BLUE,
  opacity: 0.25,
};

const Background = styled.div<{ isLoading?: boolean }>`
  display: flex;
  ${({ isLoading }) =>
    isLoading
      ? css`
          background: linear-gradient(
            -45deg,
            ${COLOR_GREY_ACTIVE},
            #c0c0c0,
            ${COLOR_GREY_ACTIVE},
            #c0c0c0,
            ${COLOR_GREY_ACTIVE},
            ${COLOR_GREY_ACTIVE}
          );
          animation: ${wave} 3s linear infinite;
          background-size: 530% 530%;
        `
      : css`
          background: ${COLOR_GREY_ACTIVE};
        `}
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
`;

const InsetBorder = styled.div`
  position: absolute;
  //z-index: 999; - because of overlay in reports during custom period selection
  border-color: #000;
  opacity: 0.2;
`;

interface AvatarProps {
  userGuid?: string;
  userName?: string;
  uri?: string | null;
  size?: "small" | "medium" | "large" | number;
  source?: { uri?: string };
  style?: CSSProperties;
  withOverlay?: boolean;
  isThumb?: boolean;
  shouldBeHidden?: boolean;
  userSessionId?: string;
  isLoading?: boolean;
  className?: string;
}

export default function Avatar({
  userGuid,
  userName,
  uri,
  size,
  source,
  style,
  withOverlay,
  isThumb,
  shouldBeHidden,
  userSessionId,
  isLoading,
  className,
}: AvatarProps) {
  const { sessionId } = useUserContext();

  const pxSize = getPixelSize(size);

  if (userGuid && userGuid !== ALL_ACCOUNTS_ID) {
    uri = `${getServerBasePath()}/avatars/${userGuid}.png?sessionId=${
      userSessionId || sessionId
    }`;

    if (isThumb) {
      uri = `${uri}&thumb=true`;
    }
  }

  if (uri) {
    source = { uri };
  }

  const sizeStyle = {
    width: pxSize,
    height: pxSize,
    borderRadius: pxSize,
  };

  return (
    <Background
      className={className}
      style={{
        ...sizeStyle,
        ...style,
        ...(shouldBeHidden ? { opacity: 0 } : {}),
      }}
      isLoading={isLoading}
    >
      <AvatarMui
        sx={{
          width: pxSize,
          height: pxSize,
          bgcolor: isLoading ? "transparent" : COLOR_GREY_ACTIVE,
        }}
        src={isLoading ? undefined : source?.uri}
      >
        <StyledText
          color={COLOR_TEXT_TABLE_LABEL}
          fontSize={(pxSize / 2) * 0.8}
          fontWeight={600}
        >
          {getNameInitials({ userName })}
        </StyledText>
      </AvatarMui>
      <InsetBorder
        style={{
          ...sizeStyle,
          ...(withOverlay && overlayStyle),
        }}
      />
    </Background>
  );
}

function getPixelSize(size: AvatarProps["size"]) {
  switch (size) {
    case "small":
      return 25;
    case "medium":
      return 40;
    case "large":
      return 60;
  }

  return size || 40;
}

function getNameInitials({ userName }: { userName?: string }) {
  const userNames = userName?.split(" ");

  if (userNames?.length) {
    const first = userNames[0].charAt(0).toUpperCase();
    const second =
      userNames.length > 1
        ? (userNames.pop()?.charAt(0).toUpperCase() ?? "")
        : "";
    return first + second;
  } else {
    return "";
  }
}
