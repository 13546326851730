import { useUserContext } from "../../contexts/User";
import {
  useCreateSplitMutation,
  useUpdateSubPracticeMutation,
  useUpdateSubPracticeParticipationMutation,
  SubPractice,
} from "../../graphql";

import { useSaveDrillOrComment } from "./useSaveDrillOrComment";

export const useCreateSplitItemWithStructure = () => {
  const { sessionId } = useUserContext();

  const [addSplitToPracticeMutation] = useCreateSplitMutation();
  const { saveDrillOrCommentPracticeLines } = useSaveDrillOrComment();
  const [updateSubPracticeMutation] = useUpdateSubPracticeMutation();
  const [updateSubPracticeParticipationMutation] =
    useUpdateSubPracticeParticipationMutation();

  const createSplitItemWithStructure = async (
    split: { subPractices: SubPractice[]; idx: number },
    practiceGuid: string
  ) => {
    try {
      const response = await addSplitToPracticeMutation({
        variables: {
          sessionId,
          input: {
            practiceGuid,
            nrOfSubPractices: split?.subPractices?.length,
            splitID: null,
            idx: split?.idx,
          },
        },
      });

      const subPracticesIds = response?.data?.createSplit?.subPractices?.map(
        (subPractice) => subPractice.id
      );

      // For each split add all drills in it
      for await (const [
        practiceSplitIndex,
        practiceSplit,
      ] of split.subPractices.entries()) {
        if (practiceSplit.comment) {
          await updateSubPracticeMutation({
            variables: {
              sessionId,
              input: {
                id: subPracticesIds[practiceSplitIndex],
                notes: practiceSplit.comment,
              },
            },
          });
        }

        try {
          await saveDrillOrCommentPracticeLines({
            practiceID: practiceGuid,
            subPracticeID: subPracticesIds[practiceSplitIndex],
            practiceLines: practiceSplit.practiceLines,
          });
        } catch (e) {
          console.error(e);
        }

        try {
          await updateSubPracticeMutation({
            variables: {
              sessionId,
              input: {
                id: subPracticesIds[practiceSplitIndex],
                notes: practiceSplit.comment,
              },
            },
          });
        } catch (e) {
          console.error(e);
        }

        if (practiceSplit.athletes?.length) {
          try {
            await updateSubPracticeParticipationMutation({
              variables: {
                input: {
                  added: practiceSplit.athletes.map((athlete) => athlete.id),
                  removed: [],
                  subPracticeID: subPracticesIds[practiceSplitIndex],
                },
              },
            });
          } catch (e) {
            console.error(e);
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return { createSplitItemWithStructure };
};
