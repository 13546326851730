import React, { CSSProperties, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../colors";
import { useWorkoutEditorContext } from "../../contexts/workout/WorkoutEditorProvider";
import { useNavigateBack } from "../../hooks/useNavigateBack";
import { getSearchParamsObject } from "../../utils/getSearchParamsObject";
import { BottomBar } from "../BottomBar";
import { ButtonColor } from "../Button/";
import Tabs, { Tab } from "../Tabs";

import { InfoForm } from "./Forms/Info";
import { TabLabel } from "./model";
import { PlanTab } from "./PlanTab";

const tabStyles: CSSProperties = {
  backgroundColor: COLOR_WHITE,
  height: 45,
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  paddingBottom: 5,
  borderBottomColor: COLOR_GREY_ACTIVE,
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  justifyContent: "center",
  width: "100%",
  borderLeftColor: COLOR_GREY_ACTIVE,
  borderLeftWidth: 1,
};

const oneTabStyle: CSSProperties = { marginBottom: 0 };

const Container = styled.div`
  flex: 1;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Content = styled.div<{ bottomOffset: boolean }>`
  border-left: 1px solid ${COLOR_GREY_ACTIVE};
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${({ bottomOffset }) => (bottomOffset ? 55 : 0)}px;
`;

const BottomBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${COLOR_WHITE};
  padding-inline: 20px;
  padding-block: 10px;
`;

export function WorkoutEditorForms({
  isDocumentEditorMinimized,
  tabs,
  tab,
  setTab,
}) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigateBack = useNavigateBack();
  const activeTabId = searchParams.get("activeTabId");
  const {
    workoutData,
    selectedDocuments,
    onSubmit,
    formMethods: { handleSubmit },
  } = useWorkoutEditorContext();

  useEffect(() => {
    if (activeTabId) {
      const newTab = tabs.find((tab) => tab.id === activeTabId);
      if (newTab) {
        setTab(newTab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabId]);

  const setNewTab = (arg: Tab) => {
    if (arg) {
      setTab(arg);
      setSearchParams(
        (current) => ({
          ...getSearchParamsObject(current),
          activeTabId: arg.id,
        }),
        { replace: true }
      );
    }
  };

  const renderContent = useMemo(() => {
    // always render to keep state
    return (
      <>
        <PlanTab
          isHidden={tab?.id !== TabLabel.PLAN}
          isDocumentEditorMinimized={isDocumentEditorMinimized}
        />
        <InfoForm isHidden={tab?.id !== TabLabel.INFO} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, workoutData, selectedDocuments, isDocumentEditorMinimized]);

  return (
    <Container>
      <Tabs
        tab={tab}
        setTab={setNewTab}
        tabs={tabs}
        style={tabStyles}
        tabStyle={oneTabStyle}
      />
      <Content bottomOffset={tab?.id === TabLabel.PLAN}>
        {renderContent}
      </Content>
      {tab?.id === TabLabel.PLAN ? (
        <BottomBarContainer>
          <BottomBar
            primary={{
              text: t("finish"),
              variant: ButtonColor.Blue,
              onClick: handleSubmit(onSubmit),
            }}
            secondary={{
              text: t("cancel"),
              onClick: navigateBack,
            }}
          />
        </BottomBarContainer>
      ) : null}
    </Container>
  );
}
