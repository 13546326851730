import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Calendar, momentLocalizer, Event, SlotInfo } from "react-big-calendar";
import { useLocation, useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import moment from "moment";
import styled from "styled-components";

import { COLOR_SECONDARY_GREY, COLOR_WHITE } from "../colors";
import { useAgendaContext } from "../contexts/agenda/AgendaProvider";
import { usePracticeContext } from "../contexts/practice/PracticeProvider";
import { useSettingsContext } from "../contexts/settingsContext";
import { useUserContext } from "../contexts/User";
import { Calendar as CalendarModel, CalendarQuery } from "../graphql";
import { useHandleClickOutside } from "../hooks";
import { ROUTING_CONFIG } from "../router/RoutingConfig";
import { StyledCard } from "../StyledCard";
import { getAllEvents } from "../utils/getAllSelectedEventsByType";
import { isRealTrainer } from "../utils/isRealTrainer";

import { CALENDAR_COMPONENTS } from "./Calendar/CalendarComponents";
import { CreateSessionDropdownContent } from "./CreateSessionDropdownContent";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../components/Calendar/customCalendar.css";
import { Loader } from "./Loader";
import { Tab } from "./Tabs";

dayjs.extend(weekOfYear);

const localizer = momentLocalizer(moment);

type Props = {
  data: CalendarQuery;
  loading: boolean;
  onNavigate: (newDate: Date) => void;
  tab?: Tab | any;
  defaultDate?: Date;
  goToWeekTab?: () => void;
  isAthleteScreen?: boolean;
};

interface DropdownDimension {
  height: number;
  width: number;
}

const SIDE_BAR = 260;
const LEFT_SIDE_DROPDOWN_PERCENTAGE = 0.8;

const CalendarCard = styled(StyledCard)`
  margin: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
`;

const Dropdown = styled.div<{
  selectedCell: SlotInfo;
  dropdownDimension: DropdownDimension;
}>`
  position: absolute;
  min-width: 120px;
  width: fit-content;
  z-index: 99999;
  padding: 5px 0;
  background: ${COLOR_WHITE};
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 30px;
  top: ${({ selectedCell, dropdownDimension }) =>
    selectedCell?.box
      ? selectedCell?.box?.clientY - dropdownDimension.height
      : 0}px;
  left: ${({ selectedCell, dropdownDimension }) =>
    selectedCell?.box
      ? selectedCell?.box?.x > window.innerWidth * LEFT_SIDE_DROPDOWN_PERCENTAGE
        ? selectedCell?.box?.x - SIDE_BAR - dropdownDimension.width
        : selectedCell?.box?.x - SIDE_BAR
      : 0}px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

type ExtendedEvent = Event & {
  id: string;
  type: string;
  isGreenInCalendar: boolean;
  cancelled: boolean;
};

export function CalendarContainer({
  data,
  loading,
  onNavigate,
  tab,
  defaultDate,
  goToWeekTab,
  isAthleteScreen,
}: Props) {
  const { timeFormatTemplate } = useSettingsContext();
  const location = useLocation();

  const user = useUserContext();
  const { selectedEventTypes, selectedVisibleGroupIds, focusedAgendaGroupId } =
    useAgendaContext();

  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCell, setSelectedCell] = useState<SlotInfo>(null);
  const [dropdownDimension, setDropdownDimension] = useState<DropdownDimension>(
    {
      width: 0,
      height: 0,
    }
  );

  const navigate = useNavigate();
  const { setPracticeStartDate } = usePracticeContext();

  const isUserRealTrainer = isRealTrainer(user);

  const onSelectEvent = useCallback(
    ({ id, type }) => {
      navigate({
        pathname: `/${ROUTING_CONFIG.session}`,
        search: `?sessionGuid=${id}&sessionType=${type}`,
      });
    },
    [navigate]
  );

  const teamNameAsTitle = useMemo(() => {
    if (location.pathname === "/calendar") {
      return !focusedAgendaGroupId && selectedVisibleGroupIds.length > 1;
    }
  }, [focusedAgendaGroupId, selectedVisibleGroupIds, location]);

  const getEvent = useCallback(
    (event, eventEnd) => ({
      title:
        teamNameAsTitle && event.__typename !== "Workout"
          ? event.teamName
          : event.label,
      label: event.label,
      start: dayjs(event.start).toDate(),
      end: eventEnd.toDate(),
      startUnix: event.start,
      ownerUser: event.ownerUser,
      type: event.__typename,
      id: event?.id ?? "",
      groupId: event.__typename !== "Workout" ? (event?.groupId ?? "") : "",
      color: event.rgb,
      cancelled: event.cancelled,
      isGreenInCalendar: event.isGreenInCalendar,
      view: tab?.id ?? "",
      durationMinutes: 60,
      location: event.location,
      xtraLocation: event.xtraLocation,
      notes: event.notes,
      teamName: event.teamName,
      attendedMinutesBefore: event.attendedMinutesBefore,
    }),
    [tab?.id, teamNameAsTitle]
  );

  const getMultipleDaysEvent = useCallback(
    (multipleDayEvent, diffEventEndEndOfStartDay) => {
      const dayInMinutes = 1440;
      const eventsToReturn = [];

      const addEvent = (start, end) => {
        const event = getEvent({ ...multipleDayEvent, start }, end);
        eventsToReturn.push(event);
      };

      const addFirstPart = () => {
        addEvent(
          multipleDayEvent.start,
          dayjs(multipleDayEvent.start).endOf("day")
        );
      };

      const addNextParts = () => {
        const numberOfWholeDays = Math.floor(
          diffEventEndEndOfStartDay / dayInMinutes
        );

        for (let i = 1; i <= numberOfWholeDays; i++) {
          const nextEventPartStart = dayjs(multipleDayEvent.start)
            .startOf("day")
            .add(i, "day");
          const nextEventPartEnd = dayjs(nextEventPartStart).endOf("day");
          addEvent(nextEventPartStart, nextEventPartEnd);
        }
      };

      const addLastPart = () => {
        const lastDayDuration = diffEventEndEndOfStartDay % dayInMinutes;
        const lastEventPartStart = dayjs(multipleDayEvent.start)
          .startOf("day")
          .add(eventsToReturn.length, "day");
        const lastEventPartEnd = dayjs(lastEventPartStart).add(
          lastDayDuration,
          "minutes"
        );
        addEvent(lastEventPartStart, lastEventPartEnd);
      };

      addFirstPart();
      addNextParts();
      addLastPart();

      return eventsToReturn;
    },
    [getEvent]
  );

  const events: ExtendedEvent[] = useMemo(
    () =>
      data?.calendar
        ? getAllEvents(data?.calendar as CalendarModel, selectedEventTypes)
            .flatMap((event) => {
              const eventEnd = dayjs(event.start).add(
                event.durationMinutes ?? 0,
                "minute"
              );
              const endOfStartDay = dayjs(event.start).endOf("day");
              const diffEventEndEndOfStartDay = eventEnd.diff(
                endOfStartDay,
                "m"
              );
              const isMultipleDayEvent = diffEventEndEndOfStartDay > 0;

              if (isMultipleDayEvent) {
                return getMultipleDaysEvent(event, diffEventEndEndOfStartDay);
              } else {
                return [getEvent(event, eventEnd)];
              }
            })
            .filter(
              (event) =>
                tab?.id !== "week" ||
                dayjs(event.start).isSame(defaultDate, "week")
            )
        : [],
    [
      data?.calendar,
      selectedEventTypes,
      getMultipleDaysEvent,
      getEvent,
      tab?.id,
      defaultDate,
    ]
  );

  const onSelectSlot = useCallback(
    (slot: SlotInfo) => {
      if (slot.action === "click" && isUserRealTrainer) {
        setSelectedCell(slot);
        setShowDropdown(true);

        if (tab?.id === "month") {
          setPracticeStartDate(dayjs(slot.start).set("hour", 12).valueOf());
        } else {
          setPracticeStartDate(dayjs(slot.start).valueOf());
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isUserRealTrainer]
  );

  useEffect(() => {
    if (selectedCell) {
      if (
        dropdownDimension?.height !== dropdownRef?.current?.clientHeight ||
        dropdownDimension?.width !== dropdownRef?.current?.clientWidth
      ) {
        setDropdownDimension({
          height: dropdownRef?.current?.clientHeight,
          width: dropdownRef?.current?.clientWidth,
        });
      }
    }
  }, [dropdownDimension, selectedCell]);

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  const onShowMore = () => {
    if (goToWeekTab) {
      goToWeekTab();
    }
  };

  useHandleClickOutside(dropdownRef, handleClickOutside);

  if (loading) {
    return (
      <LoadingWrapper>
        <Loader size="large" />
      </LoadingWrapper>
    );
  }

  const eventsStart = events?.map((event) => event.start.getHours());
  const soonestStart = Math.min(...eventsStart, 6);
  const startOfDay = new Date(0, 0, 0, soonestStart, 0, 0);

  return (
    <CalendarCard>
      <Calendar<ExtendedEvent>
        key={defaultDate}
        selectable
        components={CALENDAR_COMPONENTS}
        drilldownView={null}
        localizer={localizer}
        view={tab?.id}
        onNavigate={onNavigate}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        events={events}
        formats={{
          eventTimeRangeFormat: () => null,
          timeGutterFormat: (date) => dayjs(date).format(timeFormatTemplate),
        }}
        onShowMore={onShowMore}
        scrollToTime={startOfDay}
        dayPropGetter={(date) =>
          dayjs(date).isToday()
            ? {
                style: {
                  // We need this to overlap default styling
                  position: "relative",
                  marginRight: "-1px",
                  marginLeft: "-1px",
                  border:
                    tab?.id === "month" || isAthleteScreen
                      ? `1px solid ${COLOR_SECONDARY_GREY}`
                      : "none",
                  backgroundColor: "transparent",
                },
              }
            : {}
        }
        slotPropGetter={(date) => {
          const hour = dayjs(date).get("hour");
          const outsideMainTime = hour < 6 || hour >= 23;

          return {
            style: {
              backgroundColor: outsideMainTime ? "#f1f1f1" : "none",
            },
          };
        }}
        defaultDate={defaultDate}
      />
      {showDropdown && selectedCell && (
        <Dropdown
          selectedCell={selectedCell}
          dropdownDimension={dropdownDimension}
          ref={dropdownRef}
        >
          <CreateSessionDropdownContent
            selectedStartDate={selectedCell.start}
          />
        </Dropdown>
      )}
    </CalendarCard>
  );
}
