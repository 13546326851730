import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { EventProps } from "react-big-calendar";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_WHITE,
  COLOR_SECONDARY_GREY,
  COLOR_GREEN,
} from "../../colors";
import { useNotificationsContext } from "../../contexts/notifications";
import { usePracticeContext } from "../../contexts/practice/PracticeProvider";
import { useSettingsContext } from "../../contexts/settingsContext";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import { useSessionPlanLazyQuery } from "../../graphql";
import { useHandleClickOutside } from "../../hooks";
import { useDeleteSession } from "../../hooks/data/useDeleteSession";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { HoverableItem } from "../HoverableItem";
import { AttendingIcon } from "../Icons";

import { useSetZIndexesForCalendarRowsEffect } from "./hooks/useSetZIndexesForCalendarRowsEffect";
import { CustomEvent } from "./model";

const StyledCalendarEventContainer = styled.div<{
  backgroundColor?: string;
  cancelled?: boolean;
  isAttending?: boolean;
  withHorizontalMargin?: boolean;
}>`
  display: flex;
  align-items: center;

  min-height: 21px;
  height: 100%;
  border-radius: 12px;
  padding: 0 5px;
  margin: ${({ withHorizontalMargin }) => (withHorizontalMargin ? "0 5px" : 0)};

  font-weight: 500;
  font-size: 10px;

  ${({ backgroundColor, cancelled, isAttending }) => {
    let defaultColor = COLOR_BLUE;
    if (backgroundColor?.length) {
      defaultColor = `rgb(${backgroundColor})`;
    }

    if (isAttending && !cancelled) {
      return `
        background-color: ${COLOR_WHITE};
        border: 1px solid ${defaultColor};
        color: ${defaultColor};
        `;
    }
    if (cancelled) {
      return `
        background-color: ${COLOR_WHITE};
        border: 1px solid ${COLOR_SECONDARY_GREY};
        border-style: dashed;
        color: ${COLOR_SECONDARY_GREY};
    `;
    }
    return `
      background-color: ${defaultColor};
      border: 1px solid ${defaultColor};
      color: ${COLOR_WHITE};
    `;
  }}
`;

const Title = styled.div<{ withIcon: boolean }>`
  margin-left: 5px;
  margin-right: ${({ withIcon }) => (withIcon ? 20 : 15)}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Date = styled.span`
  font-weight: bold;
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 99999;
  background: ${COLOR_WHITE};
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 30px;
  top: 21px;
  right: 0;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 3px;
`;

const hoverableButtonStyle = { width: "100%" };

export const CustomCalendarEvent = ({
  event,
  title,
}: EventProps<CustomEvent>) => {
  const { timeFormatTemplate } = useSettingsContext();
  const [showDropdown, setShowDropdown] = useState(false);
  const { t } = useTranslation();
  const dropdownRef = useRef();
  const [eventRef, setEventRef] = useState<HTMLElement>();
  const isPractice = event.type === "Practice";
  const { actions: modalActions } = useModalContext();
  const { deleteSessionMutation } = useDeleteSession();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();
  const { isTrainer, isFamilyMember } = useUserContext();
  const { setPracticeSession, setPracticeLines } = usePracticeContext();
  const { sessionId, timezone, language } = useUserContext();

  const [getSessionPlan] = useSessionPlanLazyQuery();

  const ref = useCallback((node) => {
    if (node !== null) {
      setEventRef(node);
    }
  }, []);

  useEffect(() => {
    if (eventRef) {
      const toggleDropdown = (e) => {
        setShowDropdown(!showDropdown);
        e.preventDefault();
      };

      eventRef.addEventListener("contextmenu", toggleDropdown, false);

      return () => eventRef.removeEventListener("contextmenu", toggleDropdown);
    }
  }, [eventRef, showDropdown]);

  useSetZIndexesForCalendarRowsEffect();

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  useHandleClickOutside(dropdownRef, handleClickOutside);

  const openEditModal = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setPracticeSession({
      ...event,
      rgb: event.color,
      start: event.startUnix,
      label: event.label,
    });

    if (isPractice) {
      const { data: sessionPlanData } = await getSessionPlan({
        variables: {
          sessionId,
          timezone,
          language,
          sessionGuid: event.id,
        },
        fetchPolicy: "cache-first",
      });

      if (sessionPlanData?.practice?.practiceLines?.length) {
        setPracticeLines(sessionPlanData?.practice?.practiceLines);
      }
    }

    modalActions.openModal({
      modal: isPractice
        ? ModalType.CREATE_PRACTICE_SESSION
        : ModalType.CREATE_EVENT_OR_GAME,
      title: isPractice ? "" : `${t("update")} ${t(event.type)}`,
      params: {
        session: {
          label: event.title,
          rgb: event.color,
          endDate: event.end,
          durationMinutes: event.durationMinutes,
          start: event.startUnix,
          attendedMinutesBefore: event.attendedMinutesBefore,
          location: event.location,
          xtraLocation: event.xtraLocation,
          notes: event.notes,
          id: event.id,
          type: event.type,
          teamName: event.teamName,
          disableCancel: true,
          ownerUser: event.ownerUser,
        },
        openedForSpecificGroupId: event?.groupId,
        eventType: event.type.toLowerCase(),
      },
    });
  };

  const openDeleteModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    modalActions.openModal({
      modal: ModalType.CONFIRM,
      title: t("confirm"),
      params: {
        content: t("deleteConfirmQuestion", {
          x: t(event.type).toLowerCase(),
        }),
        okButtonText: t("delete"),
        onOK: async () => {
          try {
            await deleteSessionMutation(event, () => ["agenda", "calendar"]);
            showSuccessNotification(t("sessionDeleteSuccess"));
          } catch {
            showErrorNotification(t("sessionDeleteError"));
          }
          modalActions.closeModal();
        },
      },
    });
  };

  return (
    <StyledCalendarEventContainer
      backgroundColor={event.color}
      cancelled={event.cancelled}
      isAttending={event.isGreenInCalendar && !event.cancelled}
      withHorizontalMargin={event.view === "week"}
      ref={ref}
    >
      {showDropdown && isRealTrainer({ isTrainer, isFamilyMember }) && (
        <Dropdown ref={dropdownRef}>
          <HoverableItem
            label={t("edit")}
            buttonStyle={hoverableButtonStyle}
            onClick={openEditModal}
          />
          <HoverableItem
            label={t("delete")}
            buttonStyle={hoverableButtonStyle}
            onClick={openDeleteModal}
          />
        </Dropdown>
      )}
      <Date>{dayjs(event.start).format(timeFormatTemplate)}</Date>
      <Title withIcon={event.isGreenInCalendar}>{title}</Title>
      {event.isGreenInCalendar ? (
        <IconWrapper>
          <AttendingIcon width={15} height={15} color={COLOR_GREEN} isActive />
        </IconWrapper>
      ) : null}
    </StyledCalendarEventContainer>
  );
};
