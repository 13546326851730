import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../../../colors";
import { useAccessLevelsContext } from "../../../contexts/accessLevels";
import { useNewChatContext } from "../../../contexts/messaging/NewChatProvider";
import { useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import {
  Chatter,
  GetCreateNewChatGroupsQuery,
  WhoCanPost,
} from "../../../graphql";
import { sortList } from "../../../utils/sortList";
import StyledText from "../../StyledText";
import { Team } from "../Components/Team";

interface GroupsTeamProps {
  search: string;
  groupsData: GetCreateNewChatGroupsQuery;
}

const NoOneWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
`;

export const GroupsTeam = ({ search, groupsData }: GroupsTeamProps) => {
  const { t } = useTranslation();
  const { actions: modalActions } = useModalContext();
  const { accessLevels } = useAccessLevelsContext();
  const { language } = useUserContext();
  const {
    stage,
    setStage,
    setAnnounce,
    setSelectedGroupId,
    setForAllAutoAddedFriendsOfChatter,
    setGroupForInitGroupName,
    setWhoCanPost,
  } = useNewChatContext();

  const hasAllGroupsFromOneAccount = useMemo(() => {
    const initialOwnerOfGroup =
      groupsData?.getTheGroupsImIn?.length > 0
        ? groupsData.getTheGroupsImIn[0]?.owner?.id
        : "";

    return groupsData.getTheGroupsImIn.every(
      (group) => group.owner?.id === initialOwnerOfGroup
    );
  }, [groupsData]);

  const groups = useMemo(
    () =>
      sortList(
        groupsData?.getTheGroupsImIn?.map((group) => {
          const ownerName = [group?.owner?.firstName, group?.owner?.lastName]
            .filter((name) => !!name)
            .join(" ");

          const groupNameWithOwner = [group.name, ownerName]
            .filter((name) => !!name)
            .join(" - ");

          return {
            ...group,
            name: hasAllGroupsFromOneAccount ? group.name : groupNameWithOwner,
            groupName: group.name,
          };
        }),
        language
      ),
    [groupsData?.getTheGroupsImIn, language, hasAllGroupsFromOneAccount]
  );

  const groupedGroups = sortList([...(groups || [])], language).reduce(
    (acc, value) => {
      if (!acc[`${value.owner.firstName} ${value.owner.lastName}`])
        acc[`${value.owner.firstName} ${value.owner.lastName}`] = {
          owner: {
            id: value.owner.id,
            name: `${value.owner.firstName} ${value.owner.lastName}`,
            groupName: value.groupName,
            nickName: value.owner.nickName,
            country: value.owner.country,
          },
          // Trainer should be able to post to whole user access level only if he has full access
          value: accessLevels.find(
            (accessLevel) => accessLevel.user?.id === value.owner.id
          )?.userManagement?.fullAccess
            ? [
                {
                  id: value.owner.id,
                  name: `${value.owner.firstName} ${value.owner.lastName}`,
                },
              ]
            : [],
        };
      acc[`${value.owner.firstName} ${value.owner.lastName}`]["value"].push(
        value
      );

      return acc;
    },
    []
  );

  return (
    <>
      {stage === 1 &&
        Object.keys(groupedGroups).map((gg, index) => (
          <Team
            isCreateGroupChat
            search={search}
            isTeamSelection
            onClick={async (group, _, isAnnouncement) => {
              if (group.id === groupedGroups[gg].owner?.id) {
                setForAllAutoAddedFriendsOfChatter(group as Chatter);
              } else {
                setSelectedGroupId(group.id);
              }
              setGroupForInitGroupName({ ...group, isAnnouncement });
              setAnnounce(group as Chatter);
              setWhoCanPost(
                isAnnouncement ? WhoCanPost.Admins : WhoCanPost.All
              );
              modalActions.renameModal({ title: t("createChatGroup") });
              setStage(2);
            }}
            index={index}
            stage={stage}
            groups={groupedGroups as any}
            currentKey={gg}
            key={index}
            withPagination={false}
          />
        ))}
      {groups.length === 0 && (
        <NoOneWrapper>
          <StyledText
            style={{ textAlign: "center" }}
            fontWeight="bold"
            color={COLOR_TEXT_DEFAULT}
          >
            {t("noOne")}
          </StyledText>
        </NoOneWrapper>
      )}
    </>
  );
};
