import React, { Dispatch, SetStateAction, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_TEXT_TABLE_LABEL } from "../../../colors";
import { useResourceCalendarContext } from "../../../contexts/resourceCalendar/ResourceCalendarProvider";
import { useDefaultDateFormat } from "../../../hooks";
import {
  SessionTypeNumber,
  useSessionColors,
} from "../../../hooks/useSessionColors";
import { SessionType } from "../../../services/sessions";
import { StorageKeys } from "../../../utils/storageKeys";
import { EventType } from "../../Filters/model";
import { ControlledCalendar } from "../../HookForm/ControlledCalendar";
import { ControlledColorPicker } from "../../HookForm/ControlledColorPicker";
import { ControlledInput } from "../../HookForm/ControlledInput";
import { ControlledLocationSelect } from "../../HookForm/ControlledLocationSelect";
import { ControlledTextArea } from "../../HookForm/ControlledTextArea";
import { ControlledTitleSelect } from "../../HookForm/ControlledTitleSelect";
import { ResourceLocationSelector } from "../../HookForm/ResourceLocationSelector";
import { ColumnOfRowWrapper } from "../../HookForm/styled";
import StyledText from "../../StyledText";

type EventFormProps = {
  formMethods: any;
  eventType?: EventType;
  setSelectedGoogleLocationId: Dispatch<SetStateAction<string>>;
  selectedGoogleLocationId: string;
  googleMapsUrl: string;
  openedForSpecificGroupId: string;
};

const Row = styled.div<{ zIndexValue?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: ${({ zIndexValue }) => zIndexValue ?? 0};
  gap: 15px;
`;

const SmallInputWrapper = styled.div`
  width: 75px;
`;

const LabelText = styled(StyledText)`
  font-size: 10px;
  font-weight: 500;
  margin-left: 10px;
  color: ${COLOR_TEXT_TABLE_LABEL};
  padding-top: 35px;
`;

const StyledSeparator = styled.div`
  margin-bottom: 15px;
`;

const SmallCheckboxWrapper = styled.div`
  width: 70px;
`;

export function EventForm({
  formMethods,
  eventType,
  setSelectedGoogleLocationId,
  selectedGoogleLocationId,
  googleMapsUrl,
  openedForSpecificGroupId,
}: EventFormProps) {
  const { t } = useTranslation();
  const { watch, resetField, setValue, ...methods } = formMethods;
  const { dateFormat } = useDefaultDateFormat();
  const { selectableResourceList } = useResourceCalendarContext();

  useFormPersist(StorageKeys.gameOrEvent, {
    watch,
    setValue,
    storage: window.localStorage,
  });

  const sessionDetails = useMemo(() => {
    const localEventType = eventType?.toLocaleLowerCase();
    if (
      localEventType === EventType.EVENT ||
      localEventType === EventType.TEAM_EVENT
    ) {
      return {
        type: SessionType.EVENT,
      };
    }

    if (
      localEventType === EventType.GAME ||
      localEventType === EventType.TEAM_GAME
    ) {
      return {
        type: SessionType.GAME,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType, openedForSpecificGroupId]);

  const currentEventDate = watch("startDate");
  const currentEventStartTime = watch("startTime");
  const currentEventDuration = watch("duration");

  const [startHour, startMinute] = currentEventStartTime?.split(":");

  const updatedStartDate = dayjs(currentEventDate)
    .set("hour", startHour)
    .set("minute", startMinute);
  const updatedEndDate = dayjs(updatedStartDate).add(
    currentEventDuration,
    "minute"
  );

  const sessionType = useMemo(() => {
    if (eventType === EventType.EVENT || eventType === EventType.TEAM_EVENT) {
      return SessionTypeNumber.Event;
    }

    if (eventType === EventType.GAME || eventType === EventType.TEAM_GAME) {
      return SessionTypeNumber.Game;
    }
  }, [eventType]);

  const { colorList } = useSessionColors(sessionType);

  return (
    <FormProvider
      watch={watch}
      resetField={resetField}
      setValue={setValue}
      {...methods}
    >
      <ControlledTitleSelect
        name="title"
        label={t("title")}
        rules={{
          required: t("titleRequired"),
        }}
        sessionType={sessionDetails?.type}
        isLabel
      />
      <Row zIndexValue="6">
        <ColumnOfRowWrapper width={30}>
          <ControlledCalendar
            name="startDate"
            label={t("startDate")}
            styledForRight={-30}
            dateFormat={dateFormat}
            rules={{
              required: t("dateRequired"),
            }}
          />
        </ColumnOfRowWrapper>
        <ColumnOfRowWrapper>
          <SmallInputWrapper>
            <ControlledInput
              name="startTime"
              label={t("time")}
              type="time"
              rules={{
                required: t("timeRequired"),
              }}
            />
          </SmallInputWrapper>
        </ColumnOfRowWrapper>
        <ColumnOfRowWrapper>
          <SmallInputWrapper style={{ width: 65 }}>
            <ControlledInput
              name="durationMinutes"
              label={t("duration")}
              type="number"
              min="0"
              max="999"
            />
          </SmallInputWrapper>
          <LabelText>{t("min")}</LabelText>
        </ColumnOfRowWrapper>
        <ColumnOfRowWrapper>
          <SmallCheckboxWrapper>
            <ControlledInput
              name="attendance"
              label={t("arrive")}
              type="number"
              min="0"
              max="999"
            />
          </SmallCheckboxWrapper>
          <LabelText>{t("minutesBefore")}</LabelText>
        </ColumnOfRowWrapper>
        <ColumnOfRowWrapper>
          <SmallCheckboxWrapper>
            <ControlledColorPicker
              name="color"
              label={t("color")}
              colorList={colorList}
            />
          </SmallCheckboxWrapper>
        </ColumnOfRowWrapper>
      </Row>
      <Row zIndexValue="2">
        <ColumnOfRowWrapper rightMargin style={{ width: "calc(50% - 40px)" }}>
          {selectableResourceList?.length ? (
            <ResourceLocationSelector
              from={updatedStartDate.valueOf()}
              to={updatedEndDate.valueOf()}
              setSelectedGoogleLocationId={setSelectedGoogleLocationId}
              selectedGoogleLocationId={selectedGoogleLocationId}
              googleMapsUrl={googleMapsUrl}
            />
          ) : (
            <ControlledLocationSelect
              name="location"
              label={t("location")}
              sessionType={sessionDetails?.type}
              setSelectedGoogleLocationId={setSelectedGoogleLocationId}
              selectedGoogleLocationId={selectedGoogleLocationId}
              googleMapsUrl={googleMapsUrl}
            />
          )}
        </ColumnOfRowWrapper>
        <ColumnOfRowWrapper width={50}>
          <ControlledInput
            name="locationDescription"
            label={t("locationDescription")}
          />
        </ColumnOfRowWrapper>
      </Row>
      <ControlledTextArea name="notes" label={t("notes")} />
      {eventType === EventType.TEAM_GAME ? (
        <ControlledTextArea name="specialNotes" label={t("coachNotes")} />
      ) : null}
      <StyledSeparator />
    </FormProvider>
  );
}
