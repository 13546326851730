import { Dayjs } from "dayjs";

import {
  AggregateFunction,
  ReportWidgetCell,
  ReportWidgetTemplateActivityLog,
  ReportWidgetTemplateAcuteChronic,
  ReportWidgetTemplateAttendanceLog,
  ReportWidgetTemplateCombinedTest,
  ReportWidgetTemplateKeyValues,
} from "../../graphql";
import { Period } from "../../models";
import { InformationWidgetFormModel } from "../../screens/InformationWidgetEditor";
import {
  ReportWidgetTemplateActivityLogPreferences,
  ReportWidgetTemplateAcuteChronicPreferences,
  ReportWidgetTemplateAttendanceLogPreferences,
  ReportWidgetTemplateCombinedTestPreferences,
  ReportWidgetTemplateKeyValuesPreferences,
} from "../../utils/widgetPreferencesBuilder";
import { BodyChartWidgetModel } from "../Report/BodyChartWidget/model";
import { ChartWidgetModel } from "../Report/ChartWIdget/model";
import { PerformerSelectionMode } from "../Report/PerformerSection";
import { LinearColoring } from "../Report/PeriodizationTable/model";

export enum ComparisonViewType {
  PERCENTAGE = "as_percentage",
  VALUES = "as_values",
}

export enum ComparisonBase {
  LATEST = "latest_measurement",
  DAY_BEFORE = "day_before",
  FIRST_IN_PERIOD = "first_measurement_in_period",
}

export type AthleteTableWidgetRow = {
  attributeTemplateId: string;
  attributeTemplateName: string;
  attributeTemplateUnitName?: string;
  collectionId: string;
  measurementTemplateId: string;
  measurementTemplateName: string;
};

export type GroupTableSortBy = {
  statsIndex: number | "athlete";
  valueIndex: number;
  attributeIndex: number;
};

export type ColoringConfig = { id: string; coloring: LinearColoring }[];

type CreateTableFormValue = {
  title: string;
  maxCount?: number;
  sortingOrder?: number;
  alternatingValues?: boolean;
  alternatingColumns?: boolean;
  widgetBorders?: boolean;
  rowDividers?: boolean;
  columnDividers?: boolean;
  outsideBorderDividers?: boolean;
  showValuesAsText?: boolean;
  performerSelectionMode?: PerformerSelectionMode;
  athletes?: string[];
  period?: Period;
  coloringConfig?: ColoringConfig;
};

export type AthleteRow = {
  attributeTemplateId: string;
  attributeTemplateName: string;
  collectionId: string;
  measurementTemplateId: string;
  measurementTemplateName: string;
  attributeTemplateUnitName?: string;
  isTestWithColorRanges?: boolean;
  textOptions?: string[];
};

export type CreateAthleteTableFormValue = CreateTableFormValue & {
  columns: AggregateFunction[];
  sortBy?: AggregateFunction;
  rows: AthleteRow[];
  rules?: IRule[];
};

export type GroupColumn = {
  collectionId: string;
  attributeTemplateId: string;
  attributeTemplateName: string;
  attributeTemplateUnitName?: string;
  aggregateFunctions: AggregateFunction[];
  measurementTemplateId: string;
  measurementTemplateName: string;
  textOptions?: string;
  showValuesAsText?: boolean;
  comparison: {
    show: boolean;
    view: ComparisonViewType;
    base: ComparisonBase;
  };
  showTrend: boolean;
  showValue: boolean;
  isTestWithColorRanges?: boolean;
};

export type CreateGroupTableFormValue = CreateTableFormValue & {
  columns: GroupColumn[];
  sumRow?: boolean;
  averageRow?: boolean;
  includeZeroValues?: boolean;
  includeEmptyValues?: boolean;
  sortBy?: GroupTableSortBy;
  rgbIndicator?: string;
  rules?: IRule[];
  teams?: string[];
  colorStartDate?: Dayjs;
};

export enum WidgetType {
  HEADER = "header",
  CHART = "chart",
  TABLE = "table",
  PLACEHOLDER = "placeholder",
  KEY_VALUES = "keyValues",
  GROUP_TABLE_PERIODIZATION = "groupTablePeriodization",
  ATHLETE_TABLE_PERIODIZATION = "athleteTablePeriodization",
  COMBINED_TEST = "combinedTest",
  INFORMATION_LOG = "informationLog",
  ACTIVITY_LOG = "activityLog",
  ATTENDANCE_LOG = "attendanceLog",
  ACUTE_CHRONIC = "AcuteChronic",
  BODY_CHART = "bodyChart",
}

export type GroupTableWidgetModel = Omit<CreateGroupTableFormValue, "title"> & {
  __typename: "ReportWidgetTemplateGroupTable";
  id: string;
  name: string;
  type: WidgetType.TABLE;
  columnHeaders: ReportWidgetCell[];
  // @deprecated - just for backward compatibility after removal of the averageWithoutZeroes property
  averageWithoutZeroes?: boolean;
  additionalColumnData: (Pick<
    GroupColumn,
    "comparison" | "showValue" | "showTrend"
  > & {
    id: number;
  })[];
};

export type AthleteTableWidgetModel = Omit<
  CreateAthleteTableFormValue,
  "title"
> & {
  __typename: "ReportWidgetTemplateAthleteTable";
  id: string;
  name: string;
  type: WidgetType;
  athlete: string;
  columns: AggregateFunction[];
  rowHeaders: ReportWidgetCell[];
  rules: IRule[];
};

export type ActivityLogWidgetModel = ReportWidgetTemplateActivityLog &
  ReportWidgetTemplateActivityLogPreferences;
export type AcuteChronicWidgetModel = ReportWidgetTemplateAcuteChronic &
  ReportWidgetTemplateAcuteChronicPreferences;

export type AthleteWidget =
  | ChartWidgetModel
  | AthleteTableWidgetModel
  | CombinedTestWidgetModel
  | InformationWidgetFormModel
  | ActivityLogWidgetModel
  | AttendanceLogWidgetModel
  | KeyValuesWidgetModel
  | AcuteChronicWidgetModel
  | BodyChartWidgetModel;

export type GroupWidget =
  | ChartWidgetModel
  | GroupTableWidgetModel
  | CombinedTestWidgetModel;

export type AttendanceLogWidgetModel = ReportWidgetTemplateAttendanceLog &
  ReportWidgetTemplateAttendanceLogPreferences;

export type CombinedTestWidgetModel = ReportWidgetTemplateCombinedTest &
  ReportWidgetTemplateCombinedTestPreferences;

export type KeyValuesWidgetModel = ReportWidgetTemplateKeyValues &
  ReportWidgetTemplateKeyValuesPreferences;

export type Widget = AthleteWidget | GroupWidget;

export interface IRule {
  measurementTemplateId: string;
  attributeTemplateId: string;
  aggregation: AggregateFunction;
  comparator: {
    name: Comparator;
    value: string;
    valueTo?: string;
  };
  conditionStyle: {
    name: ConditionStyling;
    value: string;
  };
}

export enum Comparator {
  GREATER_THAN = "greater-than",
  LESS_THAN = "less-than",
  EQUAL = "equal",
  INCLUDES = "includes",
  BETWEEN = "between",
  COLOR_RANGE = "color-range",
}

export enum ConditionStyling {
  COLOR = "color",
  BACKGROUND_COLOR = "backgroundColor",
}
