import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { useTopModalContext } from "../../contexts/UI/TopModal";
import { ButtonColor, GeneralButton } from "../Button/";
import { ControlledInput } from "../HookForm/ControlledInput";

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  width: 100%;
  flex: 1;
`;

const InputsWrapper = styled.div`
  width: calc(100% - 60px);
  margin: 0 30px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 25px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 55px);
  background: white;
  padding-top: 30px;
  height: 60px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 25px;
  margin-left: 15px;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  align-items: center;
  padding-bottom: 50px;
  flex-direction: column;
  width: 100%;
`;

type CreateNewDrillModalProps = {
  handleCreateNewDrill: (name: string) => void;
};

interface FormState {
  name: string;
}

export const CreateNewDrillModal = ({
  handleCreateNewDrill,
}: CreateNewDrillModalProps) => {
  const { t } = useTranslation();
  const { actions: modalTopActions } = useTopModalContext();
  const { watch, ...methods } = useForm<FormState>();
  const { name } = watch();

  const handleDonePress = async () => {
    await handleCreateNewDrill(name);
    modalTopActions.closeTopModal();
  };

  return (
    <ModalContentWrapper>
      <ContentWrapper>
        <FormProvider watch={watch} {...methods}>
          <InputsWrapper>
            <ControlledInput name="name" label={t("name")} autoFocus={true} />
          </InputsWrapper>
        </FormProvider>
      </ContentWrapper>
      <ButtonsWrapper>
        <ButtonWrapper>
          <GeneralButton
            colorVariant={ButtonColor.Gray}
            label={t("cancel")}
            onClick={modalTopActions.closeTopModal}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <GeneralButton
            colorVariant={ButtonColor.Blue}
            label={t("done")}
            onClick={handleDonePress}
            isDisabled={!name || name?.trim() === ""}
          />
        </ButtonWrapper>
      </ButtonsWrapper>
    </ModalContentWrapper>
  );
};
