import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_TABLE_LABEL } from "../colors";
import { useReportContext } from "../contexts/report";
import { useGetGroupWidgetParticipants, IWidgetResizeProps } from "../hooks";
import { useTableSortingPreference } from "../hooks/table/useTableSortingPreference";
import { getBackwardCompatibleAverageWithoutZeroes } from "../utils/widgetPreferencesBuilder";

import { useReportPeriod } from "./Report/hooks/useReportPeriod";
import { WidgetLoader } from "./Report/WidgetLoader";
import { useGroupStatsData } from "./ReportCreateTable/hooks/useGroupStatsData";
import {
  GroupTableSortBy,
  GroupTableWidgetModel,
} from "./ReportCreateTable/models";
import { GroupTable } from "./ReportTableWidget/GroupTable";
import StyledText from "./StyledText";

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
`;

interface IGroupTableWidgetProps extends IWidgetResizeProps {
  widget: GroupTableWidgetModel;
  readOnly?: boolean;
}

const NoDataInfoText = styled(StyledText)`
  font-size: 16px;
  margin-top: 16px;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;

const NoDataInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const GroupTableWidget = ({
  widget,
  gridItemId,
  readOnly,
  layoutRef,
  setItemToResize,
  setItemsToHideInViewMode,
}: IGroupTableWidgetProps) => {
  const { t } = useTranslation();
  const { reportPreferences } = useReportContext();
  const { fromDate, toDate } = useReportPeriod(widget.period);

  const { getGroupWidgetParticipants } = useGetGroupWidgetParticipants();

  const { saveTableSortingPreference } =
    useTableSortingPreference<GroupTableSortBy>();

  const { tableData, dataComposition, isLoading } = useGroupStatsData({
    columnHeaders: widget.columnHeaders.map((columnHeader, index) => ({
      ...columnHeader,
      showTrend: widget.additionalColumnData[index].showTrend,
      comparison: widget.additionalColumnData[index].comparison,
    })),
    fromDate,
    colorStartDate: widget.colorStartDate,
    toDate,
    colorConfigMeasurementIds:
      widget.coloringConfig?.map((config) => config.id.split(":").at(0)) ?? [],
    ...getGroupWidgetParticipants(widget),
  });

  const onChangeSort = async ({
    statsIndex,
    valueIndex,
    attributeIndex,
    order: sortingOrder,
  }: GroupTableSortBy & {
    order: number;
  }) => {
    const { id: tableId } = widget;

    await saveTableSortingPreference(tableId, {
      sortBy: { statsIndex, valueIndex, attributeIndex },
      sortingOrder,
    });
  };

  if (isLoading) {
    return <WidgetLoader />;
  }

  if (
    !isLoading &&
    tableData.filter((data) => Boolean(data.stats[0])).length === 0
  ) {
    return (
      <NoDataInfoWrapper>
        <NoDataInfoText>{t("noData")}</NoDataInfoText>
      </NoDataInfoWrapper>
    );
  }

  return (
    <TableContainer>
      <GroupTable
        id={widget.id}
        title={widget.name}
        tableData={tableData}
        dataComposition={dataComposition}
        alternatingRow={widget.alternatingValues}
        alternatingColumns={widget.alternatingColumns}
        rowDividers={widget.rowDividers}
        columnDividers={widget.columnDividers}
        outsideBorderDividers={widget.outsideBorderDividers}
        widgetBorders={widget.widgetBorders}
        sortBy={widget.sortBy}
        sortingOrder={widget.sortingOrder}
        colorIndicator={widget.rgbIndicator}
        sumRow={widget.sumRow}
        averageRow={widget.averageRow || widget.averageWithoutZeroes}
        includeZeroValues={getBackwardCompatibleAverageWithoutZeroes(
          widget.includeZeroValues,
          {
            // @ts-ignore
            averageWithoutZeroes: widget.averageWithoutZeroes,
            averageRow: widget.averageRow,
          }
        )}
        includeEmptyValues={getBackwardCompatibleAverageWithoutZeroes(
          widget.includeEmptyValues,
          {
            // @ts-ignore
            averageWithoutZeroes: widget.averageWithoutZeroes,
            averageRow: widget.averageRow,
          }
        )}
        coloringConfig={widget.coloringConfig}
        lastDayInRange={toDate}
        isSingleDayReport={reportPreferences.isSingleDay}
        onChangeSort={onChangeSort}
        gridItemId={gridItemId}
        setItemToResize={setItemToResize}
        setItemsToHideInViewMode={setItemsToHideInViewMode}
        layoutRef={layoutRef}
        isReportViewMode={readOnly}
        maxCount={widget.maxCount}
        rules={widget.rules}
        showValuesAsText={widget.showValuesAsText}
      />
    </TableContainer>
  );
};
