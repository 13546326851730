import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { useAccessLevelsContext } from "../../contexts/accessLevels";
import { useNotificationsContext } from "../../contexts/notifications";
import { UserContextState } from "../../contexts/User";
import {
  Event,
  Game,
  Practice,
  useCreatePracticeMutation,
  useGetTheGroupsImInLazyQuery,
} from "../../graphql";
import useSendManualNotificationAfterAction from "../../hooks/useSendManualNotificationAfterAction";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { BottomBar } from "../BottomBar";
import { DatePicker } from "../DatePicker";
import GroupSelector, { GroupValue } from "../GroupSelector";
import { Loader } from "../Loader";

import { getSessionStart, rgbToHex, setDuplicateSessionStart } from "./utils";

type DuplicateSessionModalProps = {
  closeModal: () => void;
  session: Practice | Game | Event;
  user: UserContextState;
};

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const SelectorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export function DuplicateSessionModal({
  closeModal,
  session,
  user,
}: DuplicateSessionModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [group, setGroup] = useState<GroupValue>(null);
  const [date, setDate] = useState<number>(
    session?.start || new Date().valueOf()
  );

  const [fetchGetTheGroupsQuery] = useGetTheGroupsImInLazyQuery();

  const [createPracticeMutation] = useCreatePracticeMutation();
  const { selectedAccount } = useAccessLevelsContext();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();
  const { handleSendManualNotificationAfterAction } =
    useSendManualNotificationAfterAction();

  const handleGroupSelection = (option: GroupValue) => setGroup(option);

  useEffect(() => {
    async function fetchGroupsData() {
      const { data: groupsData } = await fetchGetTheGroupsQuery({
        variables: {
          sessionId: user.sessionId,
          accountId: selectedAccount?.id,
          language: user.language,
        },
      });

      const group =
        groupsData?.getTheGroupsImIn.find(
          (group) =>
            group?.name?.toLowerCase() === session?.teamName?.toLowerCase()
        ) ||
        groupsData?.getTheGroupsImIn
          .find((group) =>
            group.subGroups.find(
              (group) =>
                group?.name?.toLowerCase() === session?.teamName?.toLowerCase()
            )
          )
          ?.subGroups?.find(
            (subGroup) =>
              subGroup?.name?.toLowerCase() === session?.teamName?.toLowerCase()
          );

      setGroup(group);
    }

    fetchGroupsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDuplicateClick = async () => {
    const { hours, minutes } = getSessionStart(session.start);
    const start = setDuplicateSessionStart({
      timestamp: date,
      originalTimestamp: session.start,
      hours,
      minutes,
    });

    try {
      const createPracticeResult = await createPracticeMutation({
        variables: {
          sessionId: user.sessionId,
          timezone: user.timezone,
          language: user.language,
          input: {
            groupID: group.id,
            label: session.label,
            location: session.location,
            notes: session.notes,
            start,
            copyOf: session.id,
            colorHexCode: rgbToHex(session.rgb),
            durationMinutes: session.durationMinutes,
            attendedMinutesBefore: session.attendedMinutesBefore,
          },
        },
        refetchQueries: ["agenda", "resourceCalendar", "calendar"],
      });
      showSuccessNotification(t("duplicateSuccess"));

      handleSendManualNotificationAfterAction(
        { ...createPracticeResult?.data?.createPractice },
        createPracticeResult?.data?.createPractice?.notificationToSuggest
      );

      const id = createPracticeResult?.data?.createPractice?.id;
      const sessionType =
        createPracticeResult?.data?.createPractice?.__typename;

      navigate(
        {
          pathname: `/${ROUTING_CONFIG.session}`,
          search: `?sessionGuid=${id}&sessionType=${sessionType}`,
        },
        {
          replace: true,
        }
      );
    } catch {
      showErrorNotification(t("duplicateError"));
    }
    closeModal();
  };

  return group ? (
    <Wrapper>
      <SelectorsWrapper>
        <GroupSelector
          shouldDisplaySubgroups
          requireEditability
          value={group}
          onChange={handleGroupSelection}
          accountId={group?.owner?.id}
        />
        <DatePicker label={t("date")} date={new Date(date)} setDate={setDate} />
      </SelectorsWrapper>
      <BottomBar
        secondary={{
          text: t("cancel"),
          onClick: closeModal,
        }}
        primary={{
          text: t("duplicate"),
          onClick: onDuplicateClick,
        }}
      />
    </Wrapper>
  ) : (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
}
