import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../colors";

type Direction = "row" | "column";

export const ScreenWrapper = styled.div<{
  scrollable: boolean;
}>`
  flex-direction: column;
  flex: 1;
  overflow-y: ${({ scrollable }) => (scrollable ? "scroll" : "hidden")};
  height: 100vh;
`;

export const Wrapper = styled.div<{
  direction: Direction;
}>`
  flex: 1;
  display: flex;
  flex-direction: ${({ direction }) => direction};
  padding: 15px 10px;
`;

export const SectionWrapper = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  padding: ${({ width }) =>
    width === 100 ? "15px 0px" : "15px 10px 10px 10px"};
`;

export const SectionTitleWrapper = styled.div<{ marginLeft?: number }>`
  margin-bottom: 30px;
  margin-left: ${({ marginLeft = 0 }) => marginLeft}px;
`;

export const SectionTitle = styled.h1`
  font-weight: 300;
  font-size: 36px;
  line-height: 44px;
  font-family: "Inter", sans-serif;
  color: ${COLOR_TEXT_DARK};
  margin: 0;
`;

export const SectionSubTitle = styled(SectionTitle)`
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  margin-top: 10px;
`;

export const SectionLinkWrapper = styled.a`
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const SectionLink = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: ${COLOR_BLUE};
  margin: 0 10px 0 0;
`;

export const SectionBox = styled.div<{
  isHovered?: boolean;
  withoutPadding?: boolean;
  shouldGrow?: boolean;
}>`
  background-color: ${COLOR_WHITE};
  border-radius: 12px;
  padding: ${({ withoutPadding }) => (withoutPadding ? "0px" : "30px")};
  margin-bottom: 5px;
  box-shadow: 0 2px 6px 0 rgba(34, 62, 106, 0.05);
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isHovered }) =>
    isHovered ? "#9fbeec" : "rgba(230, 232, 235, 0.35)"};
  cursor: ${({ isHovered }) => (isHovered ? "pointer" : "default")};
  height: ${({ shouldGrow }) => (shouldGrow ? "calc(100vh - 375px)" : "")};
`;

export const LeftSectionBoxTitleWrapper = styled.div<{
  direction: Direction;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ direction }) => direction};
  height: 100%;
`;

export const LeftSectionBoxTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  font-family: "Inter", sans-serif;
  color: ${COLOR_TEXT_DARK};
  margin: 0;
`;

export const LeftSectionBoxSeparator = styled.span`
  width: 100%;
  height: 1px;
  background-color: ${COLOR_GREY_ACTIVE};
  margin: 30px 0 40px 0;
`;

export const BlogWrapper = styled.iframe`
  min-height: 300px;
  height: 100%;
  width: 100%;
  border: none;
`;

export const SocialMediaIconsWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const SocialMediaIconWrapper = styled.a`
  cursor: pointer;
`;

export const RightSectionWrapper = styled.div<{
  scrollable: boolean;
}>`
  overflow-y: ${({ scrollable }) => (scrollable ? "hidden" : "auto")};
  height: ${({ scrollable }) => (scrollable ? "" : "calc(100vh - 212px)")};
`;

export const RightSectionBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RightSectionRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

export const RightSectionColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 15px 0;
`;

export const RightSectionIconWrapper = styled.div`
  padding: 30px;
`;

export const RightSectionTitle = styled.h1`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  font-family: "Inter", sans-serif;
  color: ${COLOR_TEXT_DARK};
  margin: 0 0 5px 0;
`;

export const RightSectionText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-family: "Inter", sans-serif;
  color: ${COLOR_MEDIUM_GRAY};
  margin: 0;
`;
