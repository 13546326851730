import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { useFormikContext } from "formik";
import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../colors";
import { CalendarInput } from "../CalendarInput";
import { Checkbox } from "../Checkbox";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100;
`;

const LineWrapper = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

const PeriodWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  color: ${COLOR_TEXT_DARK};
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 3px;
  margin-right: 20px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
`;

const CalendarWrapper = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

export function StartDateComparison() {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<{
    colorStartDate: dayjs.Dayjs;
  }>();

  return (
    <Wrapper>
      <LineWrapper>
        <PeriodWrapper>{t("startingDateComparison")}:</PeriodWrapper>
        <CheckboxWrapper>
          <Checkbox
            text={t("timePeriod")}
            typeIcon="circle"
            check={() => {
              setFieldValue("colorStartDate", null);
            }}
            checked={values.colorStartDate === null}
          />
          <Checkbox
            text={t("fixedStartingDay")}
            typeIcon="circle"
            check={() => {
              setFieldValue("colorStartDate", dayjs());
            }}
            checked={values.colorStartDate !== null}
          />
        </CheckboxWrapper>
      </LineWrapper>

      {values.colorStartDate !== null && (
        <CalendarWrapper>
          <CalendarInput
            title={t("startDate")}
            from={values.colorStartDate || dayjs()}
            to={values.colorStartDate || dayjs()}
            value={values.colorStartDate ?? dayjs()}
            showCalendarAboveInput={false}
            set={(value) => setFieldValue("colorStartDate", value)}
          />
        </CalendarWrapper>
      )}
    </Wrapper>
  );
}
