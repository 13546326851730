import React, { useMemo, useRef } from "react";
import { useDrag } from "react-dnd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_SECONDARY_GREY_HOVER,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../../colors";
import { useTeamsContext } from "../../../contexts/teams";
import { useUserContext } from "../../../contexts/User";
import {
  SubGroup,
  useHideFromTeamMutation,
  useRemoveUserFromGroupMutation,
  XpsUser,
} from "../../../graphql";
import { ROUTING_CONFIG } from "../../../router/RoutingConfig";
import Avatar from "../../Avatar";
import { GroupValue } from "../../GroupSelector";
import Hoverable from "../../Hoverable";
import { MessageIcon, HideIcon, RemoveIcon, UnhideIcon } from "../../Icons";

import { ItemTypes } from "./model";
import { ShortNameTag } from "./ShortNameTag";

type UserItemProps = {
  user: XpsUser & { active?: boolean };
  isFirst?: boolean;
  subGroups?: SubGroup[];
  isAthlete?: boolean;
  group?: GroupValue;
  canHide?: boolean;
  canRemove?: boolean;
  grayOnHidden?: boolean;
  shouldShowOptionalBadge?: boolean;
  canDrag?: boolean;
  inCollapsibleCard?: boolean;
  onUserPress: (type: string, id: string, groupId: string) => void;
  parentGroupId?: string;
};

const Row = styled.div<{ inCollapsibleCard: boolean; isFirst: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 0 5px 0 10px;
  height: 45px;
  margin-bottom: ${({ inCollapsibleCard }) => `${inCollapsibleCard ? 0 : 4}px`};
  flex-direction: row;
  align-items: center;
  border-top-width: ${({ inCollapsibleCard, isFirst }) =>
    `${inCollapsibleCard && !isFirst ? 1 : 0}px`};
  border-top-color: ${COLOR_GREY_ACTIVE};
  border-top-style: solid;
  justify-content: ${({ inCollapsibleCard }) =>
    inCollapsibleCard && "space-between"};

  &:hover {
    background: rgba(245, 246, 248, 0.5);
  }
`;

const SimpleRow = styled.div<{ withFlex?: boolean }>`
  flex: ${({ withFlex }) => `${withFlex ? 3 : "auto"}`};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const SubGroupTagsWrapper = styled(SimpleRow)`
  flex: 2;
`;

const Name = styled.div<{ grayView?: boolean }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${({ grayView }) =>
    grayView ? COLOR_SECONDARY_GREY_HOVER : COLOR_TEXT_DARK};
`;

const YearOfBirth = styled.span`
  font-size: 10px;
  line-height: 15px;
  color: ${COLOR_SECONDARY_GREY_HOVER};
  margin-right: 5px;
`;

const Badge = styled.div<{ withMargin?: boolean; isOptional?: boolean }>`
  margin-left: ${({ withMargin = true }) => `${withMargin ? 20 : 0}px`};
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: ${({ isOptional }) =>
    isOptional ? COLOR_SECONDARY_GREY_HOVER : COLOR_BLUE};
`;

const Button = styled.div`
  cursor: pointer;
  border-radius: 100px;
  height: 35px;
  width: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${COLOR_WHITE};
  }
`;

const HideIconContainer = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

const ButtonPlaceholder = styled.div`
  width: 35px;
  height: 35px;
`;

export function UserItem({
  user,
  isFirst,
  subGroups,
  isAthlete,
  group,
  canHide,
  canRemove = true,
  grayOnHidden,
  canDrag,
  shouldShowOptionalBadge = false,
  inCollapsibleCard,
  onUserPress,
  parentGroupId,
}: UserItemProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTrainer = user?.type === "Trainer";
  const [removeUser] = useRemoveUserFromGroupMutation();
  const [hideFromTeam] = useHideFromTeamMutation();
  const { sessionId, id } = useUserContext();
  const { removeAthleteFromGroup, removeTrainerFromGroup, onSetGroup } =
    useTeamsContext();
  const closeDropdownRef = useRef<() => void>();

  const removeUserPress = async (event) => {
    event.stopPropagation();
    if (group?.id) {
      await removeUser({
        variables: {
          sessionId,
          userID: user.id,
          groupID: group?.id,
        },
      });

      if (!isTrainer) {
        removeAthleteFromGroup(user?.id, group?.id);
      } else {
        removeTrainerFromGroup(user?.id, group?.id);
      }
    }
  };

  const onMessagePress = (event) => {
    event.stopPropagation();
    navigate({
      pathname: `/${ROUTING_CONFIG.messages}`,
      search: `?id=${user?.id}`,
    });
  };

  const getSubGroupInitials = (subGroupName) => {
    const subGroupNames = subGroupName?.split(" ") || [];

    if (subGroupNames.length > 1) {
      const [first, second] = subGroupNames.map((name) =>
        name.charAt(0).toUpperCase()
      );
      return first + second;
    } else {
      return subGroupNames[0]?.charAt(0).toUpperCase() || "";
    }
  };

  const handleHide = async (event) => {
    event.stopPropagation();
    await hideFromTeam({
      variables: {
        groupID: group.id,
        sessionId,
        userID: user.id,
        active: user.active,
      },
    });
    onSetGroup(group);
    closeDropdownRef?.current?.();
  };

  const athleteSubGroups = useMemo(() => {
    if (!subGroups || !subGroups.length) {
      return [];
    }

    return subGroups.filter((subGroup) =>
      subGroup.requiredAthletes.some(
        (subGroupAthlete) => subGroupAthlete.id === user.id
      )
    );
  }, [subGroups, user.id]);

  const subGroupsTags = useMemo(() => {
    return athleteSubGroups?.length
      ? athleteSubGroups.map(
          (athleteSubGroup) =>
            athleteSubGroup?.chosenShortName ||
            athleteSubGroup?.shortName ||
            getSubGroupInitials(athleteSubGroup?.name)
        )
      : [];
  }, [athleteSubGroups]);

  const [, drag] = useDrag(() => ({
    type: isTrainer ? ItemTypes.COACH : ItemTypes.ATHLETE,
    options: {
      dropEffect: "move",
    },
    item: user,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    canDrag,
  }));

  const canMessage = user.id !== id && group.personalChat && !user.isExpired;

  return (
    <Hoverable>
      {(isHovered) => (
        <Row
          inCollapsibleCard={inCollapsibleCard}
          isFirst={isFirst}
          onClick={() =>
            onUserPress(
              isTrainer ? "Trainer" : "Athlete",
              user.id,
              parentGroupId || group?.id
            )
          }
          ref={drag}
        >
          <UserWrapper>
            <SimpleRow withFlex={isAthlete}>
              {grayOnHidden && !user?.active ? (
                <HideIconContainer>
                  <HideIcon />
                </HideIconContainer>
              ) : (
                <Avatar
                  size={25}
                  style={{ marginRight: 12 }}
                  source={{ uri: user.thumb }}
                  userName={user.name}
                />
              )}
              <Column>
                <SimpleRow>
                  {isAthlete && user?.profile?.yearOfBirth ? (
                    <YearOfBirth>{user?.profile?.yearOfBirth}</YearOfBirth>
                  ) : null}
                  {user.isExpired ? (
                    <Badge withMargin={false}>
                      {t("expired").toUpperCase()}
                    </Badge>
                  ) : null}
                </SimpleRow>
                <Name grayView={grayOnHidden && !user?.active}>
                  {user.name}
                </Name>
              </Column>
              {!user?.active && shouldShowOptionalBadge ? (
                <Badge isOptional>{t("optional").toUpperCase()}</Badge>
              ) : isTrainer ? (
                <Badge>{t("coach").toUpperCase()}</Badge>
              ) : null}
            </SimpleRow>
            <SubGroupTagsWrapper>
              {!isHovered
                ? subGroupsTags?.map((tag, index) => (
                    <ShortNameTag key={`${tag}-${index}`}>{tag}</ShortNameTag>
                  ))
                : null}
            </SubGroupTagsWrapper>
          </UserWrapper>

          {isHovered && (
            <>
              {canHide ? (
                <Button
                  onClick={(event) => handleHide(event)}
                  style={{ marginLeft: "auto", marginRight: 5 }}
                >
                  {user.active ? (
                    <HideIcon color={COLOR_BLUE} />
                  ) : (
                    <UnhideIcon color={COLOR_BLUE} />
                  )}
                </Button>
              ) : (
                <ButtonPlaceholder />
              )}
              {canRemove ? (
                <Button
                  onClick={(event) => removeUserPress(event)}
                  style={{ marginLeft: "auto", marginRight: 5 }}
                >
                  <RemoveIcon />
                </Button>
              ) : (
                <ButtonPlaceholder />
              )}
              {canMessage ? (
                <Button
                  onClick={(event) => onMessagePress(event)}
                  style={{ marginRight: 5 }}
                >
                  <MessageIcon />
                </Button>
              ) : (
                <ButtonPlaceholder />
              )}
            </>
          )}
        </Row>
      )}
    </Hoverable>
  );
}
