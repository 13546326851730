import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
} from "../../../colors";
import { Cell } from "../../../graphql";

import { shouldBePlaceholderSplit } from "./utils";

type TableInputProps = {
  cell: Cell;
  onCellUpdated: (cell: Cell, newText: string) => void;
};

const Input = styled.input`
  width: 65px;
  border: none;
  background-color: ${COLOR_GRAY};
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
  padding: 5px 0;
`;

const Text = styled.div`
  font-size: 8px;
  font-weight: 700;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;

const Wrapper = styled.div<{ hasPlaceholder: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ hasPlaceholder }) => (hasPlaceholder ? 10 : 0)}px;
  margin-right: 15px;
`;

export function TableInput({ cell, onCellUpdated }: TableInputProps) {
  const [cellValue, setCellValue] = useState(cell.valuePerformed);
  const hasMultipleValues = shouldBePlaceholderSplit(cell);
  const placeholder = hasMultipleValues
    ? cell?.valuePrescribed?.split(" ")
    : [cell?.valuePrescribed];

  const onChangeText = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setCellValue(event.target.value),
    []
  );

  const hasPlaceholder = hasMultipleValues && Boolean(placeholder?.[1]);

  useEffect(
    () => {
      const timeout = setTimeout(() => {
        if (cellValue !== cell.valuePerformed) {
          onCellUpdated(cell, cellValue);
        }
      }, 500);
      return () => clearTimeout(timeout);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cellValue]
  );

  useEffect(
    () => {
      const passedValue = cell.valuePerformed;
      if (passedValue !== cellValue) setCellValue(passedValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cell.valuePerformed]
  );

  return (
    <Wrapper hasPlaceholder={hasPlaceholder}>
      <Input
        defaultValue={cellValue}
        value={cellValue}
        placeholder={placeholder?.[0]}
        onChange={onChangeText}
      />
      {hasPlaceholder ? <Text>{placeholder[1]}</Text> : null}
    </Wrapper>
  );
}
