import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { ButtonColor, GeneralButton } from "../Button/";
import { ConfirmModalText } from "../styled/ConfirmModalText";
import { StyledNumberInput } from "../StyledNumberInput";

const ModalWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px 45px 30px;
`;

const InputWrapper = styled.div`
  margin: 15px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 35px;
  gap: 20px;
`;

export type ConfirmProps = {
  content: ReactNode;
  hasInput?: boolean;
  onOK: (value?: number) => Promise<void> | void;
  okButtonVariant?: ButtonColor;
  okButtonText?: string;
  onCancel?: () => Promise<void> | void;
  cancelButtonVariant?: ButtonColor;
  cancelButtonText?: string;
  closeModal: () => void;
};

export function ConfirmationModal({
  content,
  hasInput,
  onOK,
  okButtonVariant = ButtonColor.Green,
  okButtonText,
  onCancel,
  cancelButtonVariant = ButtonColor.Gray,
  cancelButtonText,
  closeModal,
}: ConfirmProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(2);

  const cancelText = cancelButtonText || t("cancel");
  const okText = okButtonText || t("confirm");

  const onConfirmClick = async () => {
    setIsLoading(true);

    try {
      await onOK(value);
      closeModal();
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const onCancelClick = async () => {
    if (onCancel) {
      await onCancel();
    }

    closeModal();
  };

  return (
    <ModalWrapper>
      {typeof content === "string" || typeof content === "number" ? (
        <ConfirmModalText>{content}</ConfirmModalText>
      ) : (
        content
      )}
      {hasInput && (
        <InputWrapper>
          <StyledNumberInput
            min={1}
            max={5}
            value={value}
            inputWidth={10}
            onChange={(value) => {
              setValue(value);
            }}
          />
        </InputWrapper>
      )}
      <ButtonWrapper>
        <GeneralButton
          colorVariant={okButtonVariant}
          label={okText}
          isLoading={isLoading}
          onClick={onConfirmClick}
        />
        <GeneralButton
          colorVariant={cancelButtonVariant}
          label={cancelText}
          onClick={onCancelClick}
        />
      </ButtonWrapper>
    </ModalWrapper>
  );
}
