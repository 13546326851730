import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";

import styled from "styled-components";

import { MAX_COLUMN_COUNT, MIN_ROW_COUNT } from "../../constants/ReportGrid";
import { ReportWidgetTemplate } from "../../graphql";
import { BasicButton } from "../Button";
import Hoverable from "../Hoverable";

import { LeftOrientedFirstLevelOptionsWrapper } from "./DropdownWrapper/LeftOrientedFirstLevelOptionsWrapper";
import { RightOrientedFirstLevelOptionsWrapper } from "./DropdownWrapper/RightOrientedFirstLevelOptionsWrapper";
import {
  SecondLevelOptionsWrapper,
  SecondLevelOptionsWrapperOrientation,
} from "./DropdownWrapper/SecondLevelOptionsWrapper";
import { useNavigateToWidgetDetail } from "./hooks/useNavigateToWidgetDetail";
import { DropdownOptionsWrapper } from "./styled";
import { WidgetMode } from "./WidgetWithData";

// Change this value to change ratio of right / left menus
export const PERCENTAGE_OF_RIGHT_ORIENTATED_MENUS = 0.6;

// Change this value to change ratio of top / bottom menus
const PERCENTAGE_OF_TOP_ORIENTATED_MENUS = 0.5;

interface IPlaceholderOptionsProps {
  report: any;
  iconComponent: JSX.Element;
  generalWidgets: ReportWidgetTemplate[];
  layoutIndex: number;
  readOnly: boolean;
  mode: WidgetMode;
  activeTabId?: string;
  columnXPosition: number;
  columnYPosition: number;
}

const FullSizeContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledButton = styled(BasicButton)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlaceholderOptions = ({
  report,
  iconComponent,
  generalWidgets,
  layoutIndex,
  readOnly,
  mode,
  activeTabId,
  columnXPosition,
  columnYPosition,
}: IPlaceholderOptionsProps) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const { navigateToWidgetDetail } = useNavigateToWidgetDetail();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const onAddWidgetClick = async (type, widgetId = "") => {
    navigateToWidgetDetail({
      widget: { id: widgetId, type },
      mode,
      reportId: report.id,
      parentReportId: report.parentReportId,
      layoutIndex,
      activeTabId,
      activeProfileTabId: searchParams.get("activeProfileTabId"),
    });
  };

  const leftSideOrientedDropdown =
    columnXPosition < PERCENTAGE_OF_RIGHT_ORIENTATED_MENUS * MAX_COLUMN_COUNT;

  const secondLevelOptionsWrapperOrientation = leftSideOrientedDropdown
    ? SecondLevelOptionsWrapperOrientation.LEFT
    : SecondLevelOptionsWrapperOrientation.RIGHT;

  const topOrientedDropdown =
    columnYPosition > PERCENTAGE_OF_TOP_ORIENTATED_MENUS * MIN_ROW_COUNT;

  const hasGeneralWidgets = generalWidgets?.length > 0;

  return (
    <FullSizeContainer
      className="hover-container"
      onMouseLeave={() => setIsDropdownOpen(false)}
    >
      <FullSizeContainer className="hover-item">
        <StyledButton
          onClick={() =>
            readOnly
              ? navigate(`/reports/report/${report.id}`)
              : setIsDropdownOpen(true)
          }
        >
          {iconComponent}
        </StyledButton>
      </FullSizeContainer>
      {isDropdownOpen && (
        <DropdownOptionsWrapper
          position={leftSideOrientedDropdown ? "left" : "right"}
        >
          <Hoverable>
            {(isHovered) => (
              <ItemWrapper key="create-new">
                {leftSideOrientedDropdown ? (
                  <LeftOrientedFirstLevelOptionsWrapper
                    optionItemText={t("createNew")}
                    isHovered={isHovered}
                  />
                ) : (
                  <RightOrientedFirstLevelOptionsWrapper
                    optionItemText={t("createNew")}
                    isHovered={isHovered}
                  />
                )}
                {isHovered && (
                  <SecondLevelOptionsWrapper
                    orientation={secondLevelOptionsWrapperOrientation}
                    onOptionClick={onAddWidgetClick}
                    mode={mode}
                    topOrientedDropdown={topOrientedDropdown}
                  />
                )}
              </ItemWrapper>
            )}
          </Hoverable>
          {hasGeneralWidgets && (
            <Hoverable>
              {(isHovered) => (
                <ItemWrapper key="use-template">
                  {leftSideOrientedDropdown ? (
                    <LeftOrientedFirstLevelOptionsWrapper
                      optionItemText={t("useTemplate")}
                      isHovered={isHovered}
                    />
                  ) : (
                    <RightOrientedFirstLevelOptionsWrapper
                      optionItemText={t("useTemplate")}
                      isHovered={isHovered}
                    />
                  )}
                  {isHovered && (
                    <SecondLevelOptionsWrapper
                      orientation={secondLevelOptionsWrapperOrientation}
                      onOptionClick={onAddWidgetClick}
                      generalWidgets={generalWidgets}
                      mode={mode}
                      topOrientedDropdown={topOrientedDropdown}
                    />
                  )}
                </ItemWrapper>
              )}
            </Hoverable>
          )}
        </DropdownOptionsWrapper>
      )}
    </FullSizeContainer>
  );
};

export default PlaceholderOptions;
