import React, { MouseEvent, useState } from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../../colors";
import { useAgendaContext } from "../../../contexts/agenda/AgendaProvider";
import { useSettingsContext } from "../../../contexts/settingsContext";
import { useUserContext } from "../../../contexts/User";
import { PaymentStatus } from "../../../graphql";
import { useUpdateWorkout } from "../../../hooks/data/useUpdateWorkout";
import { isRealTrainer } from "../../../utils/isRealTrainer";
import { ArrowInCircleIcon, CardIcon } from "../../Icons";
import { SessionDropdown } from "../../SessionDropdown";
import { WorkoutDoneButton } from "../../WorkoutDoneButton";
import { AgendaSession, RefetchAgendaFunc } from "../model";

import { AttendanceSection } from "./attendance/AttendanceSection";
import { ChildrenForSession } from "./ChildrenForSession";
import { PlaceSection } from "./PlaceSection";
import { SportTypeSection } from "./SportTypeSection";
import { TitleSection } from "./TitleSection";

const RightContainer = styled.div`
  flex: 7;
  flex-direction: column;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 17px;
`;

const TopContainer = styled.div<{ withoutBorder: boolean }>`
  flex-direction: row;
  border-bottom-width: ${({ withoutBorder }) => (withoutBorder ? 0 : 1)}px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  padding-bottom: 20px;
  display: flex;
  margin-bottom: 10px;
`;

const InfoContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  align-items: flex-end;
  width: 80px;
  display: flex;
  justify-content: flex-end;
`;

const BottomContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
`;

const NoteSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 3;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

const NoteText = styled.p<{ isExpanded: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_TEXT_DARK};
  overflow: hidden;
  transition: max-height 300ms;
  margin: 0;
  max-width: 500px;
  ${({ isExpanded }) =>
    isExpanded
      ? `
      white-space: pre-wrap;
      overflow-wrap: break-word;`
      : `
      height: 17px;
      white-space: nowrap;
      text-overflow: ellipsis;`};
`;

const CardIconWrapper = styled.div<{
  sessionIsNotPaid: boolean;
  shouldBeVisible: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: ${({ sessionIsNotPaid, shouldBeVisible }) =>
    shouldBeVisible
      ? sessionIsNotPaid
        ? COLOR_GRAY
        : COLOR_GREEN
      : COLOR_WHITE};
`;

const StyledCardIcon = styled(CardIcon)`
  transform: scale(0.8);
  width: 21px;
  height: 21px;
`;

const PriceWrapper = styled.div`
  margin: 0 35px;
  display: flex;
  align-items: center;
  color: ${COLOR_TEXT_DARK};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;

interface RightSectionProps {
  session: AgendaSession;
  refetch: RefetchAgendaFunc;
}
export function RightSection({ session, refetch }: RightSectionProps) {
  const {
    id,
    label,
    __typename,
    location,
    notes,
    attendance,
    avatarsOfChildren,
    teamName,
    xtraLocation,
    start,
    attendedMinutesBefore,
    cancelled,
    parentGroupName,
  } = session;

  const { isFamilyMember, isTrainer } = useUserContext();
  const { timeFormatTemplate } = useSettingsContext();
  const { selectedAthleteId } = useAgendaContext();

  const [isNoteOpen, setIsNoteOpen] = useState(true);

  const sessionIsNotPaid = session?.payments?.some(
    (payment) => payment?.status !== PaymentStatus.Paid
  );

  const price = (session?.payments?.[0]?.pricex100 || 0) / 100;
  const currency = session?.payments?.[0]?.currency;

  const displayCompleteStatus = session.__typename === "Workout";

  const attendanceSelfRegistration =
    "attSelfReg" in session ? (session.attSelfReg as boolean) : false;

  const { updateWorkoutMutation } = useUpdateWorkout();

  const completeWorkout = async (e: MouseEvent<HTMLDivElement>) => {
    // block navigation
    e.stopPropagation();
    await updateWorkoutMutation({
      id: session?.id,
      input: {
        completed: !session?.isGreenInCalendar,
      },
    });
    // update agenda item state
    refetch({
      updateAgendaItem: {
        id,
        isGreenInCalendar: !session.isGreenInCalendar,
      },
    });
  };

  const showSessionDropdown =
    isRealTrainer({ isFamilyMember, isTrainer }) &&
    !cancelled &&
    __typename !== "Questionnaire";

  return (
    <RightContainer>
      <TopContainer withoutBorder={session.__typename === "Questionnaire"}>
        <TitleSection
          // @ts-ignore
          label={label || session.name}
          payments={session?.payments}
          replyBefore={session?.replyBefore}
          sessionIsNotPaid={sessionIsNotPaid}
          typename={__typename}
          attendanceAt={
            attendedMinutesBefore
              ? dayjs(start)
                  .subtract(attendedMinutesBefore, "minute")
                  .format(timeFormatTemplate)
              : ""
          }
          cancelled={cancelled}
        />

        {__typename === "Questionnaire" ? null : (
          <>
            <SportTypeSection
              parentGroupName={parentGroupName}
              teamName={teamName}
            />
            <PlaceSection
              location={location}
              xtraLocation={xtraLocation}
              showRightPadding={showSessionDropdown}
            />
          </>
        )}
        <CardIconWrapper
          sessionIsNotPaid={sessionIsNotPaid}
          shouldBeVisible={session?.payments?.length}
        >
          {session?.payments?.length ? (
            <StyledCardIcon
              tintColor={sessionIsNotPaid ? COLOR_MEDIUM_GRAY : COLOR_WHITE}
            />
          ) : null}
        </CardIconWrapper>
      </TopContainer>
      <BottomContainer>
        {!!notes && (
          <NoteSection>
            <IconWrapper
              onClick={(e) => {
                setIsNoteOpen(!isNoteOpen);
                e.stopPropagation();
              }}
            >
              <ArrowInCircleIcon direction={!isNoteOpen ? "right" : "bottom"} />
            </IconWrapper>
            <NoteText isExpanded={isNoteOpen}>{session.notes}</NoteText>
          </NoteSection>
        )}
        {sessionIsNotPaid && session?.payments?.length ? (
          <PriceWrapper>{`${Math.round(price)} ${currency}`}</PriceWrapper>
        ) : null}
        {displayCompleteStatus ? (
          <WorkoutDoneButton
            onClick={completeWorkout}
            isGreenInCalendar={session.isGreenInCalendar}
            containerStyle={{ border: "none", paddingBlock: 0, paddingTop: 0 }}
            style={{ border: "none", marginInline: 0 }}
          />
        ) : (
          <>
            {!selectedAthleteId && __typename !== "Questionnaire" ? (
              <AttendanceSection
                refetch={refetch}
                attendanceSelfRegistration={attendanceSelfRegistration}
                session={session}
              />
            ) : null}

            {isFamilyMember &&
            avatarsOfChildren.length > 0 &&
            (!attendanceSelfRegistration || attendance.length === 0) &&
            session.__typename !== "Questionnaire" &&
            !session.payments?.length ? (
              <ChildrenForSession avatarsOfChildren={avatarsOfChildren} />
            ) : null}
          </>
        )}
      </BottomContainer>
      {showSessionDropdown && !selectedAthleteId ? (
        <InfoContainer>
          <SessionDropdown session={session} isProgramPreview={false} />
        </InfoContainer>
      ) : null}
    </RightContainer>
  );
}
