import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_WHITE,
} from "../../colors";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import {
  DocumentPreview,
  useWorkoutEditorContext,
} from "../../contexts/workout/WorkoutEditorProvider";
import {
  FolderDocumentsQuery,
  TreeType,
  useCreateExerciseMutation,
  useFolderDocumentsQuery,
} from "../../graphql";
import { getMoreDocuments } from "../../utils/getMoreDocuments";
import { normalizeString } from "../Filters/utils";
import { MinimizeIcon } from "../Icons";
import { Loader } from "../Loader";

import { DocumentGroup } from "./DocumentGroup";
import { HeaderButton } from "./HeaderButton";

const Container = styled.div<{ isMinimized: boolean }>`
  width: ${({ isMinimized }) => (isMinimized ? 55 : 374)}px;
  flex-shrink: 0;
  position: relative;
  align-items: baseline;
  display: flex;
  flex-direction: column;
  overflow: ${({ isMinimized }) => (isMinimized ? "hidden" : "auto")};
  transition: width 300ms;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 8px;
    background: linear-gradient(
      to left,
      rgba(60, 67, 76, 0.16),
      rgba(60, 67, 76, 0)
    );
  }
`;

const DocumentWrapper = styled.div<{
  isMinimized: boolean;
  withFlex?: boolean;
  centeredContent?: boolean;
}>`
  width: 350px;
  justify-content: ${({ centeredContent }) =>
    centeredContent ? "center" : "flex-start"};
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-shrink: 0;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  opacity: ${({ isMinimized }) => (isMinimized ? 0 : 1)};
  transition: opacity 300ms;
  position: relative;
  flex: ${({ withFlex }) => (withFlex ? 1 : "initial")};

  #editor-documents-empty {
    display: none;
    width: 100%;
    &:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const FetchMoreContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 100px;
  z-index: -1;
`;

const DocumentsHeader = styled.div`
  height: 45px;
  min-height: 45px;
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  width: 100%;
  background: ${COLOR_WHITE};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  z-index: 12;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 45px;
    width: 8px;
    background: linear-gradient(
      to left,
      rgba(60, 67, 76, 0.16),
      rgba(60, 67, 76, 0)
    );
  }
`;

const EmptyText = styled.div`
  font-size: 12px;
  color: ${COLOR_MEDIUM_GRAY};
  text-align: center;
  width: 100%;
  margin-top: 5px;
`;

type Folder = {
  id: string;
  children?: Folder[];
};

function getParentIds(
  folders: Folder[],
  parentIds: string[] = []
): Record<string, string[]> {
  let ids: Record<string, string[]> = {};

  for (const folder of folders) {
    let finalId = folder.id;
    if (finalId.includes(":")) {
      const split = finalId.split(":");
      finalId = split[split.length - 1];
    }
    ids[finalId] = parentIds;

    if (folder.children && folder.children.length > 0) {
      const childParentIds = getParentIds(folder.children, [
        ...parentIds,
        finalId,
      ]);
      ids = { ...ids, ...childParentIds };
    }
  }

  return ids;
}

function findNodeById(
  data: FolderDocumentsQuery["user"]["collection"]["tree"] | undefined,
  id: string,
  fullId?: string
): { name: string; fullId: string } | null {
  if (!data?.id) {
    return null;
  }
  const idSplit = data.id.split(":");
  const finalId = idSplit[idSplit.length - 1];

  if (finalId === id) {
    return { name: data.name, fullId };
  }
  if (!data.children) {
    return null;
  }
  for (const child of data.children ?? []) {
    const result = findNodeById(child, id, data.id);

    if (result !== null) {
      return result;
    }
  }
  return null;
}

const PAGE_SIZE = 4;

export function WorkoutEditorDocuments({
  isMinimized,
  toggleMinimize,
  handleAfterPlusClick,
}) {
  const documentWrapperRef = useRef(null);
  const { t } = useTranslation();
  const [createExercise] = useCreateExerciseMutation();
  const { sessionId, language, timezone } = useUserContext();
  const [searchParams] = useSearchParams();
  const sessionGuid = searchParams.get("sessionGuid");
  const {
    addDocument,
    filteredDocuments: filteredDocumentsFromContext,
    currentFolderTreeStructure: folderStructureFromContext,
    selectedFolder,
    searchValue,
    collectionType,
    collectionId,
    selectedFolderFullId,
  } = useWorkoutEditorContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const bottomSectionRef = useRef<HTMLDivElement>(null);

  const {
    actions: { openModal },
  } = useModalContext();

  const variables = useMemo(
    () => ({
      collectionId,
      collectionType,
      folder: selectedFolderFullId || null,
      filter: searchValue,
      favorite: false,
      recent: false,
      unread: false,
    }),
    [collectionId, collectionType, selectedFolderFullId, searchValue]
  );

  const {
    data,
    fetchMore,
    variables: currentVariables,
    loading: folderDocumentsLoading,
  } = useFolderDocumentsQuery({
    variables,
    skip: collectionType !== TreeType.Tags,
  });

  const [loadedPage, setLoadedPage] = useState(1);

  const handleOpenModal = (document) => {
    const idParts = document.id.split(":");

    const displayProgram =
      document.type === "WORKOUT_TEMPLATE" ||
      document.type === "PRACTICE_TEMPLATE_2020";

    const program = displayProgram
      ? {
          name: document.name,
          guid: idParts[idParts.length - 1],
        }
      : null;

    if (document.url || document.jsonUrl || document.mcUrl) {
      openModal({
        title: displayProgram ? t("workoutProgram") : document.name,
        modal: displayProgram
          ? ModalType.PROGRAM_ITEM_WORKOUT
          : ModalType.DOCUMENT,
        params: {
          program,
          document: {
            ...document,
            mcUrl: document.modularContentUrl,
            itemName: document.name,
          },
        },
      });
    }
  };

  const currentFolderTreeStructure = useMemo(() => {
    if (collectionType === TreeType.Tags) {
      return data;
    }
    return folderStructureFromContext;
  }, [folderStructureFromContext, data, collectionType]);

  const filteredDocuments = useMemo(() => {
    if (collectionType === TreeType.Tags) {
      return data?.user?.collection?.tree?.documents?.edges ?? [];
    }
    return filteredDocumentsFromContext ?? [];
  }, [filteredDocumentsFromContext, data, collectionType]);

  const documentsGroup = useMemo(
    () =>
      // @ts-ignore
      (filteredDocuments ?? []).reduce((acc, val) => {
        const folderGuid = val?.node?.folderGuid ?? " ";
        if (!acc[folderGuid]) {
          acc[folderGuid] = [];
        }
        acc[folderGuid].push({
          ...val?.node,
          parentIds:
            getParentIds(
              currentFolderTreeStructure?.user?.collection?.tree?.children ??
                [],
              []
            )?.[folderGuid] ?? [],
          fullId: val?.node?.id,
        });

        return acc;
      }, {}),
    [filteredDocuments, currentFolderTreeStructure]
  );

  const { visibleGroups, groupsLoading } = useMemo(() => {
    const groupsLoading = !documentsGroup || !currentFolderTreeStructure;
    const visibleGroups = Object.keys(documentsGroup)
      .map((key) => {
        const rawDocumentList = documentsGroup[key];
        const group =
          collectionType === TreeType.Tags
            ? {
                fullId: rawDocumentList?.[0]?.fullId,
                name: rawDocumentList?.[0]?.name,
              }
            : findNodeById(
                currentFolderTreeStructure?.user?.collection?.tree,
                key,
                rawDocumentList?.fullId
              );

        const filteredDocumentList = (rawDocumentList ?? []).filter((doc) => {
          if (collectionType === TreeType.Tags) {
            return (
              !searchValue ||
              (searchValue &&
                normalizeString(doc.name).includes(
                  normalizeString(searchValue)
                ))
            );
          }

          return (
            (!searchValue ||
            (searchValue &&
              normalizeString(group?.name ?? "").includes(
                normalizeString(searchValue)
              ))
              ? true
              : normalizeString(doc.name).includes(
                  normalizeString(searchValue)
                )) &&
            (!selectedFolder ||
              (selectedFolder &&
                (doc.folderGuid === selectedFolder ||
                  doc.parentIds.includes(selectedFolder))))
          );
        });
        return {
          id: key,
          fullId: group?.fullId,
          documentList: filteredDocumentList,
          groupName: group?.name ?? "",
        };
      })
      .filter(({ id, groupName, documentList }) => {
        if (!searchValue || collectionType === TreeType.Tags) {
          return !!documentList?.length;
        }
        return !(
          searchValue &&
          !normalizeString(groupName ?? "").includes(
            normalizeString(searchValue)
          ) &&
          !documentList.length
        );
      });
    return {
      visibleGroups:
        collectionType === TreeType.Tags
          ? visibleGroups
          : visibleGroups.slice(0, loadedPage * PAGE_SIZE),
      groupsLoading,
    };
  }, [
    loadedPage,
    documentsGroup,
    currentFolderTreeStructure,
    searchValue,
    selectedFolder,
    collectionType,
  ]);

  useEffect(() => {
    if (
      visibleGroups.length > 3 ||
      (collectionType === TreeType.Tags && data && !folderDocumentsLoading)
    ) {
      const bottomElement = bottomSectionRef.current;
      const observer = new IntersectionObserver((entries) => {
        const [entry] = entries;
        if (
          entry.isIntersecting &&
          bottomElement.parentElement.offsetHeight >
            bottomElement.parentElement.parentElement.offsetHeight
        ) {
          if (collectionType === TreeType.Tags) {
            if (!folderDocumentsLoading) {
              getMoreDocuments(data, currentVariables, fetchMore);
            }
          } else {
            const timeout = setTimeout(() => {
              setLoadedPage((current) => current + 1);
              observer.disconnect();
              return () => clearTimeout(timeout);
            }, 200);
          }
        }
      });

      observer.observe(bottomElement);

      return () => {
        observer.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    visibleGroups,
    collectionType,
    currentVariables,
    data,
    folderDocumentsLoading,
  ]);

  useEffect(() => {
    if (
      documentsGroup &&
      containerRef.current &&
      collectionType !== TreeType.Tags
    ) {
      const timeout = setTimeout(() => {
        containerRef.current.scrollTo(0, 0);
        return () => clearTimeout(timeout);
      }, 100);
    }
  }, [documentsGroup, searchValue, selectedFolder, collectionType]);

  const onPlusClick = async (document: DocumentPreview) => {
    if (sessionGuid) {
      addDocument(document);
      await createExercise({
        variables: {
          sessionId,
          language,
          timezone,
          input: {
            templateId: document.id,
            workoutId: sessionGuid,
          },
        },
        refetchQueries: () => ["Workout", "ExerciseSets"],
      });
    } else {
      addDocument({
        ...document,
        key: `${document.id}-${Math.round(Math.random() * 1000)}`,
      });
    }

    handleAfterPlusClick();
  };

  return (
    <Container isMinimized={isMinimized} ref={containerRef}>
      <DocumentsHeader>
        <HeaderButton onClick={toggleMinimize} style={{ marginRight: 10 }}>
          <MinimizeIcon
            style={!isMinimized ? { transform: `rotate(180deg)` } : {}}
          />
        </HeaderButton>
      </DocumentsHeader>
      <DocumentWrapper
        withFlex={groupsLoading || !visibleGroups?.length}
        isMinimized={isMinimized}
        ref={documentWrapperRef}
        centeredContent={groupsLoading}
      >
        {groupsLoading ? (
          <Loader
            size="small"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              alignSelf: "center",
            }}
          />
        ) : visibleGroups?.length ? (
          visibleGroups.map(
            ({ id, fullId, documentList, groupName }, index) => {
              return (
                <DocumentGroup
                  isTag={collectionType === TreeType.Tags}
                  key={id}
                  documentList={documentList}
                  index={index}
                  fullId={fullId}
                  handleOpenModal={handleOpenModal}
                  onPlusClick={onPlusClick}
                  groupName={groupName}
                />
              );
            }
          )
        ) : (
          <EmptyText id="editor-documents-empty">{t("emptyFolder")}</EmptyText>
        )}
        <FetchMoreContainer ref={bottomSectionRef} />
      </DocumentWrapper>
    </Container>
  );
}
