import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactJWPlayer from "react-jw-player";

import axios from "axios";
import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_WHITE,
  COLOR_TEXT_DEFAULT,
  COLOR_TEXT_TABLE_LABEL,
  COLOR_GREY_ACTIVE,
} from "../../colors";
import { useUIContext } from "../../contexts/UI";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { TopModalType, useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import { Document, useUpdateDocumentMutation } from "../../graphql";
import { fetchJsonContent, fetchModularContent } from "../../services";
import { ButtonColor, GeneralButton } from "../Button/";
import { ExerciseInfo } from "../ExerciseInfo";
import { IconButton } from "../IconButton";
import { StarIcon, BackIcon } from "../Icons";
import { Loader } from "../Loader";
import StyledText from "../StyledText";

import { getModalWidth } from "./Modal";
import { ProgramList } from "./ProgramList";

const getFullJsonContentUrl = (jsonContentUrl: string) =>
  jsonContentUrl.replace(".s.json", ".json");

type DocumentsModalProps = {
  document: Document & {
    itemName: string;
    mcUrl: string;
    urlOldJsonStyle: string;
  };
  program: {
    name: string;
    guid: string;
    type: string;
  };
  showDocumentName: boolean;
  hidePopularButton: boolean;
  onSend: () => void;
  closeModal: () => void;
};

const IconButtonWrapper = styled.div<{ top?: number }>`
  position: absolute;
  right: 0;
  margin: 10px;
  top: ${({ top }) => (top ? `${top}px` : "auto")};
`;

const VideoWrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 0 40px;
  align-items: center;
  flex-direction: column;
`;

const VideoListWrapper = styled.div<{ hasParagraph?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${({ hasParagraph }) => (hasParagraph ? "auto" : "930px")};
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
`;

const YtWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-bottom: 25px;
`;

const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
`;

const ParagraphWrapper = styled.div<{ hasVideos?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: ${({ hasVideos }) => (hasVideos ? "auto" : "100%")};
`;

const DownloadButtonWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const SendContainer = styled.div`
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  background: ${COLOR_WHITE};
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 90vh;
`;

const EmptyWrapper = styled.div<{
  minWidth: string;
  maxWidth: string;
}>`
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 90vh;
  align-items: center;
  justify-content: center;
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
`;

const TemplateWrapper = styled.div<{
  isSend: boolean;
  minWidth: string;
  maxWidth: string;
}>`
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 90vh;
  position: static;
  padding-bottom: ${({ isSend }) => (isSend ? "75px" : 0)};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 70px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const Documents = ({
  document: dc,
  program,
  onSend,
  showDocumentName,
  hidePopularButton,
  closeModal,
}: DocumentsModalProps) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const ui = useUIContext();
  const {
    actions: { openTopModal },
  } = useTopModalContext();
  const { actions } = useModalContext();
  const [doc, setDoc] = useState(dc);
  const [isLoading, setIsLoading] = useState(false);
  const [modularContent, setModularContent] = useState(null);
  const [videoIsReady, setVideoIsReady] = useState(false);

  const [updateDocumentMutation] = useUpdateDocumentMutation();

  useEffect(() => {
    (async () => {
      if (dc) {
        await setIsLoading(true);
        let modularContent: any = null;
        if (dc.mcUrl) {
          modularContent = await fetchModularContent(dc.mcUrl);
        } else if (dc.jsonUrl || dc.urlOldJsonStyle) {
          modularContent = await fetchJsonContent(
            getFullJsonContentUrl(dc.jsonUrl || dc.urlOldJsonStyle)
          );
        }
        await setModularContent(modularContent);
        await setIsLoading(false);
      }
    })();
  }, [dc]);

  const videos = modularContent ? modularContent.videos : [];
  const youtubeVideos = modularContent ? modularContent.youtubeVideos : [];
  const vimeoVideos = modularContent ? modularContent.vimeoVideos : [];
  const images = modularContent ? modularContent.images : [];
  const paragraphs = modularContent ? modularContent.paragraphs : [];

  const onFavoriteClick = async () => {
    await updateDocumentMutation({
      variables: {
        sessionId: user.sessionId,
        input: {
          id: doc.id,
          isFavorite: !doc.isFavorite,
        },
      },
      optimisticResponse: {
        updateDocument: {
          ...doc,
          isFavorite: !doc.isFavorite,
        },
      },
    });
    await setDoc({
      ...doc,
      id: doc.id,
      isFavorite: !doc.isFavorite,
    });
  };

  const url = doc.mcUrl
    ? `https://s3.eu-central-1.amazonaws.com/xpsweb-static/mc.html?mcUrl=${doc.mcUrl}&name=${doc.itemName}`
    : doc.url;

  const thereAreVideos =
    !!youtubeVideos.length ||
    !!vimeoVideos.length ||
    !!videos.length ||
    !!images.length;

  const isWorkout =
    dc?.type === "WORKOUT_TEMPLATE" || program?.type === "WORKOUT_TEMPLATE";

  const isTeamPractice =
    dc?.type === "PRACTICE_TEMPLATE_2020" ||
    program?.type === "PRACTICE_TEMPLATE_2020";

  const isTemplate = isWorkout || isTeamPractice;

  const handleDownload = async (url) => {
    const response = await axios.get(url, {
      responseType: "json",
    });
    if (response?.data?.videos?.length) {
      response?.data?.videos?.map((video) => {
        setTimeout(() => {
          window.open(video.url, "_blank");
        }, 100);
      });
    }
  };

  const onBack = () => {
    actions.closeModal();
    openTopModal({
      title: program.name,
      modal: TopModalType.DOCUMENT,
      params: {
        document: dc,
        program,
        onSend,
        showDocumentName,
        hidePopularButton,
        closeModal,
      },
    });
  };

  if (isLoading && !isTemplate)
    return (
      <LoaderWrapper>
        <Loader style={{ marginTop: 40 }} />
      </LoaderWrapper>
    );

  if (
    !isLoading &&
    !paragraphs.length &&
    !thereAreVideos &&
    !doc.url &&
    !isTemplate
  ) {
    return (
      <EmptyWrapper {...getModalWidth(ModalType.DOCUMENT, ui.width)}>
        {showDocumentName && (
          <StyledText
            fontWeight="bold"
            color={COLOR_TEXT_DEFAULT}
            fontSize={30}
            style={{
              padding: 20,
              textAlign: "left",
            }}
          >
            {dc.name}
          </StyledText>
        )}
        <StyledText fontSize={18} centerText>
          {t("noContentAvailable")}
        </StyledText>
        {!!doc.id && !hidePopularButton && (
          <IconButtonWrapper top={45}>
            <IconButton
              icon={<StarIcon isActive={doc.isFavorite} />}
              bgColor={COLOR_GRAY}
              onClick={onFavoriteClick}
            />
          </IconButtonWrapper>
        )}
      </EmptyWrapper>
    );
  }

  if (isTemplate) {
    return (
      <ProgramList
        onSend={onSend}
        closeModal={closeModal}
        program={program}
        onBack={onBack}
        isTeamPractice={isTeamPractice}
      />
    );
  }

  return (
    <TemplateWrapper
      isSend={!!onSend}
      {...getModalWidth(ModalType.DOCUMENT, ui.width)}
    >
      {dc.mcUrl || dc.jsonUrl || dc.urlOldJsonStyle ? (
        <div
          style={{
            maxHeight: "calc(90vh - 50px)",
            flex: 1,
            position: "relative",
            overflow: "auto",
          }}
        >
          <NameWrapper>
            {showDocumentName && (
              <StyledText
                fontWeight="bold"
                color={COLOR_TEXT_DEFAULT}
                fontSize={30}
                style={{
                  padding: 20,
                  textAlign: "left",
                }}
              >
                {dc.name}
              </StyledText>
            )}
            {!!doc.id && !hidePopularButton && (
              <IconButtonWrapper>
                <IconButton
                  icon={<StarIcon isActive={doc.isFavorite} />}
                  bgColor={COLOR_GRAY}
                  onClick={onFavoriteClick}
                />
              </IconButtonWrapper>
            )}
          </NameWrapper>
          <ContentWrapper>
            {isTemplate && (
              <GeneralButton
                colorVariant={ButtonColor.Gray}
                label={t(
                  isWorkout ? "addPracticeToCalendar" : "addProgramToCalendar"
                )}
              />
            )}
            {!!paragraphs.length && (
              <ParagraphWrapper hasVideos={thereAreVideos}>
                {paragraphs.length === 0 && !isLoading ? (
                  <StyledText
                    style={{
                      fontSize: 16,
                      color: COLOR_TEXT_TABLE_LABEL,
                      textAlign: "center",
                      marginTop: 35,
                    }}
                    fontWeight="normal"
                  >
                    {t("noContentAvailable")}
                  </StyledText>
                ) : (
                  <ExerciseInfo
                    paragraphs={paragraphs}
                    isLoading={paragraphs.length === 0 && isLoading}
                  />
                )}
              </ParagraphWrapper>
            )}
            {thereAreVideos && (
              <VideoWrapper>
                <VideoListWrapper hasParagraph={paragraphs.length}>
                  {youtubeVideos.map((ytVideo) => (
                    <YtWrapper key={ytVideo.embedUrl}>
                      <iframe
                        width="80%"
                        height={paragraphs.length ? 300 : 420}
                        src={ytVideo.embedUrl}
                      />
                      {ytVideo.caption && (
                        <StyledText style={{ marginTop: 10 }}>
                          {ytVideo.caption}
                        </StyledText>
                      )}
                    </YtWrapper>
                  ))}
                  {vimeoVideos.map((vimeoVideo) => (
                    <PlayerWrapper key={vimeoVideo.embedUrl}>
                      <iframe
                        src={vimeoVideo.embedUrl}
                        height={"100%"}
                        width={"100%"}
                      />
                      {vimeoVideo.caption && (
                        <StyledText style={{ marginTop: 5 }}>
                          {vimeoVideo.caption}
                        </StyledText>
                      )}
                    </PlayerWrapper>
                  ))}
                  {videos.map((video, index) => (
                    <PlayerWrapper key={video.url}>
                      <ReactJWPlayer
                        playerId={`${index}-video-${video.url}`}
                        playerScript="https://cdn.jwplayer.com/libraries/g9KQjRPV.js"
                        file={video.url}
                        image={video.thumbnailUrl}
                        onReady={() => setVideoIsReady(true)}
                      />
                      {videoIsReady && dc?.downloadUrl ? (
                        <DownloadButtonWrapper
                          onClick={() => handleDownload(dc?.downloadUrl)}
                        >
                          <BackIcon direction="bottom" />
                        </DownloadButtonWrapper>
                      ) : null}

                      {video.caption && (
                        <StyledText style={{ marginTop: 5 }}>
                          {video.caption}
                        </StyledText>
                      )}
                    </PlayerWrapper>
                  ))}
                  {images.map((image) => (
                    <img
                      key={image.url}
                      src={image.url}
                      style={{ height: "auto", width: "100%" }}
                    />
                  ))}
                </VideoListWrapper>
              </VideoWrapper>
            )}
          </ContentWrapper>
        </div>
      ) : (
        <iframe
          src={url}
          style={{
            minHeight: "calc(90vh - 50px)",
            width: "100%",
          }}
        />
      )}
      {onSend ? (
        <SendContainer>
          <GeneralButton
            colorVariant={ButtonColor.Blue}
            label={t("send")}
            onClick={() => {
              onSend();
              closeModal();
            }}
          />
        </SendContainer>
      ) : null}
    </TemplateWrapper>
  );
};

export default Documents;
