import React, { useMemo, memo, CSSProperties } from "react";

import { NetworkStatus } from "@apollo/client";
import styled, { css } from "styled-components";

import { COLOR_BLUE } from "../../colors";
import { useUserContext } from "../../contexts/User";
import { DocumentEdgeEdge } from "../../graphql";
import { getMoreDocuments } from "../../utils/getMoreDocuments";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { Loader } from "../Loader";
import { Tab } from "../Tabs";

import { CollectionItem } from "./CollectionItem";
import { EmptyResult } from "./EmptyResult";
import { TabLabel } from "./model";

type CollectionListProps = {
  fetchMoreFolderDocuments;
  fetchMoreSharedDocuments;
  folderDocumentsData;
  sharedDocumentsData;
  folderDocumentsVariable: any;
  sharedDocumentsVariable: any;
  isLoadingFolderDocuments: boolean;
  isLoadingSharedDocuments: boolean;
  sharedDocumentsNetworkStatus: NetworkStatus;
  folderDocumentsNetworkStatus: NetworkStatus;
  onClick: () => void;
  search: string;
  tab: Tab;
  focusedAthleteId?: string;
  handleSharedDocumentsRefetch?: () => void;
  isFromMessaging?: boolean;
};

export interface DocumentToShow {
  documentId: string;
  folderName: string;
  edges: DocumentEdgeEdge[];
}

interface DocumentToShowWithSharerName {
  sharerName: string | null;
  documents: DocumentToShow[];
}

const LoaderContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const CollectionListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CollectionItemsWrapper = styled.div<{ isFromMessaging?: boolean }>`
  padding-left: 16px;
  padding-right: 35px;
  padding-bottom: 15px;
  overflow-y: auto;
  ${({ isFromMessaging }) =>
    isFromMessaging
      ? css`
          height: calc(100vh - 310px);
        `
      : css`
          max-height: calc(100vh - 250px);
        `}
`;

export function CollectionList({
  fetchMoreFolderDocuments,
  fetchMoreSharedDocuments,
  folderDocumentsData,
  sharedDocumentsData,
  folderDocumentsVariable,
  sharedDocumentsVariable,
  isLoadingFolderDocuments,
  isLoadingSharedDocuments,
  folderDocumentsNetworkStatus,
  sharedDocumentsNetworkStatus,
  onClick,
  search,
  tab,
  focusedAthleteId,
  handleSharedDocumentsRefetch,
  isFromMessaging,
}: CollectionListProps) {
  const user = useUserContext();

  const documentEdges =
    folderDocumentsData?.user?.collection?.tree?.documents?.edges ||
    sharedDocumentsData?.user?.collection?.tree?.documents?.edges;

  const documentsGroup = useMemo(
    () =>
      (documentEdges ?? []).reduce((acc, val) => {
        if (!acc[val.node.folderGuid]) {
          acc[val.node.folderGuid] = [];
        }

        acc[val.node.folderGuid].push(val);

        return acc;
      }, {}),
    [documentEdges]
  );

  const documentsToShow = useMemo(() => {
    const documentsWithSharerName: DocumentToShowWithSharerName[] = [];
    if (tab.id === TabLabel.ALL || isRealTrainer(user)) {
      Object.keys(documentsGroup).map((id) => {
        const documents = documentsGroup[id];
        documentsWithSharerName.push({
          sharerName: null,
          documents: [
            {
              documentId: id,
              folderName: "",
              edges: documents,
            },
          ],
        });
      });
    } else {
      Object.keys(documentsGroup).map((id) => {
        const documents = documentsGroup[id];
        const sharerName = documents[0].node.sharerName;
        const documentToUpdate = documentsWithSharerName.find(
          (documentWithSharerName) =>
            documentWithSharerName.sharerName === sharerName
        );
        const folderNameOfDocumentsGroup =
          documents.length && documents[0].node?.crumbs?.length
            ? documents[0].node?.crumbs?.[documents[0].node?.crumbs?.length - 1]
                .name
            : null;
        const documentToPush = {
          documentId: id,
          folderName: folderNameOfDocumentsGroup,
          edges: documents,
        };
        if (documentToUpdate) {
          documentToUpdate.documents.push(documentToPush);
        } else {
          documentsWithSharerName.push({
            sharerName,
            documents: [documentToPush],
          });
        }
      });
    }
    return documentsWithSharerName;
  }, [documentsGroup, tab, user]);

  const handleOnScroll = async (e) => {
    const isClose =
      e.target.clientHeight + e.target.scrollTop + 100 >= e.target.scrollHeight;

    if (isClose) {
      if (!isLoadingFolderDocuments) {
        getMoreDocuments(
          folderDocumentsData,
          folderDocumentsVariable,
          fetchMoreFolderDocuments
        );
      }
      if (!isLoadingSharedDocuments) {
        getMoreDocuments(
          sharedDocumentsData,
          sharedDocumentsVariable,
          fetchMoreSharedDocuments
        );
      }
    }
  };

  if (
    (isLoadingFolderDocuments &&
      folderDocumentsNetworkStatus !== NetworkStatus.fetchMore &&
      !folderDocumentsData) ||
    (isLoadingSharedDocuments &&
      sharedDocumentsNetworkStatus !== NetworkStatus.fetchMore &&
      !sharedDocumentsData)
  ) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (!Object.keys(documentsGroup)?.length) {
    return <EmptyResult tab={tab} search={search} />;
  }

  const additionalStyleStyle =
    tab.id === TabLabel.ALL
      ? { display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 10 }
      : {};

  return (
    <CollectionListWrapper>
      <CollectionItemsWrapper
        isFromMessaging={isFromMessaging}
        onScroll={handleOnScroll}
        style={additionalStyleStyle as CSSProperties}
      >
        {documentsToShow.map((documentToShow, index) => (
          <CollectionItem
            key={index}
            documentToShowIndex={index}
            documents={documentToShow.documents}
            sharerName={documentToShow.sharerName}
            tab={tab}
            onClick={onClick}
            focusedAthleteId={focusedAthleteId}
            handleSharedDocumentsRefetch={handleSharedDocumentsRefetch}
          />
        ))}
        <LoaderContainer>
          <Loader
            size="small"
            color={COLOR_BLUE}
            animating={isLoadingFolderDocuments || isLoadingSharedDocuments}
          />
        </LoaderContainer>
      </CollectionItemsWrapper>
    </CollectionListWrapper>
  );
}

export const MemoizedCollectionList = memo(CollectionList);
