import React from "react";
import Calendar, { CalendarProps } from "react-calendar";

import dayjs, { Dayjs } from "dayjs";

import { ChevronRightIcon } from "../Icons";

import "./styles.css";

// Minor renaming of props for better clarity, also we are using dayjs
export type ReactCalendarProps = Omit<
  CalendarProps,
  "value" | "defaultActiveStartDate" | "minDate" | "maxDate"
> & {
  value?: Dayjs;
  from: Dayjs;
  to: Dayjs;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  set: (date: Dayjs) => void;
};

export const ReactCalendar = (calendarProps: ReactCalendarProps) => {
  const { value, from, to, minDate, maxDate, set, onChange, ...rest } =
    calendarProps;

  const defaultActiveStartDate = new Date(value.toString());
  const calendarValue: [Date, Date] = [
    new Date(from.toString()),
    new Date(to.toString()),
  ];
  const calendarMinDate = minDate ? new Date(minDate.toString()) : undefined;
  const calendarMaxDate = maxDate ? new Date(maxDate.toString()) : undefined;

  const handleChange = (value: Date | null) => {
    set(dayjs(value?.toString()));
  };

  return (
    <Calendar
      key={defaultActiveStartDate.toString()}
      defaultActiveStartDate={defaultActiveStartDate}
      value={calendarValue}
      minDate={calendarMinDate}
      maxDate={calendarMaxDate}
      minDetail="month"
      next2Label={null}
      prev2Label={null}
      nextLabel={<ChevronRightIcon direction={"right"} />}
      prevLabel={<ChevronRightIcon direction={"left"} />}
      onChange={handleChange}
      {...rest}
    />
  );
};
