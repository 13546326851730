import React, { useCallback, useMemo, useState } from "react";

import { useApolloClient } from "@apollo/client";
import styled from "styled-components";

import { NewChatProvider } from "../../../contexts/messaging/NewChatProvider";
import { useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import {
  Avatar,
  Chatter,
  ChatterType,
  useAddParticipantsMutation,
  useFindChattableUsersQuery,
  useRemoveParticipantsMutation,
  useUpdateChatGroupMutation,
  WhoCanPost,
} from "../../../graphql";
import { MessageOverviewPaginated } from "../../../graphql/queries/MessageOverviewPaginated";
import { isRealTrainer } from "../../../utils/isRealTrainer";
import { UpsertGroupChatStage2ModalBody } from "../../Communication/UpsertGroupChatStage2ModalBody";
import { Loader } from "../../Loader";

import { BottomBar } from "./BottomBar";
import { GroupChatUserSelector } from "./GroupChatUserSelector";

interface EditCustomChatGroupModalProps {
  contact: Chatter;
  avatars: Avatar[];
  onRowPress: (contact: Chatter) => void;
  messageListRefetch: () => void;
}

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const EditCustomChatGroupModal = ({
  contact,
  avatars,
  onRowPress,
  messageListRefetch,
}: EditCustomChatGroupModalProps) => {
  const {
    id,
    isFamilyMember,
    isTrainer,
    sessionId,
    firstName,
    lastName,
    profileImageUrlThumb,
  } = useUserContext();
  const { actions: modalActions } = useModalContext();
  const client = useApolloClient();

  const canDeleteOrUpdate = contact?.canLoggedOnUserDelete;

  const [currentName, setCurrentName] = useState(contact.name);
  const [removedParticipants, setRemovedParticipants] = useState<string[]>([]);
  const [addedParticipants, setAddedParticipants] = useState<Chatter[]>([]);
  const [confirmedParticipants, setConfirmedParticipants] = useState<Chatter[]>(
    []
  );
  const [addingNewMembers, setAddingNewMembers] = useState(false);
  const [onlyTrainersCanPost, setOnlyTrainersCanPost] = useState(
    contact.onlyTrainersCanPost
  );

  const { data, loading } = useFindChattableUsersQuery({
    variables: { sessionId },
  });

  const [removeParticipantsFromChat] = useRemoveParticipantsMutation();
  const [addParticipantsToChat] = useAddParticipantsMutation();
  const [updateChatGroup] = useUpdateChatGroupMutation();

  const onRemoveParticipantClick = (participantId: string) => {
    setRemovedParticipants((old) => [...old, participantId]);
  };

  const handleRefreshAfterSave = async (updatedContact?: Partial<Chatter>) => {
    await client.refetchQueries({
      include: [MessageOverviewPaginated],
    });
    await messageListRefetch();
    onRowPress({ ...contact, ...updatedContact });
    modalActions.closeModal();
  };

  const onSaveClick = async () => {
    try {
      if (addedParticipants.length > 0) {
        await addParticipantsToChat({
          variables: {
            sessionId,
            chatterId: contact.id,
            userIDS: addedParticipants.map((participant) => participant.id),
          },
        });
      }

      if (removedParticipants.length > 0) {
        await removeParticipantsFromChat({
          variables: {
            sessionId,
            chatterId: contact.id,
            userIDS: removedParticipants,
          },
        });
      }

      if (canDeleteOrUpdate) {
        const res = await updateChatGroup({
          variables: {
            sessionId,
            chatterId: contact.id,
            input: { name: currentName, onlyTrainersCanPost },
          },
        });
        await handleRefreshAfterSave({
          ...res?.data?.updateChatGroup,
          onlyTrainersCanPost:
            res?.data?.updateChatGroup?.whoCanPost === WhoCanPost.Trainers,
        });
      } else {
        await handleRefreshAfterSave();
      }
    } catch {
      await handleRefreshAfterSave();
    }
  };

  const onAddUsersClick = () => {
    setAddingNewMembers(false);
    setConfirmedParticipants(addedParticipants);
  };

  const onCancelAddUsersClick = () => {
    setAddingNewMembers(false);
    setAddedParticipants(confirmedParticipants);
  };

  const usersInChat = useMemo(() => {
    const owner = {
      id,
      userID: id,
      name: `${firstName} ${lastName}`,
      avatarThumbURL: profileImageUrlThumb,
    };

    const ownerChatterType = isRealTrainer({ isFamilyMember, isTrainer })
      ? ChatterType.Trainer
      : ChatterType.Family;

    const chattableUsers =
      Array.from(
        new Set([
          ...avatars.map((avatar) => avatar.userID),
          ...addedParticipants.map((chatter) => chatter.id),
        ])
      )
        .filter(
          (userID) => userID !== id && !removedParticipants.includes(userID)
        )
        .map(
          (userID) =>
            data?.findChattableUsers?.find((user) => user.id === userID) ??
            avatars.find((avatar) => avatar.userID === userID)
        ) ?? [];

    return [...chattableUsers, { ...owner, chatterType2: ownerChatterType }];
  }, [
    avatars,
    data,
    removedParticipants,
    addedParticipants,
    id,
    isTrainer,
    isFamilyMember,
    firstName,
    lastName,
    profileImageUrlThumb,
  ]) as unknown as Chatter[];

  const onAttendingUsersChange = (users: Chatter[]) => {
    const removedUsers = usersInChat.filter(
      (originalUser) => !users.find((newUser) => newUser.id === originalUser.id)
    );

    const addedUsers = users.filter(
      (newUser) =>
        !usersInChat.find((originalUser) => originalUser.id === newUser.id)
    );

    setRemovedParticipants((previous) => [
      ...previous.filter(
        (alreadyRemovedUserId) =>
          !users.some((changedUser) => changedUser.id === alreadyRemovedUserId)
      ),
      ...removedUsers.map((user) => user.id),
    ]);

    setAddedParticipants((previous) =>
      Array.from(new Set([...previous, ...addedUsers]))
    );
  };

  const handleCloseModal = useCallback(
    () => modalActions.closeModal(),
    [modalActions]
  );

  const handleAddingNewMembers = useCallback(
    () => setAddingNewMembers(true),
    [setAddingNewMembers]
  );

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <NewChatProvider>
      {addingNewMembers ? (
        <>
          <GroupChatUserSelector
            attendingUsers={usersInChat}
            setAttendingUsers={onAttendingUsersChange}
          />
          <BottomBar
            variant="group"
            closeModal={onCancelAddUsersClick}
            done={onAddUsersClick}
            isMultistage
            editMode
            count={usersInChat?.length}
          />
        </>
      ) : (
        <>
          <UpsertGroupChatStage2ModalBody
            groupName={currentName}
            setGroupName={setCurrentName}
            onlyTrainersCanPost={onlyTrainersCanPost}
            setOnlyTrainersCanPost={setOnlyTrainersCanPost}
            usersInChat={usersInChat}
            isEditChatMode
            onRemoveChatterFromChat={onRemoveParticipantClick}
            onAddNewParticipantsClick={handleAddingNewMembers}
            canUpdate={canDeleteOrUpdate}
          />
          <BottomBar
            variant="group"
            disableDone={!currentName?.trim()}
            closeModal={handleCloseModal}
            done={onSaveClick}
            isMultistage
            editMode
            count={usersInChat?.length}
          />
        </>
      )}
    </NewChatProvider>
  );
};
