import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../colors";
import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../contexts/accessLevels";
import { usePracticeContext } from "../contexts/practice/PracticeProvider";
import { TopModalType, useTopModalContext } from "../contexts/UI/TopModal";
import { useUserContext } from "../contexts/User";
import { FilteredDocuments } from "../contexts/workout/WorkoutEditorProvider";
import {
  FolderTreeDocumentsQuery,
  setClientParam,
  TreeType,
  useFolderTreeDocumentsQuery,
} from "../graphql";

import { GeneralButton } from "./Button/";
import { fetchAllCollections } from "./Collections/allCollections";
import { normalizeString } from "./Filters/utils";
import { FileList } from "./Modals/CreateDrill/FileList";
import { FolderItem } from "./Modals/CreateDrill/FolderItem";
import { FolderStructureTabLabel } from "./Modals/CreateDrill/model";
import { SearchBar } from "./SearchBar/SearchBar";
import { Spinner } from "./Spinner";
import Tabs, { Tab } from "./Tabs";

export const FOLDER_STRUCTURE_SECTION_WIDTH = 370;

type FolderStructureProps = {
  isWorkout?: boolean;
  isPractice?: boolean;
  folderListStyle?: CSSProperties;
  style?: CSSProperties;
  onCreateNewDrill?: (name: string) => void;
  setFilteredDocuments?: (documents: FilteredDocuments) => void;
  saveSearchValue?: (searchValue: string) => void;
  onFolderDocumentsChange?: (res: FolderTreeDocumentsQuery) => void;
  selectedWorkoutFolder?: string | null;
  setSelectedWorkoutFolder?: (folderId: string | null) => void;
  changeTabOrCollectionType?: (collectionType?: TreeType) => void;
  changeCollectionId?: (collectionId: string) => void;
  selectedWorkoutFolderFullId?: string | null;
  setSelectedWorkoutFolderFullId?: (folderId: string | null) => void;
  accountIdForCollections?: string;
  onFileDragging?: (isDragging: boolean) => void;
};

const Wrapper = styled.div<{ isWorkout?: boolean }>`
  border-left: 1px solid ${COLOR_GREY_ACTIVE};
  width: ${({ isWorkout }) =>
    isWorkout ? "auto" : `${FOLDER_STRUCTURE_SECTION_WIDTH}px`};
  height: 100%;
  flex-direction: column;
  overflow-y: hidden;
`;

const Header = styled.div`
  height: 45px;
  margin-top: 10px;
  display: flex;
`;

const FolderList = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 210px);
  overflow-y: auto;
  #folders-empty {
    display: none;

    &:last-child {
      display: block;
    }
  }
`;

const ButtonWrapper = styled.div`
  padding: 10px;
`;

const FilterButton = styled.button<{ isActive?: boolean }>`
  border-radius: 12px;
  border: 1px solid ${COLOR_BLUE};
  background-color: ${({ isActive }) => (isActive ? COLOR_BLUE : COLOR_WHITE)};
  padding: 4px 10px;
  margin-right: 5px;
  cursor: pointer;
`;

const Label = styled.span<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? COLOR_WHITE : COLOR_BLUE)};
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;

const SearchBarWrapper = styled.div<{ isPractice?: boolean }>`
  padding: 0 10px;
  margin-top: ${({ isPractice }) => (isPractice ? "30px" : "0")};
`;

const EmptyFolderList = styled.div`
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
`;

const StyledGeneralButton = styled(GeneralButton)`
  margin: auto;
`;

const Divider = styled.div`
  background-color: ${COLOR_GREY_ACTIVE};
  width: 100%;
  height: 1px;
`;

function getTabs(isWorkout: boolean, t: TFunction) {
  const common = [
    { id: FolderStructureTabLabel.DRILL, title: t("drills").toUpperCase() },
  ];

  if (isWorkout) {
    return [
      {
        id: FolderStructureTabLabel.EXERCISE_SET,
        title: t("exercises").toUpperCase(),
      },
      ...common,
      { id: FolderStructureTabLabel.TESTS, title: t("tests").toUpperCase() },
    ];
  }

  return common;
}

export function FolderStructure({
  isWorkout = false,
  isPractice = false,
  folderListStyle,
  setFilteredDocuments,
  saveSearchValue,
  onCreateNewDrill,
  onFolderDocumentsChange,
  selectedWorkoutFolder,
  setSelectedWorkoutFolder,
  changeTabOrCollectionType,
  changeCollectionId,
  setSelectedWorkoutFolderFullId,
  selectedWorkoutFolderFullId,
  accountIdForCollections,
  onFileDragging,
}: FolderStructureProps) {
  const { sessionId, id } = useUserContext();
  const { t } = useTranslation();
  const tabs = getTabs(isWorkout, t);
  const { accessLevels, selectedAccount } = useAccessLevelsContext();
  const { setSelectedTab, setSelectedFolder } = usePracticeContext();
  const topModal = useTopModalContext();

  const [collection, setCollection] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [tab, setTab] = useState<Tab>(tabs[0]);
  const [collectionType, setCollectionType] = useState(TreeType.Folders);

  const selectedAccountId = useMemo(
    () =>
      accountIdForCollections ??
      (selectedAccount?.id === ALL_ACCOUNTS_ID
        ? accessLevels[1]?.user?.id
        : selectedAccount?.id),
    [selectedAccount, accessLevels, accountIdForCollections]
  );

  const { data: folderDocumentsData, loading: isLoadingFolderDocuments } =
    useFolderTreeDocumentsQuery({
      variables: {
        collectionId: `false.${tab.id}.${selectedAccountId}`,
        collectionType,
        desktopStyleSearchText:
          collectionType === TreeType.Tags ? "" : searchValue,
      },
      skip: !collection?._selectedAccount,
    });

  useEffect(() => {
    if (changeCollectionId) {
      changeCollectionId(`false.${tab.id}.${selectedAccountId}`);
    }
  }, [tab, selectedAccountId, changeCollectionId]);

  useEffect(
    function handleTabChange() {
      if (tab) {
        setSelectedTab(tab);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tab]
  );

  useEffect(() => {
    if (onFolderDocumentsChange) {
      onFolderDocumentsChange(folderDocumentsData);
    }
  }, [folderDocumentsData, onFolderDocumentsChange]);

  useEffect(() => {
    async function loadAllCollections() {
      const { data } = await fetchAllCollections(sessionId);
      setCollection(data);
    }

    if (sessionId && id) {
      setClientParam("sessionId", sessionId);
      loadAllCollections();
    }
  }, [sessionId, id]);

  useEffect(() => {
    if (
      folderDocumentsData?.user?.collection?.tree?.documents?.edges &&
      setFilteredDocuments
    ) {
      setFilteredDocuments(
        folderDocumentsData?.user?.collection?.tree?.documents?.edges ?? []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderDocumentsData?.user?.collection?.tree?.documents?.edges]);

  const { folders, documents } = useMemo(() => {
    if (folderDocumentsData?.user?.collection?.tree?.children) {
      return {
        folders: folderDocumentsData.user.collection.tree.children,
        documents: (
          folderDocumentsData.user.collection.tree.documents?.edges ?? []
        )
          .filter((d) => !d.node.folderGuid)
          .filter((d) => {
            if (!searchValue) {
              return true;
            }
            return normalizeString(d.node?.name ?? "")?.includes(
              normalizeString(searchValue)
            );
          }),
      };
    }

    return { folders: [], documents: [] };
  }, [folderDocumentsData, searchValue]);

  const handleSearch = (txt: string) => {
    setSearchValue(txt);
    if (saveSearchValue !== undefined) {
      saveSearchValue(txt);
    }
  };

  const handleCreateNewDrill = () => {
    if (searchValue) {
      onCreateNewDrill(searchValue);
      setSearchValue("");
    } else {
      topModal.actions.openTopModal({
        modal: TopModalType.CREATE_NEW_DRILL,
        title: t("createNewDrill"),
        params: {
          handleCreateNewDrill: (drillName) => onCreateNewDrill(drillName),
        },
      });
    }
  };

  return (
    <Wrapper isWorkout={isWorkout}>
      {isWorkout ? (
        <>
          <Header>
            <Tabs
              tab={tab}
              tabs={tabs}
              style={{ flexFlow: "nowrap" }}
              setTab={(newTab) => {
                setTab(newTab);
                changeTabOrCollectionType?.();
                setSelectedFolder("");
              }}
            />
          </Header>
          <Divider />
          <ButtonWrapper>
            <FilterButton
              isActive={collectionType === TreeType.Folders}
              onClick={() => {
                changeTabOrCollectionType?.(TreeType.Folders);
                setCollectionType(TreeType.Folders);
                setSelectedFolder("");
              }}
            >
              <Label isActive={collectionType === TreeType.Folders}>
                {t("all")}
              </Label>
            </FilterButton>
            <FilterButton
              isActive={collectionType === TreeType.Tags}
              onClick={() => {
                changeTabOrCollectionType?.(TreeType.Tags);
                setCollectionType(TreeType.Tags);
                setSelectedFolder("");
              }}
            >
              <Label isActive={collectionType === TreeType.Tags}>
                {t("tags")}
              </Label>
            </FilterButton>
          </ButtonWrapper>
        </>
      ) : null}
      <SearchBarWrapper isPractice={isPractice}>
        <SearchBar
          searchValue={searchValue}
          placeholder={t("search")}
          backgroundColor={COLOR_GRAY}
          setSearchValue={handleSearch}
        />
      </SearchBarWrapper>
      {/*@ts-ignore*/}
      <FolderList
        style={folderListStyle}
        onClick={() => {
          // unselect folder on outside click
          setSelectedFolder("");
        }}
      >
        {isLoadingFolderDocuments ? (
          <Spinner />
        ) : folderDocumentsData ? (
          <div id="folders-empty">
            <EmptyFolderList>{t("noResults")}</EmptyFolderList>
            {isPractice && (
              <StyledGeneralButton
                label={t("createNewDrill")}
                onClick={handleCreateNewDrill}
              />
            )}
          </div>
        ) : null}
        {folders?.map((folder) => (
          <FolderItem
            isTag={collectionType === TreeType.Tags}
            key={folder.id}
            folder={folder}
            collectionId={tab.id}
            isWorkout={isWorkout}
            allOpen={!!searchValue}
            isLoading={isLoadingFolderDocuments}
            searchValue={searchValue}
            documents={
              folderDocumentsData.user.collection.tree.documents?.edges ?? []
            }
            rootFolder
            onFolderDocumentsChange={onFolderDocumentsChange}
            selectedWorkoutFolder={
              collectionType === TreeType.Tags
                ? selectedWorkoutFolderFullId
                : selectedWorkoutFolder
            }
            setSelectedWorkoutFolder={(id) => {
              setSelectedWorkoutFolderFullId?.(folder.id);
              setSelectedWorkoutFolder?.(id);
            }}
            onFileDragging={onFileDragging}
          />
        ))}
        {documents.length && collectionType !== TreeType.Tags ? (
          <FileList
            loading={false}
            data={documents}
            collectionId={tab.id}
            searchValue={searchValue}
            onFileDragging={onFileDragging}
          />
        ) : null}
      </FolderList>
    </Wrapper>
  );
}
