import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import _partition from "lodash/partition";

import { roundToTwo } from "../../../utils/number";
import {
  GroupTableStats,
  GroupTableStatsData,
  GroupTableStatsValue,
} from "../../ReportCreateTable/hooks/useGroupStatsData/helpers/models";
import { GroupTableSortBy } from "../../ReportCreateTable/models";

import { getFormattedTimeOrValueWithAttributeFromDecimal } from "./helpers/getFormattedTimeOrValueWithAttributeFromDecimal";

type UseGroupTableRowsDataProps = {
  tableData: GroupTableStatsData;
  sumRow?: boolean;
  averageRow?: boolean;
  includeZeroValues?: boolean;
  includeEmptyValues?: boolean;
  sortByConfig: GroupTableSortBy & {
    order: number;
  };
  maxCount?: number;
};

export function useGroupTableRowsData({
  tableData,
  sortByConfig,
  sumRow,
  averageRow,
  includeZeroValues,
  includeEmptyValues,
  maxCount,
}: UseGroupTableRowsDataProps) {
  const { t } = useTranslation();

  const rowsData = useMemo(() => {
    const { statsIndex, valueIndex, attributeIndex, order } = sortByConfig;

    function getStatsValue(
      groupTableStats: GroupTableStats,
      { statsIndex, attributeIndex, valueIndex }: GroupTableSortBy
    ): GroupTableStatsValue {
      return (
        groupTableStats?.stats[statsIndex]?.attributes[attributeIndex]?.values[
          valueIndex
        ] ?? {}
      );
    }

    if (
      statsIndex === null ||
      valueIndex === null ||
      attributeIndex === null ||
      tableData.length === 0
    ) {
      return tableData;
    }

    let filterFunction = (item: GroupTableStats) => true;
    let comparisonFunction = (r1: GroupTableStats, r2: GroupTableStats) => 0;

    if (statsIndex === "athlete") {
      comparisonFunction = (r1, r2) =>
        r1.athleteName.localeCompare(r2.athleteName) * order;
    } else if (getStatsValue(tableData[0], sortByConfig).isInfo) {
      filterFunction = (r) =>
        getStatsValue(r, sortByConfig).txt !== "-" &&
        Boolean(getStatsValue(r, sortByConfig).txt);

      comparisonFunction = (r1, r2) =>
        getStatsValue(r1, sortByConfig)
          .txt.toString()
          .localeCompare(getStatsValue(r2, sortByConfig).txt.toString()) *
        order;
    } else {
      filterFunction = (r) =>
        !isNaN(getStatsValue(r, sortByConfig).value as number);

      comparisonFunction = (r1, r2) => {
        const firstValue = Number(getStatsValue(r1, sortByConfig).value);
        const secondValue = Number(getStatsValue(r2, sortByConfig).value);

        return (firstValue - secondValue) * order;
      };
    }

    const [sortableStats, restStats] = _partition(tableData, filterFunction);

    return [...sortableStats.sort(comparisonFunction), ...restStats].slice(
      0,
      maxCount || undefined
    );
  }, [tableData, sortByConfig, maxCount]);

  const sumStatsRaw = useMemo(() => {
    if (rowsData.length === 0) {
      return { athleteName: "SUM_RAW", stats: [] };
    }

    if (rowsData.length === 1) {
      return { athleteName: "SUM_RAW", stats: rowsData[0].stats };
    }

    return rowsData.reduce((mem, curr) => {
      const stats = mem.stats.map((stat, statIndex) => ({
        label: stat.label,
        attributes: stat.attributes.map((attribute, attributeIndex) => ({
          trendValues: attribute.trendValues?.map(
            (trendValueObject, trendIndex) => {
              const currTrendObject =
                curr.stats[statIndex].attributes[attributeIndex].trendValues[
                  trendIndex
                ];

              return {
                ...trendValueObject,
                value: trendValueObject.value + currTrendObject.value,
              };
            }
          ),
          values: attribute.values.map((sumValueObject, valueIndex) => {
            const sumValue = !isNaN(Number(sumValueObject.value))
              ? Number(sumValueObject.value)
              : 0;
            const countValue = !isNaN(Number(sumValueObject.count))
              ? Number(sumValueObject.count)
              : 0;
            const currValueObject =
              curr.stats[statIndex].attributes[attributeIndex].values[
                valueIndex
              ];
            const currEmptyValue = isNaN(Number(currValueObject.value));
            const currValue = !isNaN(Number(currValueObject.value))
              ? Number(currValueObject.value)
              : 0;
            const newValue = sumValueObject.isInfo ? "-" : sumValue + currValue;

            let newCount = countValue;

            if (!sumValueObject.isInfo) {
              if (includeEmptyValues && includeZeroValues) {
                newCount += 1;
              } else if (includeZeroValues && !currEmptyValue) {
                newCount += 1;
              } else if (includeEmptyValues && currEmptyValue) {
                newCount += 1;
              } else if (currValue) {
                newCount += 1;
              }
            }

            return {
              ...sumValueObject,
              value: newValue,
              count: newCount,
            };
          }),
        })),
      }));

      return { athleteName: "SUM_RAW", stats };
    });
  }, [rowsData, includeZeroValues, includeEmptyValues]);

  const sumData = useMemo(() => {
    if (!sumRow) {
      return undefined;
    }

    return {
      athleteName: t("aggregationSum").toUpperCase(),
      isSummaryStatistic: true,
      hideTrend: true,
      stats: sumStatsRaw.stats.map((stat) => ({
        label: stat.label,
        attributes: stat.attributes.map((attribute) => ({
          trendValues: attribute.trendValues?.map((trendValueObject) => ({
            ...trendValueObject,
            value: roundToTwo(trendValueObject.value),
          })),
          values: attribute.values.map((sumValueObject) => {
            const sumValue = !isNaN(Number(sumValueObject.value))
              ? Number(sumValueObject.value)
              : 0;
            const newValue = sumValueObject.isInfo ? "-" : roundToTwo(sumValue);

            return {
              ...sumValueObject,
              txt:
                newValue === "-" || newValue === 0
                  ? "-"
                  : getFormattedTimeOrValueWithAttributeFromDecimal(
                      newValue,
                      sumValueObject.timeUnit,
                      sumValueObject.unitName
                    ),
              value: undefined,
            };
          }),
        })),
      })),
    };
  }, [sumRow, sumStatsRaw, t]);

  const averageData = useMemo(() => {
    if (!averageRow) {
      return undefined;
    }

    return {
      athleteName: t("aggregationAverage").toUpperCase(),
      isSummaryStatistic: true,
      hideTrend: true,
      stats: sumStatsRaw.stats.map((stat) => ({
        label: stat.label,
        attributes: stat.attributes.map((attribute) => ({
          trendValues: attribute.trendValues?.map((trendValueObject) => ({
            ...trendValueObject,
            value: roundToTwo(trendValueObject.value / tableData.length),
          })),
          values: attribute.values.map((sumValueObject) => {
            const sumValue = !isNaN(Number(sumValueObject.value))
              ? Number(sumValueObject.value)
              : 0;
            const countOfItems = sumValueObject.count;
            const newValue =
              sumValueObject.isInfo || countOfItems === 0
                ? "-"
                : roundToTwo(sumValue / countOfItems);

            return {
              ...sumValueObject,
              txt:
                newValue === "-" ||
                newValue === 0 ||
                (typeof newValue === "number" && isNaN(newValue as number))
                  ? "-"
                  : getFormattedTimeOrValueWithAttributeFromDecimal(
                      newValue,
                      sumValueObject.timeUnit,
                      sumValueObject.unitName
                    ),
              value: undefined,
            };
          }),
        })),
      })),
    };
  }, [averageRow, sumStatsRaw, tableData, t]);

  return useMemo(() => {
    const statsData: GroupTableStatsData = [];

    if (sumData) {
      statsData.push(sumData);
    }

    if (averageData) {
      statsData.push(averageData);
    }

    return [...rowsData, ...statsData];
  }, [rowsData, sumData, averageData]);
}
