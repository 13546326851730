import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useApolloClient } from "@apollo/client";
import dayjs from "dayjs";

import { PracticeLine } from "../../components/Modals/CreateDrill/model";
import { CreatePracticeFormState } from "../../components/Modals/CreatePracticeModal";
import { useNotificationsContext } from "../../contexts/notifications";
import {
  PracticeLineWithDraft,
  usePracticeContext,
} from "../../contexts/practice/PracticeProvider";
import { useUserContext } from "../../contexts/User";
import {
  ResourcesUsageInput,
  Split,
  useUpdateSubPracticeMutation,
  useCreatePracticeMutation,
  useCreateSplitMutation,
  useUpdateSessionMutation,
  useUpdateSubPracticeParticipationMutation,
  Maybe,
  SubPractice,
  useDeleteSplitMutation,
  useDeleteSubPracticeMutation,
  useUpdateSplitMutation,
} from "../../graphql";
import useSendManualNotificationAfterAction from "../useSendManualNotificationAfterAction";

import { useCreateSplitItemWithStructure } from "./useCreateSplitItemWithStructure";
import { useDeleteDrill } from "./useDeleteDrill";
import { useSaveDrillOrComment } from "./useSaveDrillOrComment";
import { useUpdateDrillOrComment } from "./useUpdateDrillOrComment";

type SplitWithLinesToRemove = Omit<Split, "subPractices"> & {
  index?: number;
  hasBeenMoved?: boolean;
  subPractices?: Maybe<
    (Maybe<SubPractice> & {
      practiceLinesToRemove?: { id: string }[];
    })[]
  >;
};

export const useCreatePracticeWithDrills = () => {
  const { t } = useTranslation();
  const { sessionId, timezone, language } = useUserContext();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();
  const client = useApolloClient();
  const { setOriginalPracticeLines } = usePracticeContext();

  const [createPracticeMutation] = useCreatePracticeMutation();
  const [addSplitToPracticeMutation] = useCreateSplitMutation();
  const [updateSession] = useUpdateSessionMutation();
  const [updateSplit] = useUpdateSplitMutation();

  const { saveDrillOrCommentPracticeLines, saveDrillOrComment } =
    useSaveDrillOrComment();
  const { updateDrillOrComment } = useUpdateDrillOrComment();
  const { deleteDrill } = useDeleteDrill();
  const { createSplitItemWithStructure } = useCreateSplitItemWithStructure();
  const [deleteSplit] = useDeleteSplitMutation();
  const [deleteSubPractice] = useDeleteSubPracticeMutation();

  const { handleSendManualNotificationAfterAction } =
    useSendManualNotificationAfterAction();
  const [updateSubPracticeParticipationMutation] =
    useUpdateSubPracticeParticipationMutation();
  const [updateSubPracticeMutation] = useUpdateSubPracticeMutation();
  const [isLoading, setIsLoading] = useState(false);

  const createPracticeWithDrills = async ({
    data,
    practiceId,
    selectedGoogleLocationId,
    resourcesUsage,
    practiceLines,
    groupId,
    practiceLinesToRemove,
    originalPracticeLines,
  }: {
    data: CreatePracticeFormState;
    practiceId: string | null;
    selectedGoogleLocationId: string;
    resourcesUsage: ResourcesUsageInput;
    practiceLines: PracticeLineWithDraft[];
    groupId: string;
    practiceLinesToRemove?: PracticeLine[];
    originalPracticeLines?: PracticeLine[];
  }) => {
    setIsLoading(true);
    try {
      const [timeHour, timeMinute] = data.time.split(":");
      const updatedHours = dayjs(data?.date).set("hour", Number(timeHour));
      const finalDate = updatedHours.set("minute", Number(timeMinute));

      if (practiceId) {
        const updateSessionResult: any = await updateSession({
          variables: {
            sessionId,
            timezone,
            language,
            input: {
              id: practiceId,
              groupID: groupId,
              label: data?.title,
              location: data?.location,
              xtraLocation: data?.locationDescription,
              notes: data?.notes,
              specialNotes: data?.coachNotes,
              start: finalDate.valueOf(),
              colorHexCode: data?.color,
              durationMinutes: data?.duration,
              attendedMinutesBefore: data?.attendance,
              googleLocationId: selectedGoogleLocationId,
              resourcesUsage,
            },
          },
        });

        const existingPracticeLines = practiceLines
          ?.map((line, index) => ({
            ...line,
            index: line.hasBeenMoved ? index : null,
          }))
          ?.filter((line) => line?.__typename !== "Document" && !line.isDraft);

        const nonExistingPracticeLines = practiceLines
          ?.map((line, index) => ({
            ...line,
            index,
          }))
          ?.filter((line) => line?.__typename === "Document" || line.isDraft);

        if (practiceLinesToRemove?.length) {
          try {
            await deleteDrill({
              practiceLines: practiceLinesToRemove,
              practiceId,
            });
          } catch (e) {
            console.error(e);
          }
        }

        if (existingPracticeLines?.length > 0) {
          try {
            await updateDrillOrComment({
              practiceID: practiceId,
              practiceLines: existingPracticeLines,
              originalPracticeLines,
            });
          } catch (e) {
            console.error(e);
          }
        }

        if (nonExistingPracticeLines?.length) {
          try {
            await saveDrillOrCommentPracticeLines({
              practiceID: practiceId,
              practiceLines: nonExistingPracticeLines,
            });
          } catch (e) {
            console.error(e);
          }
        }

        const practiceLinesWithIndex = practiceLines?.map((line, index) => ({
          ...line,
          index,
        }));

        const existingSplits = practiceLinesWithIndex?.filter(
          (line) => line?.__typename === "Split" && !line?.isDraft
        );

        const nonExistingSplits = practiceLinesWithIndex?.filter(
          (line) => line?.__typename === "Split" && line?.isDraft
        );

        if (nonExistingSplits?.length) {
          const createdSplits = await Promise.all(
            // @ts-ignore
            nonExistingSplits.map(async (split: SplitWithLinesToRemove) => {
              try {
                const response = await addSplitToPracticeMutation({
                  variables: {
                    sessionId,
                    input: {
                      practiceGuid: practiceId,
                      nrOfSubPractices: split.subPractices?.length,
                      splitID: null,
                    },
                  },
                });

                const subPracticesIds =
                  response?.data?.createSplit?.subPractices?.map(
                    (subPractice) => subPractice.id
                  );
                // For each split add all drills in it
                await Promise.all(
                  split.subPractices.map(
                    async (practiceSplit, practiceSplitIndex) => {
                      await saveDrillOrCommentPracticeLines({
                        practiceID: practiceId,
                        subPracticeID: subPracticesIds[practiceSplitIndex],
                        practiceLines: practiceSplit.practiceLines,
                      });
                      if (practiceSplit.comment) {
                        await updateSubPracticeMutation({
                          variables: {
                            sessionId,
                            input: {
                              id: subPracticesIds[practiceSplitIndex],
                              notes: practiceSplit.comment,
                            },
                          },
                        });
                      }
                      if (practiceSplit.athletes?.length) {
                        await updateSubPracticeParticipationMutation({
                          variables: {
                            input: {
                              added: practiceSplit.athletes.map(
                                (athlete) => athlete.id
                              ),
                              subPracticeID:
                                subPracticesIds[practiceSplitIndex],
                            },
                          },
                        });
                      }
                    }
                  )
                );

                return {
                  createdSplitId: response?.data?.createSplit?.id,
                  hasBeenMoved: split.hasBeenMoved,
                  idx: split.index,
                };
              } catch (e) {
                console.error(e);
              }
            })
          );

          // Unfortunately, we need to edit split once it is created if it was also moved after creation and before save as API does not use idx for freshly created splits
          for (const split of createdSplits) {
            if (split.hasBeenMoved) {
              await updateSplit({
                variables: {
                  sessionId,
                  input: {
                    splitID: split.createdSplitId,
                    idx: split.idx,
                  },
                },
              });
            }
          }
        }

        if (existingSplits?.length) {
          await Promise.all(
            // @ts-ignore
            existingSplits.map(async (split: SplitWithLinesToRemove) => {
              const subPracticesIds = split?.subPractices?.map(
                (subPractice) => subPractice.id
              );

              if (!subPracticesIds.length) {
                await deleteSplit({ variables: { splitID: split.id } });
              } else if (split.hasBeenMoved) {
                await updateSplit({
                  variables: {
                    sessionId,
                    input: {
                      splitID: split.id,
                      idx: split.index,
                    },
                  },
                });
              }

              const originalPracticeLine = originalPracticeLines.find(
                (original) => original?.id === split?.id
              ) as SplitWithLinesToRemove;

              const originalPracticeLinesSubPracticesIds =
                originalPracticeLine?.subPractices?.map(
                  (subPractice) => subPractice.id
                );

              const subPracticeToDelete =
                originalPracticeLinesSubPracticesIds?.filter(
                  (item) => !subPracticesIds.includes(item)
                );

              if (subPracticeToDelete?.length) {
                await Promise.all(
                  subPracticeToDelete?.map(async (item) => {
                    return deleteSubPractice({
                      variables: { sessionId, subPracticeID: item },
                    });
                  })
                );
              }

              // For each split add all drills in it
              await Promise.all(
                split.subPractices.map(
                  async (practiceSplit, practiceSplitIndex) => {
                    try {
                      await updateSubPracticeMutation({
                        variables: {
                          sessionId,
                          input: {
                            id: subPracticesIds[practiceSplitIndex],
                            notes: practiceSplit.comment,
                          },
                        },
                      });
                    } catch (e) {
                      console.error(e);
                    } finally {
                      try {
                        if (practiceSplit.athletes?.length) {
                          await updateSubPracticeParticipationMutation({
                            variables: {
                              input: {
                                added: practiceSplit.athletes.map(
                                  (athlete) => athlete.id
                                ),
                                subPracticeID:
                                  subPracticesIds[practiceSplitIndex],
                              },
                            },
                          });
                        }
                      } catch (e) {
                        console.error(e);
                      } finally {
                        if (practiceSplit.practiceLinesToRemove?.length) {
                          await deleteDrill({
                            practiceLines: practiceSplit.practiceLinesToRemove,
                          });
                        }

                        const draftPracticeLines = practiceSplit.practiceLines
                          .map((line, index) => ({ ...line, index }))
                          ?.filter(
                            (practiceLine) =>
                              // @ts-ignore
                              practiceLine.__typename === "Document"
                          );

                        if (draftPracticeLines.length) {
                          await saveDrillOrCommentPracticeLines({
                            practiceID: practiceId,
                            subPracticeID: practiceSplit.id,
                            practiceLines: draftPracticeLines,
                          });
                        }

                        const editedLines = practiceSplit.practiceLines
                          .map((line, index) => ({ ...line, index }))
                          // @ts-ignore -> it is handled as draft line
                          .filter((line) => line.__typename !== "Document");

                        if (editedLines.length) {
                          await updateDrillOrComment({
                            practiceID: practiceId,
                            subPracticeID: subPracticesIds[practiceSplitIndex],
                            practiceLines: editedLines,
                            originalPracticeLines: (
                              originalPracticeLines.find(
                                (line) => line.id === split.id
                              ) as Split
                            )?.subPractices?.find(
                              (subPractice) =>
                                subPractice.id === practiceSplit.id
                            )?.practiceLines,
                          });
                        }
                      }
                    }
                  }
                )
              );
            })
          );
        }

        showSuccessNotification(t("updatePracticeSuccess"));

        handleSendManualNotificationAfterAction(
          {
            ...data,
            id: practiceId,
          },
          updateSessionResult?.data?.updateSession?.notificationToSuggest
        );
      } else {
        // Create practice session
        const { data: createdPracticeData } = await createPracticeMutation({
          variables: {
            sessionId,
            timezone,
            language,
            input: {
              id: practiceId,
              groupID: groupId,
              label: data?.title,
              location: data?.location,
              xtraLocation: data?.locationDescription,
              notes: data?.notes,
              specialNotes: data?.coachNotes,
              start: finalDate.valueOf(),
              colorHexCode: data?.color,
              durationMinutes: data?.duration,
              attendedMinutesBefore: data?.attendance,
              googleLocationId: selectedGoogleLocationId,
              resourcesUsage,
              useTemplateForGroup: false,
            },
          },
          refetchQueries: [],
        });

        // It is important to go here one by one because split is always added to the end of the practice
        for (const [index, practiceLine] of practiceLines.entries()) {
          if (practiceLine.__typename === "Split") {
            await createSplitItemWithStructure(
              {
                subPractices: practiceLine.subPractices,
                idx: index + 1,
              },
              createdPracticeData?.createPractice?.id
            );
          } else {
            await saveDrillOrComment(
              {
                ...practiceLine,
                index,
              },
              index,
              createdPracticeData?.createPractice?.id,
              null
            );
          }
        }

        showSuccessNotification(t("createPracticeSuccess"));

        handleSendManualNotificationAfterAction(
          { ...createdPracticeData?.createPractice },
          createdPracticeData?.createPractice?.notificationToSuggest
        );
      }

      // refetch queries after all operations
      client.refetchQueries({
        include: ["agenda", "resourceCalendar", "SessionDetail"],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      if (practiceId) {
        showErrorNotification(t("updatePracticeError"));
      } else {
        showErrorNotification(t("createPracticeError"));
      }
    } finally {
      setOriginalPracticeLines(practiceLines);
    }
  };

  return { createPracticeWithDrills, createPracticeLoading: isLoading };
};
