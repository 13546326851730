import { useCallback } from "react";

import { PracticeLine } from "../../components/Modals/CreateDrill/model";
import { useUserContext } from "../../contexts/User";
import {
  useDeleteDrillMutation,
  useDeleteInlinePracticeCommentMutation,
} from "../../graphql";

export function useDeleteDrill() {
  const { sessionId } = useUserContext();

  const [deleteDrillMutation] = useDeleteDrillMutation();
  const [deleteInlinePracticeCommentMutation] =
    useDeleteInlinePracticeCommentMutation();

  const deleteDrill = useCallback(
    async ({
      practiceLines,
      practiceId,
    }: {
      practiceLines: PracticeLine[];
      practiceId?: string;
    }) => {
      return Promise.all(
        practiceLines?.map(async (practiceLine) => {
          if (practiceLine?.__typename === "PracticeInlineComment") {
            await deleteInlinePracticeCommentMutation({
              variables: {
                sessionId,
                input: {
                  id: practiceLine?.id,
                  practiceID: practiceId,
                },
              },
            });
          }
          return await deleteDrillMutation({
            variables: {
              sessionId,
              drillID: practiceLine.id,
            },
          });
        })
      );
    },
    [deleteDrillMutation, deleteInlinePracticeCommentMutation, sessionId]
  );

  return { deleteDrill };
}
