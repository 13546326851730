import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../../colors";
import { Chatter } from "../../graphql";
import { Checkbox } from "../Checkbox";
import { PlusInRoundIcon } from "../Icons";
import StyledText from "../StyledText";
import { StyledTextInput } from "../StyledTextInput";

import {
  ChatMemberListItem,
  ChatMemberListItemWrapper,
} from "./ChatMemberListItem";
import { MembersTitleDivider } from "./styled";

const ControlSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 20px 20px 20px;
`;

const AddIconWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`;

const MembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(90vh - 280px);
`;

interface UpsertGroupChatStage2ModalBodyProps {
  groupName: string;
  onlyTrainersCanPost: boolean;
  usersInChat: Chatter[];
  isEditChatMode?: boolean;
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
  setOnlyTrainersCanPost: React.Dispatch<React.SetStateAction<boolean>>;
  onRemoveChatterFromChat?: (userId: string) => void;
  onAddNewParticipantsClick?: () => void;
  canUpdate?: boolean;
}

export function UpsertGroupChatStage2ModalBody({
  groupName,
  onlyTrainersCanPost,
  usersInChat,
  isEditChatMode,
  setGroupName,
  setOnlyTrainersCanPost,
  onRemoveChatterFromChat,
  onAddNewParticipantsClick = () => ({}),
  canUpdate = true,
}: UpsertGroupChatStage2ModalBodyProps) {
  const { t } = useTranslation();

  return (
    <>
      <ControlSectionWrapper>
        <StyledText fontSize={10} fontWeight="semibold">
          {t("title").toUpperCase()}
        </StyledText>
        <StyledTextInput
          onChangeText={setGroupName}
          value={groupName}
          placeholder={t("groupName")}
          style={{ marginTop: 8 }}
          disabled={!canUpdate}
        />

        <Checkbox
          text={t("coachLimitation")}
          check={setOnlyTrainersCanPost}
          checked={onlyTrainersCanPost}
          disabled={!canUpdate}
        />
      </ControlSectionWrapper>

      <MembersTitleDivider>
        <StyledText fontSize={10} fontWeight="semibold">
          {t("members").toUpperCase()}
        </StyledText>
      </MembersTitleDivider>

      <MembersWrapper>
        {isEditChatMode && (
          <ChatMemberListItemWrapper
            onClick={onAddNewParticipantsClick}
            clickable={true}
          >
            <AddIconWrapper>
              <PlusInRoundIcon style={{ height: 25, width: 25 }} />
            </AddIconWrapper>
            <StyledText
              fontWeight="bold"
              fontSize={14}
              color={COLOR_TEXT_DEFAULT}
            >
              {t("addMembers")}
            </StyledText>
          </ChatMemberListItemWrapper>
        )}
        {usersInChat.map((user) => (
          <ChatMemberListItem
            chatter={user}
            isEditChatMode={isEditChatMode}
            onRemoveClick={onRemoveChatterFromChat}
          />
        ))}
      </MembersWrapper>
    </>
  );
}
