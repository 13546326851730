import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

import { useTheme } from "styled-components";

import { getSelectedGroupIdKey, UserPreferenceKeys } from "../../constants";
import {
  GetTheGroupsImInListQuery,
  Group,
  SubGroup,
  useGetGroupLazyQuery,
  useGetPreferenceLazyQuery,
  useGetTheGroupsImInListLazyQuery,
  usePutPreferenceMutation,
} from "../../graphql";
import { isRouteParamTrue } from "../../utils/route";
import { ALL_ACCOUNTS_ID, useAccessLevelsContext } from "../accessLevels";
import { useUserContext } from "../User";

import { useActions } from "./hooks";
import reducers, { initialState } from "./reducers";
import { ReportsContextState } from "./types";
import { useDateHandler } from "./useDateHandler";

export * from "./types";

export type ReportsContextType = [
  ReportsContextState & {
    loadingDates: boolean;
    datesQueryCalled: boolean;
    groupsImIn: GetTheGroupsImInListQuery | null;
    handleGroupChange: (newId: string) => Promise<void>;
  },
  ReturnType<typeof useActions>,
];

const ReportsContext = createContext<ReportsContextType | null>(null);

export const ReportsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isMobileInitialized, setIsMobileInitialized] = useState(false);

  const [searchParams] = useSearchParams();
  const selectedAthleteIdFromMobileApp = searchParams.get(
    "selectedAthleteIdFromMobileApp"
  );
  const selectedGroupsFromMobileApp = searchParams.get("selectedGroups");

  const [state, dispatch] = useReducer(reducers, initialState);
  const actions = useActions(dispatch);
  const { selectedAccount, setSelectedAccount } = useAccessLevelsContext();
  const { isMobile } = useTheme();
  const { sessionId, isTrainer, language, timezone } = useUserContext();

  const [getPreference] = useGetPreferenceLazyQuery();
  const [setPreferences] = usePutPreferenceMutation();
  const [getTheGroupsImIn, { data }] = useGetTheGroupsImInListLazyQuery();
  const [getGroup] = useGetGroupLazyQuery();
  const params = new URLSearchParams(window.location.search);
  const isMobileDedicated = isRouteParamTrue(params.get("isMobile"));

  const handleGroupChange = useCallback(
    async (newId: string) => {
      const res = await getGroup({
        variables: {
          id: newId,
          timezone,
          sessionId,
          language,
        },
      });
      if (res?.data?.getGroup) {
        actions.selectGroup(res.data.getGroup as any);

        if (isMobileDedicated || isMobile) {
          if (res.data.getGroup.owner?.id) {
            setSelectedAccount(res.data.getGroup.owner.id);
          }
          setIsMobileInitialized(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezone, sessionId, language, isMobile, isMobileDedicated]
  );

  // Handle date changes for both AthleteProfile and Reports
  const { loadingDates, datesQueryCalled } = useDateHandler({
    selectedEndDate: state.selectedEndDate,
    selectedStartDate: state.selectedStartDate,
    selectedPeriod: state.selectedPeriod,
    selectedSingleDay: state.selectedSingleDay,
    isDefaultState: state.isDefaultState,
    loadDateData: actions.loadDateData,
  });

  useEffect(() => {
    if (sessionId) {
      getPreference({
        variables: {
          key: UserPreferenceKeys.XPS_REPORTS_CONTEXT_STATE,
          sessionId,
        },
      }).then((result) => {
        if (result?.data?.getPreference) {
          actions.loadReports(JSON.parse(result.data.getPreference));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    if (
      sessionId &&
      (state.lastSelectedAccountForAllAccounts || state.selectedAthlete?.id)
    ) {
      const { selectedAthlete, lastSelectedAccountForAllAccounts } = state;

      setPreferences({
        variables: {
          key: UserPreferenceKeys.XPS_REPORTS_CONTEXT_STATE,
          value: JSON.stringify({
            lastSelectedAccountForAllAccounts,
            selectedAthlete,
          }),
          sessionId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sessionId,
    state.lastSelectedAccountForAllAccounts,
    state.selectedAthlete?.id,
  ]);

  const getAllGroupsAndSubGroups = useCallback(
    (groupsImIn: Group[], selectedGroupsFromMobileApp: string[]) => {
      return groupsImIn?.flatMap((group) =>
        !selectedGroupsFromMobileApp?.length ||
        selectedGroupsFromMobileApp.includes(group.id)
          ? [group, ...group.subGroups.map((subgroup) => subgroup)]
          : []
      );
    },
    []
  );

  const selectFirstTeamWithActivePlayer = useCallback(
    ({
      allSelectableGroupsOrSubgroups,
      athleteIdFromMobileApp,
    }: {
      allSelectableGroupsOrSubgroups: (Group | SubGroup)[];
      athleteIdFromMobileApp: string;
    }) => {
      return allSelectableGroupsOrSubgroups.find((group) =>
        group.athletes.some((athlete) => athlete.id === athleteIdFromMobileApp)
      );
    },
    []
  );

  useEffect(() => {
    (async () => {
      const isHandlingDueToMobileGroupChange = isMobile && isMobileInitialized;

      if (
        sessionId &&
        (selectedAccount?.id || !isTrainer) &&
        !isHandlingDueToMobileGroupChange
      ) {
        const [selectedGroupResponse, groupsResponse] = await Promise.all([
          getPreference({
            variables: {
              key: getSelectedGroupIdKey({
                accountId: selectedAccount?.id,
                isMobile,
                isTrainer,
              }),
              sessionId,
            },
            fetchPolicy: "network-only",
          }),
          getTheGroupsImIn({
            variables: {
              sessionId,
              language,
              requireEditability: false,
              mustBeAccessibleBy: selectedAthleteIdFromMobileApp || undefined,
              accountId:
                isMobile || !isTrainer ? undefined : selectedAccount.id,
            },
          }),
        ]);

        const selectedGroupId = selectedGroupResponse?.data?.getPreference;
        const allSelectableGroupsOrSubgroups = getAllGroupsAndSubGroups(
          groupsResponse.data?.getTheGroupsImIn as Group[],
          selectedGroupsFromMobileApp?.split(",")
        );

        let group = allSelectableGroupsOrSubgroups?.find(
          (group) => group.id === selectedGroupId
        );

        // For mobile app embedded version integration - parent login
        if (
          selectedAthleteIdFromMobileApp &&
          !group?.athletes?.find(
            (athlete) => athlete.id === selectedAthleteIdFromMobileApp
          )
        ) {
          group = selectFirstTeamWithActivePlayer({
            allSelectableGroupsOrSubgroups,
            athleteIdFromMobileApp: selectedAthleteIdFromMobileApp,
          });
        }

        const hasAccountChangedInWebApp =
          isTrainer &&
          group?.owner?.id !== selectedAccount.id &&
          selectedAccount?.id !== ALL_ACCOUNTS_ID &&
          !isMobile;

        if (
          (!group && allSelectableGroupsOrSubgroups.length > 0) ||
          hasAccountChangedInWebApp
        ) {
          group = allSelectableGroupsOrSubgroups[0];
        }

        if (group) {
          await handleGroupChange(group?.id);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, selectedAccount?.id, isMobileInitialized, handleGroupChange]);

  useEffect(() => {
    if (
      sessionId &&
      state.selectedGroup?.id &&
      (!isTrainer ||
        (selectedAccount?.id &&
          state.selectedGroup.owner?.id === selectedAccount?.id) ||
        selectedAccount?.id === ALL_ACCOUNTS_ID)
    ) {
      setPreferences({
        variables: {
          key: getSelectedGroupIdKey({
            accountId: selectedAccount?.id,
            isMobile,
            isTrainer,
          }),
          value: state.selectedGroup.id,
          sessionId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, state.selectedGroup?.id, selectedAccount?.id, isTrainer]);

  useEffect(() => {
    if (!sessionId) {
      actions.resetReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  return (
    <ReportsContext.Provider
      value={[
        {
          ...state,
          loadingDates,
          groupsImIn: data,
          handleGroupChange,
          datesQueryCalled,
        },
        actions,
      ]}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export const useReportsContext = () => useContext(ReportsContext);
