import { AccessToTrainer, GetGroupWithSubgroupsQuery } from "../graphql";

type CheckForEmptyChatProps = {
  group: GetGroupWithSubgroupsQuery;
  accessLevels: AccessToTrainer[];
  chatSettings: {
    autoAddFamily: boolean;
    autoAddAthletes: boolean;
    autoAddTrainers: boolean;
  };
};

export const checkForEmptyChat = ({
  group,
  accessLevels,
  chatSettings,
}: CheckForEmptyChatProps) => {
  const { autoAddAthletes, autoAddTrainers, autoAddFamily } = chatSettings;

  const athletes = autoAddAthletes
    ? group.getGroup.athleteAccess.filter((athlete) => athlete.active)
    : [];
  const trainers = autoAddTrainers
    ? group.getGroup.trainerAccess.filter((trainer) => trainer.active)
    : [];
  const family = autoAddFamily
    ? group.getGroup.familyAccess.filter((family) => family.active)
    : [];
  const users = [...athletes, ...trainers, ...family];

  const accounts = accessLevels.map((level) => level.user);

  return (
    users.filter((user) =>
      accounts.every((account) => account.id !== user.user.id)
    ).length === 0
  );
};
