import React, { useMemo, useState } from "react";

import dayjs from "dayjs";
import { useFormikContext } from "formik";
import _isNumber from "lodash/isNumber";
import styled from "styled-components";

import { useReportContext } from "../../contexts/report";
import { AggregateFunction } from "../../graphql";
import { getDummyValue } from "../../utils/dummyData";
import { isInformationCollection } from "../../utils/reports";
import { GroupTable } from "../ReportTableWidget/GroupTable";

import { COMPARISON_AGGREGATION_LABEL } from "./hooks/useGroupStatsData/helpers/constants";
import { shouldReturnAggregationName } from "./hooks/useGroupStatsData/helpers/headerDisplayChecker";
import { GroupTableStatsData } from "./hooks/useGroupStatsData/helpers/models";
import {
  ComparisonViewType,
  CreateGroupTableFormValue,
  GroupColumn,
} from "./models";

const GroupTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
`;

const generateDummyComparisonData = (
  id: string,
  isPercentageDisplay: boolean,
  value: number,
  unitName: string,
  attributeName: string
) => ({
  id,
  aggregation: COMPARISON_AGGREGATION_LABEL,
  value,
  unitName: isPercentageDisplay ? "%" : unitName,
  attributeName,
});

const getGrouppedColumnHeader = (columnHeaders: GroupColumn[]) => {
  const grouppedColumnHeaderMap = columnHeaders.reduce(
    (entryMap, header) =>
      entryMap.set(header.measurementTemplateId, [
        ...(entryMap.get(header.measurementTemplateId) || []),
        header,
      ]),
    new Map()
  );

  return Array.from(grouppedColumnHeaderMap.values()).map(
    (value: GroupColumn[]) => ({
      measurementTemplateId: value[0]?.measurementTemplateId,
      attributes: value.map((attributeValue) => ({
        ...attributeValue,
      })),
    })
  );
};

export const GroupTablePreview = () => {
  const [dummyValues, setDummyValue] = useState<Map<string, number | string>>(
    new Map<string, number | string>()
  );
  const { values } = useFormikContext<CreateGroupTableFormValue>();
  const { reportPreferences } = useReportContext();

  // Used to optimize count of rerenders when mounting component
  const columnIdentity = values.columns
    .map(
      (col) =>
        `${col.measurementTemplateId}-${col.measurementTemplateName}-${
          col.attributeTemplateId
        }-${col.aggregateFunctions.join(":")}`
    )
    .join(",");

  const exampleRows: GroupTableStatsData = useMemo(
    () =>
      ["John Doe", "Jane Doe"].map((athleteName) => ({
        athleteName,
        stats: getGrouppedColumnHeader(values.columns).map((column) => {
          const generateNewDummyValue = (
            agg: string,
            attr: GroupColumn,
            enforceNumbers = false
          ) => {
            const rowKey = `${athleteName}-${column.measurementTemplateId}-${attr.attributeTemplateId}-${attr.textOptions?.length}-${values.showValuesAsText}-${agg}`;
            const alreadyGeneratedDummyCells = dummyValues.get(rowKey);

            if (_isNumber(alreadyGeneratedDummyCells)) {
              return alreadyGeneratedDummyCells;
            }

            const value = getDummyValue(
              attr.attributeTemplateName,
              isInformationCollection(attr.collectionId),
              agg as AggregateFunction,
              attr.textOptions,
              values.showValuesAsText && !enforceNumbers,
              attr.measurementTemplateId
            );

            setDummyValue(new Map(dummyValues.set(rowKey, value)));

            return value;
          };

          const isAttributeNameVisible =
            column.attributes.length > 1 ||
            !(
              column.attributes[0].collectionId.includes("TEST") ||
              isInformationCollection(column.attributes[0].collectionId)
            );

          return {
            label: column.attributes[0]?.measurementTemplateName,
            attributes: column.attributes.map((attr) => ({
              trendValues: attr.showTrend
                ? new Array(7).fill(undefined).map((_, index) => {
                    const percentageOfMax = 0.13 * index;
                    const date = new Date();

                    return {
                      value: generateNewDummyValue(
                        `${AggregateFunction.Each}-${index}`,
                        attr
                      ) as number,
                      percentageOfMax,
                      date: new Date(date.setDate(date.getDate() - index)),
                      unitName: attr.attributeTemplateUnitName,
                      attributeName: isAttributeNameVisible
                        ? attr.attributeTemplateName
                        : "TREND",
                    };
                  })
                : [],
              values: [
                ...attr.aggregateFunctions.map((aggregation) => ({
                  id: `${column.measurementTemplateId}-${aggregation}`,
                  measurementTemplateId: attr.measurementTemplateId,
                  attributeTemplateId: attr.attributeTemplateId,
                  aggregation: shouldReturnAggregationName(
                    attr.attributeTemplateId,
                    aggregation
                  )
                    ? aggregation
                    : "",
                  txt:
                    values.showValuesAsText && attr.textOptions?.length
                      ? generateNewDummyValue(aggregation, attr)
                      : "",
                  value:
                    values.showValuesAsText && attr.textOptions?.length
                      ? Math.floor(Math.random() * attr.textOptions.length)
                      : generateNewDummyValue(aggregation, attr),
                  unitName: attr.attributeTemplateUnitName,
                  attributeName: isAttributeNameVisible
                    ? attr.attributeTemplateName?.split("(")[0]
                    : "",
                  isInfo: isInformationCollection(attr.collectionId),
                })),
                ...(attr.comparison?.show
                  ? [
                      {
                        measurementTemplateId: attr.measurementTemplateId,
                        attributeTemplateId: attr.attributeTemplateId,
                        ...generateDummyComparisonData(
                          `${column.measurementTemplateId}-comparison`,
                          attr.comparison.view ===
                            ComparisonViewType.PERCENTAGE,
                          generateNewDummyValue(
                            COMPARISON_AGGREGATION_LABEL,
                            attr,
                            true
                          ) as number,
                          attr.attributeTemplateUnitName,
                          isAttributeNameVisible
                            ? attr.attributeTemplateName?.split("(")[0]
                            : ""
                        ),
                      },
                    ]
                  : []),
              ],
            })),
          };
        }),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnIdentity, values.showValuesAsText]
  );

  if (!values.title && values.columns.length === 0) {
    return null;
  }

  return (
    <GroupTableWrapper>
      <GroupTable
        tableData={exampleRows}
        title={values.title}
        alternatingRow={values.alternatingValues}
        alternatingColumns={values.alternatingColumns}
        rowDividers={values.rowDividers}
        columnDividers={values.columnDividers}
        outsideBorderDividers={values.outsideBorderDividers}
        widgetBorders={values.widgetBorders}
        sortBy={values.sortBy}
        colorIndicator={values.rgbIndicator}
        sortingOrder={values.sortingOrder}
        sumRow={values.sumRow}
        averageRow={values.averageRow}
        includeZeroValues={values.includeZeroValues}
        includeEmptyValues={values.includeEmptyValues}
        isSingleDayReport={reportPreferences.isSingleDay}
        lastDayInRange={dayjs()}
        maxCount={values.maxCount}
        rules={values.rules}
        showValuesAsText={values.showValuesAsText}
      />
    </GroupTableWrapper>
  );
};
