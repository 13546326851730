import React from "react";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_DEFAULT,
  COLOR_TEXT_TABLE_LABEL,
} from "../colors";

import { BasicButton } from "./Button";
import Dropdown from "./Dropdown";
import { CalendarDottedIcon, ChevronRightIcon } from "./Icons";
import { ReactCalendar, ReactCalendarProps } from "./ReactCalendar";
import StyledText from "./StyledText";

const CalendarInputWrapper = styled.div<{ withLeftMargin?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: ${({ withLeftMargin }) => (withLeftMargin ? 15 : 0)}px;
`;

const CalendarTitle = styled(StyledText)`
  color: ${COLOR_MEDIUM_GRAY};
  font-size: 10px;
  margin-bottom: 7px;
  margin-top: 20px;
`;

const CalendarWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: 5px;
`;

const CalendarButton = styled(BasicButton)`
  background-color: ${COLOR_GRAY};
  border-radius: 6px;
  color: ${COLOR_TEXT_DARK};
  padding: 0 15px;
  height: 35px;
  display: block;

  &:active {
    opacity: 0.2;
  }
`;

const StyledDateText = styled(StyledText)`
  color: ${COLOR_TEXT_DEFAULT};
  font-size: 14px;
  line-height: 35px;
  font-weight: 600;
  min-width: 60px;
  margin-left: 23px;
`;

interface CalendarInputProps extends ReactCalendarProps {
  title: string;
  withLeftMargin?: boolean;
  showCalendarAboveInput?: boolean;
}

export function CalendarInput({
  title,
  withLeftMargin = false,
  showCalendarAboveInput = true,
  ...calendarProps
}: CalendarInputProps) {
  return (
    <CalendarInputWrapper withLeftMargin={withLeftMargin}>
      <CalendarTitle>{title}</CalendarTitle>
      <Dropdown
        dropdownContainerStyle={{
          top: showCalendarAboveInput && -295,
          right: -55,
        }}
        component={({ toggle }) => {
          return (
            <CalendarButton
              onClick={(event) => {
                event.preventDefault();
                toggle();
              }}
            >
              <CalendarWrapper>
                <CalendarDottedIcon tintColor={COLOR_TEXT_TABLE_LABEL} />
              </CalendarWrapper>
              <StyledDateText>
                {calendarProps.value.format("DD. MMMM YYYY")}
              </StyledDateText>
              <ChevronRightIcon
                tintColor={COLOR_TEXT_DARK}
                direction="bottom"
                style={{ marginLeft: 10 }}
              />
            </CalendarButton>
          );
        }}
      >
        <ReactCalendar {...calendarProps} />
      </Dropdown>
    </CalendarInputWrapper>
  );
}
