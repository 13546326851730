import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
} from "../../colors";
import { useReportsContext } from "../../contexts/Reports";
import { useAutoSelectAthlete } from "../../hooks/report/useAutoSelectAthlete";
import Avatar from "../Avatar";
import { BasicButton } from "../Button";
import StyledText from "../StyledText";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  background-color: ${COLOR_WHITE};
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid ${COLOR_LIGHT_GRAY};
`;

const AthleteItem = styled(BasicButton)<{ isSelected: boolean }>`
  flex-direction: row;
  min-height: 25px;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  background-color: ${({ isSelected }) =>
    isSelected ? COLOR_BLUE : "transparent"};

  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? COLOR_BLUE : COLOR_GRAY};
  }
`;

export function AthleteSelector() {
  const { sortedAthletes } = useAutoSelectAthlete();
  const [{ selectedAthlete }, actions] = useReportsContext();
  const { t } = useTranslation();

  if (sortedAthletes?.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <StyledText
        fontSize={14}
        fontWeight="bold"
        style={{
          padding: "15px",
          borderBottom: `1px solid ${COLOR_LIGHT_GRAY}`,
          backgroundColor: COLOR_WHITE,
        }}
      >
        {t("athletes")}
      </StyledText>
      <div
        style={{
          borderTopWidth: 0,
        }}
      >
        {sortedAthletes?.map((athlete) => {
          const isSelected = athlete.id === selectedAthlete?.id;

          return (
            <AthleteItem
              key={athlete.id}
              isSelected={isSelected}
              onClick={() => {
                actions.selectAthlete(athlete);
              }}
            >
              <Avatar
                size={25}
                userName={athlete.name}
                uri={athlete?.thumb ?? athlete?.img}
              />
              <StyledText
                fontSize={12}
                fontWeight="bold"
                style={{
                  marginLeft: "12px",
                  whiteSpace: "nowrap",
                  ...(isSelected ? { color: COLOR_WHITE } : {}),
                }}
              >
                {athlete.name}
              </StyledText>
            </AthleteItem>
          );
        })}
      </div>
    </Wrapper>
  );
}
