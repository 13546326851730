import React, { useMemo, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_SECONDARY_GREY_HOVER,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
  COLOR_WHITE,
} from "../../../colors";
import { useNotificationsContext } from "../../../contexts/notifications";
import { useTeamsContext } from "../../../contexts/teams";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import {
  GetTeamsDocument,
  GetTheGroupsImInDocument,
  SubGroup,
  useAddToTeamMutation,
  useDeleteGroupMutation,
  WhoCanPost,
  XpsUser,
} from "../../../graphql";
import { useCreateGroupChat } from "../../../hooks/useCreateGroupChat";
import { StyledCard } from "../../../StyledCard";
import { sortList } from "../../../utils/sortList";
import Dropdown from "../../Dropdown";
import { GroupWithAccessValue } from "../../GroupSelector";
import { HoverableItem } from "../../HoverableItem";
import { IconButton } from "../../IconButton";
import {
  MessageIcon,
  ChevronRightIcon,
  PlusIcon,
  ThreeDotsIcon,
} from "../../Icons";

import { ItemTypes } from "./model";
import { ShortNameTag } from "./ShortNameTag";
import { TheWho } from "./TeamGroups";
import { UserList } from "./UserList";

type CollapsibleGroupCardProps = {
  data?: GroupWithAccessValue;
  order: number;
  isSubGroup?: boolean;
  onAddToGroupPress: (who: TheWho) => void;
  onUserPress: (type: string, id: string, groupId: string) => void;
  parentGroup?: GroupWithAccessValue;
};

const Card = styled(StyledCard)<{
  isCollapsed: boolean;
  withBiggerBottomMargin: boolean;
}>`
  height: ${({ isCollapsed }) => `${isCollapsed ? "auto" : "45px"}`};
  margin-left: 10px;
  margin-bottom: ${({ withBiggerBottomMargin }) =>
    `${withBiggerBottomMargin ? "10px" : "2px"}`};
  border: 1px solid rgba(230, 232, 235, 0.35);
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
  border-radius: 6px;
`;

const CollapsibleWrapper = styled.div<{ isCollapsed: boolean }>`
  height: 45px;
  border-bottom-width: ${({ isCollapsed }) => `${isCollapsed ? 1 : 0}px`};
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 10px;
  z-index: 2;
  position: relative;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_TEXT_DARK};
  margin-left: 8px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const Count = styled.div`
  background-color: ${COLOR_SECONDARY_GREY_HOVER};
  align-items: center;
  border-radius: 15px;
  padding: 3px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  height: 21px;
  color: ${COLOR_WHITE};
  display: flex;
  justify-content: center;
`;

const ActionButton = styled.div`
  cursor: pointer;
  border-radius: 100px;
  height: 35px;
  width: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ChevronButton = styled.div`
  cursor: pointer;
  border-radius: 100px;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.div`
  background-color: ${COLOR_GREY_ACTIVE};
  width: 100%;
  height: 1px;
`;

export function CollapsibleGroupCard({
  data,
  order,
  isSubGroup,
  onAddToGroupPress,
  onUserPress,
  parentGroup,
}: CollapsibleGroupCardProps) {
  const closeDropdownRef = useRef<() => void>();
  const plusDropdownRef = useRef<() => void>();

  const { t } = useTranslation();
  const { sessionId, language, timezone } = useUserContext();
  const { onSetGroup, refetch } = useTeamsContext();

  const modal = useModalContext();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();

  const [deleteGroupMutation] = useDeleteGroupMutation();

  const [isCollapsed, setCollapsed] = useState(false);
  const [addMember] = useAddToTeamMutation();

  const { users } = useMemo(() => {
    const athletes = sortList<XpsUser & { name: string; active: boolean }>(
      (
        data?.athleteAccess?.map((athlete) => ({
          ...athlete.user,
          active: athlete.active,
        })) ?? []
      ).filter((athlete) => !isSubGroup || athlete?.active),
      language
    );
    const trainers = sortList<XpsUser & { name: string; active: boolean }>(
      (
        data?.trainerAccess?.map((trainer) => ({
          ...trainer.user,
          active: trainer.active,
          name: trainer.user?.name ?? "",
        })) ?? []
      ).filter((trainer) => !isSubGroup || trainer?.active),
      language
    );

    return {
      users: [...trainers, ...athletes],
    };
  }, [data?.athleteAccess, data?.trainerAccess, isSubGroup, language]);

  const usersCount = users?.filter((user) => user.active).length || 0;

  const { createGroupChatForTeam } = useCreateGroupChat();

  const onMessagePress = () =>
    modal.actions.openModal({
      modal: ModalType.CREATE_NEW_CHAT,
      title: t("newMessage"),
      params: {
        onRowPress: createGroupChatForTeam,
        disableCancel: true,
        createChatOverride: true,
        defaultGroupForInitGroupName: parentGroup ?? data,
        defaultChatState: {
          forAllAutoAddedFriendsOfChatter: null,
          autoAddFamily: true,
          autoAddAthletes: true,
          autoAddTrainers: true,
          announce: null,
          stage: 2,
          selectedGroupId: data.id,
          whoCanPost: WhoCanPost.All,
        },
      },
    });

  const onDeletePress = async () => {
    await deleteGroupMutation({
      variables: {
        id: data.id,
        sessionId,
        language,
        timezone,
      },
      refetchQueries: () => [GetTheGroupsImInDocument, GetTeamsDocument],
    });
    onSetGroup({ id: isSubGroup ? parentGroup?.id : data?.id });
  };

  const handleDeletePress = () => {
    closeDropdownRef?.current?.();

    modal.actions.openModal({
      modal: ModalType.CONFIRM,
      title: t("confirm"),
      params: {
        content: t("deleteConfirmQuestion", {
          x: t(isSubGroup ? "group" : "team").toLowerCase(),
        }),
        okButtonText: t("delete"),
        onOK: async () => {
          try {
            await onDeletePress();
            showSuccessNotification(
              t(isSubGroup ? "groupDeleteSuccess" : "teamDeleteSuccess")
            );
          } catch {
            showErrorNotification(t("groupDeleteError"));
          }
          modal.actions.closeModal();
        },
      },
    });
  };

  const handleSettingsPress = () => {
    closeDropdownRef?.current?.();
    modal.actions.openModal({
      modal: ModalType.SAVE_GROUP,
      title: t("settings"),
      params: {
        disableCancel: true,
        groupToEdit: data,
        parentGroup: isSubGroup ? parentGroup?.id : null,
        onSetGroup,
        refetch,
        isTeam: !isSubGroup,
      },
    });
  };

  const handleDrop = async (item: XpsUser) => {
    if (item?.id && item?.__typename === "XpsUser") {
      await addMember({
        variables: {
          sessionId,
          userID: item?.id,
          groupID: data?.id,
        },
      });
      onSetGroup(parentGroup);
    }
  };

  const [, drop] = useDrop({
    accept: [ItemTypes.ATHLETE, ItemTypes.COACH],
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const messageOptionShouldBeHidden = !data?.groupChat;

  return (
    <Card
      style={{ zIndex: order }}
      isCollapsed={isCollapsed}
      withBiggerBottomMargin={!isSubGroup}
      ref={drop}
    >
      <CollapsibleWrapper isCollapsed={isCollapsed}>
        <LabelWrapper>
          <ChevronButton onClick={() => setCollapsed(!isCollapsed)}>
            <ChevronRightIcon
              tintColor={COLOR_TEXT_TABLE_LABEL}
              direction={isCollapsed ? "bottom" : "right"}
            />
          </ChevronButton>
          <Label>{data?.name}</Label>
          {(data as SubGroup)?.shortName ? (
            <ShortNameTag withMargin>
              {(data as SubGroup).chosenShortName ||
                (data as SubGroup).shortName}
            </ShortNameTag>
          ) : null}
        </LabelWrapper>
        <RightSection>
          {Boolean(usersCount) && <Count>{usersCount}</Count>}
          {!messageOptionShouldBeHidden ? (
            <ActionButton onClick={onMessagePress} style={{ marginLeft: 20 }}>
              <MessageIcon />
            </ActionButton>
          ) : null}
          <Dropdown
            {...dropdownStyle}
            component={({ toggle }) => {
              plusDropdownRef.current = toggle;

              return (
                <ActionButton onClick={toggle}>
                  <PlusIcon />
                </ActionButton>
              );
            }}
          >
            <HoverableItem
              onClick={() => onAddToGroupPress("coach")}
              label={t("coaches")}
            />
            <HoverableItem
              onClick={() => onAddToGroupPress("athlete")}
              label={t("athletes")}
            />
          </Dropdown>
          <Dropdown
            {...dropdownStyle}
            component={({ toggle }) => {
              closeDropdownRef.current = toggle;

              return <IconButton icon={<ThreeDotsIcon />} onClick={toggle} />;
            }}
          >
            <HoverableItem
              label={t("settings")}
              onClick={handleSettingsPress}
            />
            <Divider />
            <HoverableItem label={t("delete")} onClick={handleDeletePress} />
          </Dropdown>
        </RightSection>
      </CollapsibleWrapper>
      {isCollapsed && (
        <div>
          <UserList
            emptyText={t("noMembers")}
            emptyTextAlign="left"
            inCollapsibleCard={true}
            data={users}
            group={data}
            grayOnHidden
            canRemove={!!isSubGroup}
            onUserPress={onUserPress}
            parentGroupId={parentGroup?.id}
          />
        </div>
      )}
    </Card>
  );
}

const dropdownStyle = {
  dropdownContainerStyle: {
    marginTop: 25,
  },
  dropdownContentStyle: {
    padding: "5px 0",
  },
};
