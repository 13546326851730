import React from "react";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../colors";
import { Group, XpsUser } from "../../graphql";
import Avatar from "../Avatar";
import { BasicButton } from "../Button";
import { AttendanceCrossIcon } from "../Icons/AttendanceCrossIcon";
import { RoundedGroupProfileIcon } from "../Icons/RoundedGroupProfileIcon";
import StyledText from "../StyledText";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: 20px;
  max-width: 45px;
`;

const CrossIconWrapper = styled(BasicButton)`
  width: 15px;
  height: 15px;
  position: absolute;
  right: -5px;
  border-radius: 50%;
  border: 1px solid ${COLOR_GREY_ACTIVE};
  cursor: pointer;
`;

interface SelectedPerformerCircleProps<T> {
  performer: T;
  onDeleteClick: (perfomer: T) => void;
}

export const SelectedPerformerCircle = <T extends Partial<Group> | XpsUser>({
  onDeleteClick,
  performer,
}: SelectedPerformerCircleProps<T>) => {
  return (
    <Wrapper key={`attending-${performer.id}`}>
      {performer.__typename === "Group" ? (
        <RoundedGroupProfileIcon />
      ) : (
        <Avatar
          size={45}
          source={{ uri: performer.thumb }}
          userName={performer.name}
        />
      )}
      <StyledText
        numberOfLines={2}
        fontSize={10}
        style={{ marginTop: 10, flex: 1, textAlign: "center" }}
      >
        {performer.name}
      </StyledText>
      <CrossIconWrapper onClick={() => onDeleteClick(performer)}>
        <AttendanceCrossIcon circleColor={COLOR_WHITE} />
      </CrossIconWrapper>
    </Wrapper>
  );
};
