import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_ALMOST_BLACK, COLOR_MEDIUM_GRAY } from "../../colors";
import StyledText from "../../components/StyledText";
import { usePrintGroupReportContext } from "../../contexts/Print/PrintGroupReportProvider";
import useImageSize from "../../hooks/useImageSize";
import {
  HeaderWrapper,
  LogoImage,
  PeriodTitle,
  ReportTitle,
  SecondaryTitle,
  TextWrapper,
} from "../styled";

interface PrintGroupReportHeaderProps {
  title: string;
  period: string;
  athletes: string[];
  groupName: string;
  accountLogo: string;
}

const AthletesTitle = styled(StyledText)`
  font-size: 10px;
  color: ${COLOR_MEDIUM_GRAY};
  padding: 4px 0;
  margin-top: 16px;
`;

const AthleteName = styled(StyledText)`
  font-size: 10px;
  font-weight: bold;
  color: ${COLOR_ALMOST_BLACK};
  width: 33%;
  padding: 4px;
`;

const AthletesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60%;
`;

export const PrintHeader = ({
  title,
  period,
  athletes,
  groupName,
  accountLogo,
}: PrintGroupReportHeaderProps) => {
  const { t } = useTranslation();
  const {
    mainTitle,
    periodFlag,
    teamNameFlag,
    logoFlag,
    athleteListFlag,
    setMainTitle,
  } = usePrintGroupReportContext();
  const imageSize = useImageSize(accountLogo);

  useEffect(() => {
    setMainTitle(title);
  }, [title, setMainTitle]);

  return (
    <HeaderWrapper>
      <TextWrapper>
        {mainTitle && <ReportTitle>{mainTitle}</ReportTitle>}
        {periodFlag && <PeriodTitle>{period}</PeriodTitle>}

        {teamNameFlag && <SecondaryTitle>{groupName}</SecondaryTitle>}

        {athleteListFlag && (
          <>
            <AthletesTitle>{t("athletes")}</AthletesTitle>
            <AthletesWrapper>
              {athletes.map((athlete) => (
                <AthleteName>{athlete}</AthleteName>
              ))}
            </AthletesWrapper>
          </>
        )}

        <SecondaryTitle>{t("overview")}</SecondaryTitle>
      </TextWrapper>

      {logoFlag && imageSize && (
        <LogoImage
          src={accountLogo}
          width={imageSize?.width}
          height={imageSize?.height}
        />
      )}
    </HeaderWrapper>
  );
};
