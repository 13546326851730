import { useMemo } from "react";

import { useAccessLevelsContext } from ".";

export const useCanEditSessions = (ownerId: string) => {
  const { accessLevels, selectedAccountSessionsAccess } =
    useAccessLevelsContext();

  const canEdit = useMemo(() => {
    const accessLevelForOwnerOfSelectedGroup = accessLevels?.find(
      (accessLevel) => accessLevel?.user?.id === ownerId
    );

    if (accessLevelForOwnerOfSelectedGroup) {
      return accessLevelForOwnerOfSelectedGroup?.teamSessions?.edit;
    } else {
      return selectedAccountSessionsAccess?.edit;
    }
  }, [accessLevels, selectedAccountSessionsAccess?.edit, ownerId]);

  return canEdit;
};
