import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import styled from "styled-components";

import { Agenda } from "../components/Agenda/Agenda";
import { AgendaAction } from "../components/Agenda/AgendaAction";
import { AthletesDropdownContent } from "../components/Agenda/components/AthletesDropdownContent";
import { TeamsDropdownContent } from "../components/Agenda/components/TeamsDropdownContent";
import { CalendarContainer } from "../components/CalenderContainer";
import { AthletesFilter } from "../components/Filters/AthletesFilter";
import { GroupsFilter } from "../components/Filters/GroupsFilter";
import { useToggleShowOptionalAthletes } from "../components/Filters/hooks/useToggleShowOptionalAthletes";
import { FocusedAthlete } from "../components/FocusedAthlete";
import { FocusedTeam } from "../components/FocusedTeam";
import { Header } from "../components/Header";
import ScreenContainer from "../components/ScreenContainer";
import { SideBar } from "../components/SideBar";
import { SubHeader } from "../components/SubHeader";
import { Tab } from "../components/Tabs";
import { useAgendaDataContext } from "../contexts/agenda/AgendaDataProvider";
import { useAgendaContext } from "../contexts/agenda/AgendaProvider";
import { useUserContext } from "../contexts/User";
import { useAgendaWithRegistration } from "../hooks/useAgendaWithRegistration";
import { getSearchParamsObject } from "../utils/getSearchParamsObject";
import { isRealTrainer } from "../utils/isRealTrainer";
import Storage from "../utils/storage";
import { StorageKeys } from "../utils/storageKeys";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../components/Calendar/customCalendar.css";

dayjs.extend(weekOfYear);

const AgendaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

const AgendaContent = styled.div`
  flex: 1;
  align-items: stretch;
  overflow: auto;
`;

const SideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: sticky;
  top: 10px;
  z-index: 99;
`;

export enum TabLabel {
  AGENDA = "agenda",
  ACTION_REQUIRED = "action_required",
  WEEK = "week",
  MONTH = "month",
}

function getAgendaTabs(
  t: TFunction,
  withActionRequired: boolean,
  needsAttention?: boolean
) {
  if (withActionRequired) {
    return [
      { id: TabLabel.AGENDA, title: t("agenda").toUpperCase() },
      {
        id: TabLabel.ACTION_REQUIRED,
        title: t("actionRequired").toUpperCase(),
        needsAttention,
      },
    ];
  } else {
    return [{ id: TabLabel.AGENDA, title: t("agenda").toUpperCase() }];
  }
}

function getCalendarTabs(t: TFunction) {
  return [
    { id: TabLabel.WEEK, title: t("week").toUpperCase() },
    { id: TabLabel.MONTH, title: t("month").toUpperCase() },
  ];
}

function getTabs(
  t: TFunction,
  withActionRequired: boolean,
  needAttention?: boolean
) {
  return [
    ...getAgendaTabs(t, withActionRequired, needAttention),
    ...getCalendarTabs(t),
  ];
}

export function AgendaScreen() {
  const { t } = useTranslation();
  const { agendaListNeedAttention, agendaListNotNeedAttention } =
    useAgendaWithRegistration();
  const user = useUserContext();

  const tabs = useMemo(
    () =>
      getTabs(
        t,
        (!!agendaListNeedAttention?.length ||
          !!agendaListNotNeedAttention?.length) &&
          !isRealTrainer({
            isFamilyMember: user.isFamilyMember,
            isTrainer: user.isTrainer,
          }),
        !!agendaListNeedAttention?.length
      ),
    [
      agendaListNeedAttention?.length,
      agendaListNotNeedAttention?.length,
      t,
      user.isFamilyMember,
      user.isTrainer,
    ]
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState<Tab>(tabs[0]);
  const { setCurrentWeek, focusedAgendaGroupId, selectedAthleteId } =
    useAgendaContext();
  const {
    refetchAgendaHandler,
    allAgendaData,
    agendaLoading,
    isRefetchingData,
    refetchAgendaOnScrollHandler,
    lastScrollPosition,
    calendarLoading,
    calendarData,
    setCalendarDateToCheck,
    calendarDateToCheck,
  } = useAgendaDataContext();

  const [selectedDate, setSelectedDate] = useState(
    Storage.getItem(StorageKeys.currentCalendarDate, window.sessionStorage) ??
      new Date()
  );
  const scrollRef = useRef<HTMLDivElement>(null);
  const agendaTabIdFromUrl = getSearchParamsObject(searchParams)?.activeTabId;
  const { showOptionalAthletes, toggleShowOptionalAthletes } =
    useToggleShowOptionalAthletes();

  const onNavigate = useCallback(
    (newDate: Date) => {
      setCalendarDateToCheck(newDate);

      if (tab.id === TabLabel.WEEK) {
        setCurrentWeek(dayjs(newDate).week());
      }
      Storage.setItem(
        StorageKeys.currentCalendarDate,
        newDate,
        window.sessionStorage
      );
      setSelectedDate(newDate);
    },
    [setCalendarDateToCheck, tab.id, setCurrentWeek]
  );

  function onAgendaScroll() {
    if (scrollRef?.current) {
      const leftScrollToBottom =
        scrollRef.current.scrollHeight -
        scrollRef.current.clientHeight -
        scrollRef.current.scrollTop;

      refetchAgendaOnScrollHandler(leftScrollToBottom);
    }
  }

  useEffect(() => {
    const instance = scrollRef.current;
    instance.addEventListener("scroll", onAgendaScroll, true);

    return () => {
      instance.removeEventListener("scroll", onAgendaScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const allTabs = getTabs(t, true, !!agendaListNeedAttention.length);

    if (agendaTabIdFromUrl) {
      setTab(allTabs.find((tab) => tab.id === agendaTabIdFromUrl) ?? tabs[0]);
    } else {
      const storedAgendaTabId = Storage.getItem(
        StorageKeys.agendaTab,
        window.sessionStorage
      );
      if (storedAgendaTabId) {
        setTab(allTabs.find((tab) => tab.id === storedAgendaTabId) ?? tabs[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaTabIdFromUrl, agendaListNeedAttention.length]);

  useEffect(() => {
    if (!calendarDateToCheck) {
      setCalendarDateToCheck(selectedDate);
    }
  }, [calendarDateToCheck, selectedDate, setCalendarDateToCheck]);

  const handleSetTab = (newTab) => {
    setTab(newTab);
    Storage.setItem(StorageKeys.agendaTab, newTab.id, window.sessionStorage);
    setSearchParams({
      ...getSearchParamsObject(searchParams),
      activeTabId: newTab.id,
    });
  };

  const scrollTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    document.addEventListener("agendaScrollTop", scrollTop);
    return () => document.removeEventListener("agendaScrollTop", scrollTop);
  }, []);

  useLayoutEffect(() => {
    if (allAgendaData && lastScrollPosition > 0 && scrollRef.current) {
      scrollRef.current.scrollTo({ top: lastScrollPosition });
    }
  }, [allAgendaData, lastScrollPosition]);

  const monthToWeek = () => {
    setTab(tabs.find((tab) => tab.id === TabLabel.WEEK));
  };

  return (
    <ScreenContainer>
      <Header />
      <SubHeader
        tab={tab}
        tabs={tabs}
        setTab={(newTab) => handleSetTab(newTab)}
        isButtonVisible={isRealTrainer(user)}
        focusedEntityComponent={
          selectedAthleteId ? <FocusedAthlete /> : <FocusedTeam />
        }
      />
      <AgendaWrapper>
        {isRealTrainer(user) && (
          <SideBarWrapper>
            <SideBar
              label={t("teams")}
              withoutTopMargin
              showDots
              dropdownContent={<TeamsDropdownContent />}
            >
              {(searchValue, isSelectAll) => (
                <GroupsFilter
                  value={focusedAgendaGroupId}
                  searchValue={searchValue}
                  isSelectAll={isSelectAll}
                />
              )}
            </SideBar>
            <SideBar
              label={t("athletes")}
              withoutTopMargin
              showDots
              customDropdownContainerStyle={{ maxWidth: 220 }}
              dropdownContent={
                <AthletesDropdownContent
                  showOptionalAthletes={showOptionalAthletes}
                  toggleShowOptionalAthletes={toggleShowOptionalAthletes}
                />
              }
            >
              {(searchValue, isSelectAll) => (
                <AthletesFilter
                  value={selectedAthleteId}
                  searchValue={searchValue}
                  isSelectAll={isSelectAll}
                  showOptionalAthletes={showOptionalAthletes}
                />
              )}
            </SideBar>
          </SideBarWrapper>
        )}

        <AgendaContent ref={scrollRef} id="agenda-wrapper">
          {tab.id === TabLabel.AGENDA && (
            <Agenda
              data={allAgendaData}
              loading={agendaLoading || isRefetchingData}
              refetch={(args) => refetchAgendaHandler(args)}
            />
          )}
          {tab.id === TabLabel.ACTION_REQUIRED && <AgendaAction />}
          {tab.id !== TabLabel.AGENDA &&
            tab.id !== TabLabel.ACTION_REQUIRED && (
              <CalendarContainer
                tab={tab}
                onNavigate={onNavigate}
                loading={calendarLoading}
                data={calendarData}
                defaultDate={selectedDate}
                goToWeekTab={monthToWeek}
              />
            )}
        </AgendaContent>
      </AgendaWrapper>
    </ScreenContainer>
  );
}
