import React, { useCallback } from "react";
import { useResizeDetector } from "react-resize-detector";

import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import styled, { useTheme } from "styled-components";
import useResizeObserver from "use-resize-observer";

import { IWidgetResizeProps, useBottomWidgetResize } from "../../../hooks";
import { CombinedTestWidgetModel } from "../../ReportCreateTable/models";
import { WidgetContainer, WidgetTitle, WidgetTitleContainer } from "../styled";
import { WidgetLoader } from "../WidgetLoader";
import { WidgetNoData } from "../WidgetNoData";

import { AthleteCombinedTestRadarChart } from "./components/AthleteCombinedTestRadarChart";
import { CombinedTestCircleChart } from "./components/CombinedTestCircleChart";
import { CombinedTestChartWrapper } from "./components/styled";
import { useCombinedTestData } from "./hooks/useCombinedTestData";
import { ChartType, CompareTo } from "./models";

HighchartsMore(Highcharts);

interface CombinedTestWidgetProps extends IWidgetResizeProps {
  widget: CombinedTestWidgetModel;
  isGroupReport: boolean;
  useDummyData?: boolean;
  readOnly?: boolean;
}

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const CombinedTestChartInnerContainerWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export function CombinedTestWidget({
  widget,
  isGroupReport,
  useDummyData,
  readOnly,
  gridItemId,
  layoutRef,
  setItemToResize,
  setItemsToHideInViewMode,
}: CombinedTestWidgetProps) {
  // Fix chart dimension on widget resize
  const { height, width, ref } = useResizeDetector();
  const { isMobile } = useTheme();

  const {
    ref: containerRef,
    height: widgetHeight,
    width: widgetWidth,
  } = useResizeObserver<HTMLDivElement>();

  useBottomWidgetResize({
    setItemToResize,
    setItemsToHideInViewMode,
    gridItemId,
    widgetHeight,
    id: widget.id,
    shouldResize: readOnly,
    alwaysResize: true,
    layoutRef,
  });

  const data = useCombinedTestData({ widget, isGroupReport, useDummyData });

  const CombinedTestChartInnerContainer = useCallback(
    ({ children }) => {
      if (isMobile) {
        return <MobileWrapper>{children}</MobileWrapper>;
      }
      return (
        <CombinedTestChartInnerContainerWrapper>
          {children}
        </CombinedTestChartInnerContainerWrapper>
      );
    },
    [isMobile]
  );

  if (data.isLoading) {
    return <WidgetLoader />;
  }

  const hasNoData =
    !data._theAthlete?._resultsByAthlete?.length ||
    data._theAthlete?._resultsByAthlete?.every(
      (athlete) => athlete._results.length === 0
    );

  return (
    <WidgetContainer
      showBorders={widget.widgetBorders}
      withoutTopPadding={widget.chartType !== ChartType.CIRCLE && !widget?.name}
      style={
        isMobile
          ? {
              overflow: "visible",
              height: hasNoData ? "300px" : "auto",
            }
          : {}
      }
    >
      {(widget.chartType === ChartType.CIRCLE || widget?.name) && (
        <WidgetTitleContainer>
          <WidgetTitle>{widget?.name}</WidgetTitle>
        </WidgetTitleContainer>
      )}

      {hasNoData ? (
        <WidgetNoData />
      ) : (
        <CombinedTestChartWrapper ref={containerRef as any} style={{ flex: 1 }}>
          <CombinedTestChartInnerContainer>
            {widget.chartType === ChartType.RADAR && (
              <div ref={ref} style={{ height: "100%", width: "100%" }}>
                <AthleteCombinedTestRadarChart
                  subTests={widget?.combinedTestTemplate?.subTests}
                  data={data}
                  height={height}
                  width={width}
                />
              </div>
            )}

            {widget.chartType === ChartType.CIRCLE && (
              <CombinedTestCircleChart
                widgetWidth={widgetWidth}
                widgetName={widget?.name}
                data={data}
                visibleSubTests={widget?.visibleSubTests ?? []}
                showDiffs={widget?.compareTo !== CompareTo.NOTHING}
              />
            )}
          </CombinedTestChartInnerContainer>
        </CombinedTestChartWrapper>
      )}
    </WidgetContainer>
  );
}
