import React from "react";
import { Draggable } from "react-beautiful-dnd";

import styled, { useTheme } from "styled-components";

import { ReportTemplate } from "../../../graphql";
import { Resource } from "../../Calendar/model";
import { IconButton } from "../../IconButton";
import { RemoveIcon, ReorderIcon } from "../../Icons";
import { ReorderImageWrapper } from "../../ReportCreateTable/styled";
import { EditAthleteProfileTabsOptionsWithDropdown } from "../EditAthleteProfileTabs/Dropdown";

import { HoverableWrapper, HoverableItem, Name } from "./styled";

type DraggableItemProps = {
  resource?: Resource;
  report?: any;
  index: number;
  onRemove: (item: Resource | ReportTemplate) => void;
};

const ActionIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export function DraggableItem({
  resource,
  report,
  index,
  onRemove,
}: DraggableItemProps) {
  const draggableId = resource?.resourceId || report?.id;
  const name = resource?.resourceTitle || report?.name;
  const item = resource || report;
  const { isMobile } = useTheme();

  return (
    <Draggable index={index} draggableId={draggableId}>
      {(provided) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <HoverableWrapper index={index}>
            <HoverableItem>
              <ReorderImageWrapper mr={10} {...provided.dragHandleProps}>
                <ReorderIcon />
              </ReorderImageWrapper>
              <Name width={isMobile ? "50vw" : "100%"}>{name}</Name>
              <ActionIconsWrapper>
                {report && (
                  <EditAthleteProfileTabsOptionsWithDropdown
                    // @ts-ignore
                    report={report}
                    isAddedToProfile
                  />
                )}
                <IconButton
                  icon={<RemoveIcon />}
                  onClick={() => onRemove(item)}
                />
              </ActionIconsWrapper>
            </HoverableItem>
          </HoverableWrapper>
        </div>
      )}
    </Draggable>
  );
}
