import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";

import styled, { css } from "styled-components";

import {
  COLOR_BUTTON_BLUE,
  COLOR_BUTTON_BLUE_ACTIVE,
  COLOR_BUTTON_BLUE_HOVER,
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../colors";
import { ModalContext, ModalType } from "../../contexts/UI/Modal";
import { UserContext } from "../../contexts/User";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { BasicButton } from "../Button";
import Dropdown from "../Dropdown";
import Hoverable from "../Hoverable";
import { IconButton } from "../IconButton";
import {
  CrossIcon,
  AddFileIcon,
  AddImageIcon,
  CameraIcon,
  PlusInRoundIcon,
  NewDocumentIcon,
  ArrowBoldIcon,
} from "../Icons";
import { RoundIcon } from "../RoundIcon";
import StyledText from "../StyledText";

import { restrictNewLines } from "./utils";

type Props = {
  onSendMessage: (props: {
    message?: string;
    files?: any[];
    attachments?: any[];
  }) => Promise<void>;
  uploading?: boolean;
  sendingMessage?: boolean;
  contact?: any;
  minRows?: number;
  maxRows?: number;
};

const CancelButton = styled.button`
  padding: 0;
  border: none;
  background: ${COLOR_WHITE};
  cursor: pointer;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 12px;
`;

const AttachmentsWrapper = styled.div`
  display: flex;
  background-color: ${COLOR_GRAY};
  padding: 10px 30px;
  flex-direction: column;
`;

const PreviewsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
`;

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
`;

const Preview = styled.div`
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  background-color: ${COLOR_GREY_ACTIVE};
  display: flex;
`;

const DocumentIconPlaceholder = styled.div`
  height: 37px;
  width: 1px;
`;

const AttachmentButton = styled(BasicButton)`
  align-items: center;
  justify-content: center;
  margin: 0 10px;
`;

const CollectionButton = styled(BasicButton)`
  display: block;
`;

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 35px;
  flex: 1;
  padding: 5px;
  background-color: #f5f6f8;
  border-radius: 25px;
  margin-right: 5px;
  align-items: center;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 15px 10px 10px;
  background-color: ${COLOR_WHITE};
  align-items: center;
`;

export const ChatMessageInput = ({
  onSendMessage,
  uploading,
  sendingMessage,
  contact,
  minRows = 1,
  maxRows = 6,
}: Props) => {
  const { t } = useTranslation();
  const modal = useContext(ModalContext);
  const user = useContext(UserContext);

  const enforceToggleCallback = useRef<() => void>();

  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const textInput = useRef(null);
  const [attachments, setAttachments] = useState([]);
  const [previews, setPreviews] = useState([]);

  const canPost = contact.onlyTrainersCanPost ? user.isTrainer : true;

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(restrictNewLines(e.target.value));
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const binaryStr = reader.result;
          //@ts-ignore
          const base64 = btoa(
            new Uint8Array(binaryStr as ArrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          if (base64) {
            const url = `data:${file.type};base64,${base64}`;
            setFiles([
              ...files,
              {
                localName: file.name.replace(/\s/g, "_"),
                mime: file.type,
                content: base64,
                url,
              },
            ]);
          }
        };
        reader.readAsArrayBuffer(file);
      });

      if (
        enforceToggleCallback?.current &&
        typeof enforceToggleCallback.current === "function"
      ) {
        enforceToggleCallback.current();
      }
    },
    [files]
  );

  const { getRootProps: getRootPropsVideo, getInputProps: getInputPropsVideo } =
    useDropzone({
      onDrop,
    });

  const { getRootProps: getRootPropsPhoto, getInputProps: getInputPropsPhoto } =
    useDropzone({
      onDrop,
    });

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onSubmit = async () => {
    if (!!message?.trim() || files?.length || attachments?.length) {
      await onSendMessage({ message, files, attachments });

      setFiles([]);
      setAttachments([]);
      setMessage("");
    }
  };

  useEffect(() => {
    textInput.current.focus();
  }, []);

  useEffect(
    () => setPreviews([...files, ...attachments]),
    [files, attachments]
  );

  const handleKeyDown = (e) => {
    if (message && e.key === "Enter" && !e.shiftKey) {
      e.stopPropagation();
      e.preventDefault();
      onSubmit();
    }
  };

  const sendingDisabled = sendingMessage || uploading || !canPost;

  return (
    <div style={{ marginTop: 10 }}>
      {!!previews.length && (
        <AttachmentsWrapper>
          <StyledText fontSize={10} style={{ marginBottom: "10px" }}>
            {t("attachmentsToSend")}
          </StyledText>
          <PreviewsWrapper>
            {previews.map((file, index) => {
              const name = file.localName || file.name;

              return (
                <PreviewWrapper key={name}>
                  <Preview>
                    {file.type ? (
                      <NewDocumentIcon
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      />
                    ) : (
                      <DocumentIconPlaceholder />
                    )}
                    <StyledText
                      fontSize={12}
                      fontWeight="bold"
                      color={COLOR_TEXT_DARK}
                    >
                      {name?.length > 25 ? `${name.substring(0, 24)}...` : name}
                    </StyledText>
                    <CancelButton
                      onClick={() => {
                        if (file.localName) {
                          files.splice(index, 1);
                          setFiles([...files]);
                        } else {
                          attachments.splice(index, 1);
                          setAttachments([...attachments]);
                        }
                      }}
                    >
                      <CrossIcon
                        tintColor={COLOR_MEDIUM_GRAY}
                        style={{ width: 15, height: 15 }}
                      />
                    </CancelButton>
                  </Preview>
                </PreviewWrapper>
              );
            })}
          </PreviewsWrapper>
        </AttachmentsWrapper>
      )}
      <BottomWrapper>
        {canPost && (
          <div
            style={{
              position: "relative",
              zIndex: 10,
            }}
          >
            <Dropdown
              dropdownContentStyle={{
                top: 10,
                right: 10,
                padding: "5px 0",
              }}
              dropdownContainerStyle={{
                left: 5,
                top: "auto",
                bottom: 35,
                minWidth: 170,
              }}
              component={({ toggle, show }) => {
                enforceToggleCallback.current = toggle;

                return (
                  <AttachmentButton onClick={toggle}>
                    <PlusInRoundIcon isActive={show} />
                  </AttachmentButton>
                );
              }}
            >
              <Hoverable>
                {(hovered) => (
                  <AttachmentDropdownItem
                    hovered={hovered}
                    {...getRootPropsPhoto()}
                  >
                    <RoundIcon svgIcon={<AddImageIcon />} isHovered={hovered} />
                    <input
                      disabled={!canPost}
                      {...getInputPropsPhoto()}
                      accept="image/*"
                      multiple={false}
                    />
                    <DropdownItemText>{t("attachImage")}</DropdownItemText>
                  </AttachmentDropdownItem>
                )}
              </Hoverable>
              <Hoverable>
                {(hovered) => (
                  <AttachmentDropdownItem
                    hovered={hovered}
                    {...getRootPropsVideo()}
                  >
                    <RoundIcon svgIcon={<CameraIcon />} isHovered={hovered} />
                    <input
                      disabled={!canPost}
                      {...getInputPropsVideo()}
                      accept="video/*"
                      multiple={false}
                    />
                    <DropdownItemText>{t("attachVideo")}</DropdownItemText>
                  </AttachmentDropdownItem>
                )}
              </Hoverable>
              <Hoverable>
                {(hovered) => (
                  <AttachmentDropdownItem hovered={hovered} {...getRootProps()}>
                    <RoundIcon svgIcon={<AddFileIcon />} isHovered={hovered} />
                    <input
                      disabled={!canPost}
                      {...getInputProps()}
                      multiple={false}
                    />
                    <DropdownItemText>{t("attachFile")}</DropdownItemText>
                  </AttachmentDropdownItem>
                )}
              </Hoverable>
              {isRealTrainer(user) && (
                <CollectionButton
                  onClick={() => {
                    modal.actions.openModal({
                      modal: ModalType.COLLECTIONS,
                      title: t("collections"),
                      params: {
                        onClick: (node) => {
                          setAttachments([...attachments, node]);
                          modal.actions.closeModal();
                        },
                        isFromMessaging: true,
                      },
                    });
                  }}
                >
                  {/* Empty div is needed otherwise button + hoverable does not work */}
                  <div>
                    <Hoverable>
                      {(hovered) => (
                        <AttachmentDropdownItem hovered={hovered}>
                          <RoundIcon
                            isHovered={hovered}
                            svgIcon={<NewDocumentIcon />}
                          />
                          <DropdownItemText>
                            {t("attachTraining")}
                          </DropdownItemText>
                        </AttachmentDropdownItem>
                      )}
                    </Hoverable>
                  </div>
                </CollectionButton>
              )}
            </Dropdown>
          </div>
        )}
        <TextAreaWrapper>
          <TextareaAutosize
            disabled={sendingDisabled}
            ref={textInput}
            placeholder={
              contact.onlyTrainersCanPost
                ? canPost
                  ? t("typeMessage")
                  : t("onlyTrainersCanPost")
                : t("typeMessage")
            }
            minRows={minRows}
            maxRows={maxRows}
            style={{
              fontSize: 14,
              width: "100%",
              marginTop: 6,
              marginBottom: 6,
              marginLeft: 15,
              marginRight: 5,
              outline: 0,
              borderWidth: 0,
              backgroundColor: "transparent",
              resize: "none",
              fontFamily: "Inter",
            }}
            value={message}
            onKeyDown={handleKeyDown}
            onChange={handleInput}
          />

          <IconButton
            disabled={
              (!message?.trim() && !files.length && !attachments.length) ||
              sendingDisabled
            }
            icon={<ArrowBoldIcon tintColor={COLOR_WHITE} />}
            isSpinnerVisible={sendingMessage || uploading}
            bgColor={COLOR_BUTTON_BLUE}
            hoverBgColor={COLOR_BUTTON_BLUE_HOVER}
            activeBgColor={COLOR_BUTTON_BLUE_ACTIVE}
            onClick={onSubmit}
          />
        </TextAreaWrapper>
      </BottomWrapper>
    </div>
  );
};

const AttachmentDropdownItem = styled.div<{ hovered?: boolean }>(
  ({ hovered }) => {
    return css`
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: ${hovered ? COLOR_GRAY : COLOR_WHITE};
      cursor: pointer;
      padding: 5px 10px;
    `;
  }
);

const DropdownItemText = styled.div(() => {
  return css`
    font-size: 12px;
    font-weight: bold;
    color: ${COLOR_TEXT_DARK};
  `;
});
