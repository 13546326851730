import { useCallback } from "react";

import { PracticeLine } from "../../components/Modals/CreateDrill/model";
import { useUserContext } from "../../contexts/User";
import {
  useUpdateDrillMutation,
  useUpdateInlinePracticeCommentMutation,
} from "../../graphql";

export function useUpdateDrillOrComment() {
  const { sessionId, timezone, language } = useUserContext();

  const [updateDrillMutation] = useUpdateDrillMutation();
  const [updateInlinePracticeCommentMutation] =
    useUpdateInlinePracticeCommentMutation();

  const updateDrillOrComment = useCallback(
    async ({
      practiceLines,
      practiceID,
      subPracticeID,
      originalPracticeLines,
    }: {
      practiceLines: (PracticeLine & { index?: number | null })[];
      practiceID?: string;
      subPracticeID?: string;
      originalPracticeLines: PracticeLine[];
    }) => {
      return Promise.all(
        practiceLines.map(async (practiceLine, index) => {
          const finalIndex: null | number =
            practiceLine.index === null ||
            typeof practiceLine.index === "number"
              ? practiceLine.index
              : index;

          if (
            practiceLine.__typename === "Document" ||
            practiceLine.__typename === "Drill"
          ) {
            const drillTemplateID =
              practiceLine.__typename === "Drill"
                ? null
                : practiceLine.id.split(":").pop();

            const drillID =
              practiceLine.__typename === "Drill" ? practiceLine.id : null;

            const durationForDrillIsChanged =
              practiceLine.__typename === "Drill" &&
              practiceLine?.localDuration;

            const shouldUpdate =
              practiceLine.__typename === "Document" ||
              durationForDrillIsChanged ||
              practiceLine.hasBeenMoved;

            if ((drillTemplateID || drillID) && shouldUpdate)
              await updateDrillMutation({
                variables: {
                  sessionId,
                  language,
                  timezone,
                  input: {
                    practiceID,
                    drillID,
                    idx: practiceLine.hasBeenMoved
                      ? index
                      : finalIndex === null
                        ? finalIndex
                        : finalIndex + 1,
                    drillTemplateID,
                    subPracticeID,
                    minutes:
                      practiceLine?.localDuration ??
                      practiceLine?.minutes ??
                      null,
                  },
                },
              });
          }

          // Add comments
          if (
            practiceLine.__typename === "PracticeInlineComment" ||
            (practiceLine.__typename === "InlineComment" && practiceLine.txt)
          ) {
            await updateInlinePracticeCommentMutation({
              variables: {
                sessionId,
                input: {
                  id: practiceLine.id,
                  practiceID,
                  index: finalIndex,
                  text: practiceLine.txt,
                  subPracticeID,
                },
              },
            });
          }
        })
      );
    },
    [
      sessionId,
      language,
      timezone,
      updateInlinePracticeCommentMutation,
      updateDrillMutation,
    ]
  );

  return { updateDrillOrComment };
}
