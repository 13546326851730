import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE } from "../colors";
import { useAgendaContext } from "../contexts/agenda/AgendaProvider";
import { useUserContext } from "../contexts/User";
import { isRealTrainer } from "../utils/isRealTrainer";

import { HoverableItem } from "./HoverableItem";

const Divider = styled.div`
  background-color: ${COLOR_GREY_ACTIVE};
  width: 100%;
  height: 1px;
`;

const hoverableButtonStyle = { width: "100%" };

interface CreateSessionDropdownContentProps {
  selectedStartDate?: Date;
}

export const CreateSessionDropdownContent = ({
  selectedStartDate,
}: CreateSessionDropdownContentProps) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const {
    handleCreateWorkoutPress,
    handleCreateEventPress,
    handleCreateGamePress,
    handleCreatePracticePress,
    groupsAvailableForSession,
    selectedGroup,
    selectedAthleteId,
  } = useAgendaContext();
  const isUserRealTrainer = isRealTrainer(user);

  const [searchParams] = useSearchParams();
  const athleteId = searchParams.get("athleteId") ?? "";

  const focusedAthleteId = selectedAthleteId || athleteId;

  const showMulOptions =
    isUserRealTrainer &&
    ((groupsAvailableForSession?.length > 0 &&
      groupsAvailableForSession?.length < 4) ||
      selectedGroup?.id);

  const singleGroupForSessionCreation =
    selectedGroup ||
    (groupsAvailableForSession?.length === 1
      ? groupsAvailableForSession[0]
      : null);

  const currentOptions = selectedGroup?.id
    ? [selectedGroup]
    : groupsAvailableForSession;

  return (
    <>
      {showMulOptions ? (
        <>
          {currentOptions.map((groupOption) => (
            <HoverableItem
              key={groupOption.id}
              label={`${t("practice")}: ${groupOption.name}`}
              onClick={() => handleCreatePracticePress(groupOption)}
              buttonStyle={hoverableButtonStyle}
            />
          ))}
          <Divider />
        </>
      ) : (
        <HoverableItem
          label={t("practice")}
          onClick={() =>
            handleCreatePracticePress(
              singleGroupForSessionCreation,
              selectedStartDate
            )
          }
          buttonStyle={hoverableButtonStyle}
        />
      )}
      {showMulOptions ? (
        <>
          {currentOptions.map((groupOption) => (
            <HoverableItem
              key={groupOption.id}
              label={`${t("game")}: ${groupOption.name}`}
              onClick={() =>
                handleCreateGamePress(groupOption, selectedStartDate)
              }
              buttonStyle={hoverableButtonStyle}
            />
          ))}
          <Divider />
        </>
      ) : (
        <HoverableItem
          label={t("game")}
          onClick={() =>
            handleCreateGamePress(
              singleGroupForSessionCreation,
              selectedStartDate
            )
          }
          buttonStyle={hoverableButtonStyle}
        />
      )}
      {showMulOptions ? (
        <>
          {currentOptions.map((groupOption) => (
            <HoverableItem
              key={groupOption.id}
              label={`${t("event")}: ${groupOption.name}`}
              onClick={() =>
                handleCreateEventPress(groupOption, selectedStartDate)
              }
              buttonStyle={hoverableButtonStyle}
            />
          ))}
        </>
      ) : (
        <HoverableItem
          label={t("event")}
          onClick={() =>
            handleCreateEventPress(
              singleGroupForSessionCreation,
              selectedStartDate
            )
          }
          buttonStyle={hoverableButtonStyle}
        />
      )}
      {focusedAthleteId ? (
        <HoverableItem
          label={t("workout")}
          onClick={() =>
            handleCreateWorkoutPress(focusedAthleteId, selectedStartDate)
          }
          buttonStyle={hoverableButtonStyle}
        />
      ) : null}
    </>
  );
};
