import React, { useState, useMemo } from "react";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_SECONDARY_GREY, COLOR_WHITE } from "../../../colors";
import { usePracticeContext } from "../../../contexts/practice/PracticeProvider";
import { FolderTreeDocumentsQuery } from "../../../graphql";
import { handleSingleDoubleClick } from "../../../utils/handleSingleDoubleClick";
import { getFolderColors } from "../../Collections/utils";
import { normalizeString } from "../../Filters/utils";
import { ChevronRightIcon, FolderIcon } from "../../Icons";
import { rgbToHex } from "../utils";

import { FileType } from "./FileItem";
import { FileList } from "./FileList";
import { SubfolderItem } from "./SubfolderItem";

type FolderItemProps = {
  folder: FolderTreeDocumentsQuery["user"]["collection"]["tree"]["children"][0];
  documents: FolderTreeDocumentsQuery["user"]["collection"]["tree"]["documents"]["edges"];
  collectionId: string;
  isWorkout?: boolean;
  allOpen: boolean;
  isLoading?: boolean;
  searchValue?: string;
  rootFolder?: boolean;
  onFolderDocumentsChange?: (res: FolderTreeDocumentsQuery) => void;
  selectedWorkoutFolder?: string | null;
  setSelectedWorkoutFolder?: (folderId: string | null) => void;
  isTag?: boolean;
  withExtraPaddingForFiles?: boolean;
  selectedFile?: FileType;
  onFileSingleClick?: (file: FileType) => void;
  onFileDoubleClick?: (file: FileType) => void;
  controlledSelectedFolder?: string | null;
  controlledSetSelectedFolder?: (folderId: string) => void;
  controlledToggledFolderObject?: Record<string, boolean>;
  controlledSetToggledFolder?: (
    folderId: string
  ) => (newToggle: boolean) => void;
  onFileDragging?: (isDragging: boolean) => void;
};

const Folder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FolderIconWrapper = styled.div<{ hasWorkoutSubfolders?: boolean }>`
  padding: 0 5px;
  margin-left: ${({ hasWorkoutSubfolders }) =>
    hasWorkoutSubfolders ? 7 : 0}px;
  align-items: center;
  display: flex;
`;

const FolderName = styled.div<{ isSelected?: boolean; color: string }>`
  padding: 5px;
  background-color: ${({ isSelected }) =>
    isSelected ? COLOR_BLUE : "transparent"};
  color: ${({ isSelected, color }) => (isSelected ? COLOR_WHITE : color)};
  text-overflow: ${({ isSelected }) => (isSelected ? "hidden" : "ellipsis")};
  border-radius: ${({ isSelected }) => (isSelected ? "3px" : "0px")};
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
`;

const ClickableFolderContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

function findFolderById(
  folders: FolderTreeDocumentsQuery["user"]["collection"]["tree"]["children"],
  id: string
) {
  let flattenFolders = [...folders];
  for (const folder of folders) {
    if (folder.children) {
      const result = findFolderById(folder.children, id);
      if (result) {
        flattenFolders = [...flattenFolders, ...result];
      }
    }
  }
  return flattenFolders;
}

export function FolderItem({
  folder,
  collectionId,
  isWorkout,
  isLoading,
  allOpen,
  searchValue,
  documents,
  onFolderDocumentsChange,
  selectedWorkoutFolder,
  setSelectedWorkoutFolder,
  isTag,
  withExtraPaddingForFiles,
  selectedFile,
  onFileSingleClick,
  onFileDoubleClick,
  controlledToggledFolderObject,
  controlledSetToggledFolder,
  controlledSelectedFolder,
  controlledSetSelectedFolder,
  onFileDragging,
}: FolderItemProps) {
  const fillColor = folder?.color
    ? folder.color.includes(",")
      ? rgbToHex(folder.color)
      : folder.color
    : COLOR_BLUE;

  const folderColor = useMemo(
    () => getFolderColors(collectionId),
    [collectionId]
  );

  const {
    selectedFolder: uncontrolledSelectedFolder,
    setSelectedFolder: uncontrolledSetSelectedFolder,
  } = usePracticeContext();
  const selectedFolder = controlledSelectedFolder ?? uncontrolledSelectedFolder;
  const setSelectedFolder =
    controlledSetSelectedFolder ?? uncontrolledSetSelectedFolder;

  const [uncontrolledToggledFolder, uncontrolledSetToggledFolder] = useState<
    boolean | null
  >(null);
  const toggledFolder =
    controlledToggledFolderObject?.[folder.id] ?? uncontrolledToggledFolder;
  const setToggledFolder =
    controlledSetToggledFolder?.(folder.id) ?? uncontrolledSetToggledFolder;

  const toggleFolderSelection = async (e) => {
    // because of outside click blur
    e.stopPropagation();

    const splitId = folder.id.split(":");
    const id = splitId[splitId.length - 1];
    if (!isWorkout) {
      return setSelectedFolder(selectedFolder === folder.id ? "" : folder.id);
    }
    if (folder.hasSubfolders) {
      setSelectedWorkoutFolder?.(id);
      setSelectedFolder(folder.id);
    } else if (!folder.hasSubfolders) {
      setSelectedWorkoutFolder?.(id);
      setSelectedFolder(folder.id);
    }
  };

  const isSelected = useMemo(() => {
    if (isTag) {
      return selectedWorkoutFolder && selectedWorkoutFolder === folder.id;
    }
    if (isWorkout) {
      const splitId = folder.id.split(":");
      const id = splitId[splitId.length - 1];
      return selectedWorkoutFolder === id;
    }
    if (selectedFolder) {
      return selectedFolder === folder.id;
    }
  }, [selectedFolder, folder?.id, isTag, selectedWorkoutFolder, isWorkout]);

  const { files, noDeepFiles, doNotFilterFiles } = useMemo(() => {
    const flattenFolders = findFolderById(folder?.children ?? [], folder.id);

    if (documents) {
      const shortFolderId = folder.id.split(":");
      const files = documents.filter(
        (d) => d.node.folderGuid === shortFolderId[shortFolderId.length - 1]
      );
      return {
        files,
        noDeepFiles: searchValue
          ? !files.some((f) =>
              normalizeString(f.node.name ?? "").includes(searchValue)
            ) &&
            !(folder?.children ?? [])?.some((f) =>
              normalizeString(f.name).includes(normalizeString(searchValue))
            ) &&
            !flattenFolders.some((fol) => {
              const shortFolderId = fol.id.split(":");
              return (
                normalizeString(fol.name).includes(
                  normalizeString(searchValue)
                ) ||
                documents.some((file) => {
                  return (
                    file.node.folderGuid ===
                      shortFolderId[shortFolderId.length - 1] &&
                    normalizeString(file.node.name ?? "").includes(
                      normalizeString(searchValue)
                    )
                  );
                })
              );
            })
          : false,
        doNotFilterFiles: normalizeString(folder.name).includes(
          normalizeString(searchValue)
        ),
      };
    }
    return { files: [], noDeepFiles: true, doNotFilterFiles: false };
  }, [documents, searchValue, folder]);

  const onChevronClick = (e) => {
    e.stopPropagation();
    setToggledFolder(!toggledFolder);
  };

  const shouldDisplayFolderIndicator =
    !folder.hasSubfolders && !documents.length && isWorkout;

  if (noDeepFiles && !doNotFilterFiles && !isTag) {
    return null;
  }

  return (
    <>
      <Folder title={folder.name}>
        {!isTag && !shouldDisplayFolderIndicator ? (
          <ChevronRightIcon
            tintColor={COLOR_SECONDARY_GREY}
            onClick={onChevronClick}
            style={{ width: 8, height: 8, cursor: "pointer" }}
            direction={
              toggledFolder || (allOpen && toggledFolder === null)
                ? "bottom"
                : null
            }
          />
        ) : null}
        <ClickableFolderContainer
          onClick={(e) =>
            handleSingleDoubleClick(
              () => toggleFolderSelection(e),
              () => onChevronClick(e)
            )
          }
        >
          <FolderIconWrapper
            onClick={(e) => e.stopPropagation()}
            hasWorkoutSubfolders={shouldDisplayFolderIndicator}
          >
            <FolderIcon
              color={folderColor?.color}
              border={folderColor?.border}
            />
          </FolderIconWrapper>
          <FolderName isSelected={isSelected} color={fillColor}>
            {folder.name}
          </FolderName>
        </ClickableFolderContainer>
      </Folder>
      {!isTag &&
      (toggledFolder || (allOpen && toggledFolder === null)) &&
      files.length ? (
        <FileList
          loading={isLoading}
          data={files}
          collectionId={collectionId}
          searchValue={searchValue}
          doNotFilter={doNotFilterFiles}
          withExtraPaddingForFiles={toggledFolder || withExtraPaddingForFiles}
          selectedFile={selectedFile}
          onFileSingleClick={onFileSingleClick}
          onFileDoubleClick={onFileDoubleClick}
          onFileDragging={onFileDragging}
        />
      ) : null}
      {!isTag && (toggledFolder || (allOpen && toggledFolder === null)) ? (
        <SubfolderItem
          loading={isLoading}
          data={folder.children ?? []}
          collectionId={collectionId}
          isWorkout={isWorkout}
          allOpen={allOpen}
          searchValue={searchValue}
          documents={documents}
          onFolderDocumentsChange={onFolderDocumentsChange}
          selectedWorkoutFolder={selectedWorkoutFolder}
          setSelectedWorkoutFolder={setSelectedWorkoutFolder}
          selectedFile={selectedFile}
          onFileSingleClick={onFileSingleClick}
          onFileDoubleClick={onFileDoubleClick}
          controlledToggledFolderObject={controlledToggledFolderObject}
          controlledSetToggledFolder={controlledSetToggledFolder}
          controlledSelectedFolder={controlledSelectedFolder}
          controlledSetSelectedFolder={controlledSetSelectedFolder}
        />
      ) : null}
    </>
  );
}
