import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import styled from "styled-components";

import { COLOR_GRAY } from "../../colors";
import { WorkoutEditorContextProvider } from "../../contexts/workout/WorkoutEditorProvider";
import { Tab } from "../Tabs";

import { TabLabel } from "./model";
import { WorkoutEditorDocuments } from "./WorkoutEditorDocuments";
import { WorkoutEditorFolders } from "./WorkoutEditorFolders";
import { WorkoutEditorForms } from "./WorkoutEditorForms";

dayjs.extend(utc);
dayjs.extend(timezone);

const Container = styled.div`
  display: flex;
  background-color: ${COLOR_GRAY};
  overflow-y: hidden;
  height: calc(100vh - 55px);
`;

const getTabs = (t): Tab[] => [
  {
    id: TabLabel.INFO,
    title: t("info").toUpperCase(),
  },
  {
    id: TabLabel.PLAN,
    title: t("plan").toUpperCase(),
  },
];

export function WorkoutEditor() {
  const { t } = useTranslation();

  const tabs = getTabs(t);

  const [isDocumentEditorMinimized, setIsDocumentEditorMinimized] =
    useState(false);
  const [tab, setTab] = useState<Tab>(tabs[0]);

  const toggleMinimize = () => {
    setIsDocumentEditorMinimized((current) => !current);
  };

  const handleAfterPlusClick = () => {
    if (tab.id === TabLabel.INFO) {
      setTab(tabs[1]);
    }
  };

  return (
    <WorkoutEditorContextProvider>
      <Container>
        <WorkoutEditorFolders />
        <WorkoutEditorDocuments
          isMinimized={isDocumentEditorMinimized}
          toggleMinimize={toggleMinimize}
          handleAfterPlusClick={handleAfterPlusClick}
        />
        <WorkoutEditorForms
          isDocumentEditorMinimized={isDocumentEditorMinimized}
          tabs={tabs}
          setTab={setTab}
          tab={tab}
        />
      </Container>
    </WorkoutEditorContextProvider>
  );
}
