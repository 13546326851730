import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { ApolloQueryResult } from "@apollo/client";

import { COLOR_BLUE } from "../../colors";
import { MAX_COLUMN_COUNT } from "../../constants/ReportGrid";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { WidgetTemplatesQuery } from "../../graphql";
import Dropdown from "../Dropdown";
import Hoverable from "../Hoverable";
import { IconButton } from "../IconButton";
import { ThreeDotsIcon } from "../Icons";
import { Loader } from "../Loader";

import { useNavigateToWidgetDetail } from "./hooks/useNavigateToWidgetDetail";
import { useSaveAsWidget } from "./hooks/useSaveAsWidget";
import { PERCENTAGE_OF_RIGHT_ORIENTATED_MENUS } from "./PlaceholderOptions";
import { OptionItemText, StyledOptionItem } from "./styled";
import { WidgetMode } from "./WidgetWithData";

interface IWidgetOptions {
  report: any;
  widget: any;
  mode: WidgetMode;
  layoutIndex: number;
  isDeletingWidget: boolean;
  onDeleteWidgetPress: () => void;
  refetchGeneralWidgets: () => Promise<ApolloQueryResult<WidgetTemplatesQuery>>;
}

const WidgetOptions = ({
  report,
  widget,
  mode,
  layoutIndex,
  onDeleteWidgetPress,
  refetchGeneralWidgets,
  isDeletingWidget,
}: IWidgetOptions) => {
  const { t } = useTranslation();
  const { saveWidget } = useSaveAsWidget(widget, mode);
  const modal = useModalContext();
  const { navigateToWidgetDetail } = useNavigateToWidgetDetail();
  const [searchParams] = useSearchParams();

  const activeTabId = searchParams.get("activeTabId");
  const activeProfileTabId = searchParams.get("activeProfileTabId");

  const onEditWidgetPress = () => {
    navigateToWidgetDetail({
      widget,
      mode,
      reportId: report.id,
      parentReportId: report.parentReportId,
      activeTabId,
      activeProfileTabId,
      layoutIndex,
      editReportWidget: true,
    });
  };

  const onSaveTemplatePress = async () => {
    modal.actions.openModal({
      modal: ModalType.RENAME_WIDGET,
      title: t("nameWidgetTemplate"),
      params: {
        defaultValue: widget.title || widget.type + " template",
        onDone: async (templateName) => {
          await saveWidget(templateName, report.id);
          await refetchGeneralWidgets();
        },
      },
    });
  };

  const leftSideOrientedDropdown =
    widget?.columnXPosition <
    PERCENTAGE_OF_RIGHT_ORIENTATED_MENUS * MAX_COLUMN_COUNT;

  return (
    <Dropdown
      dropdownContainerStyle={{
        padding: 0,
        marginRight: 0,
        width: 170,
      }}
      dropdownContentStyle={{
        padding: "5px 0",
        top: 10,
        ...(leftSideOrientedDropdown ? { left: 100 } : { right: 10 }),
      }}
      component={({ toggle, show }) => (
        <IconButton
          icon={<ThreeDotsIcon isActive={show} />}
          enforceActiveState={show}
          onClick={toggle}
        />
      )}
    >
      <Hoverable>
        {(isHovered) => (
          <StyledOptionItem onClick={onSaveTemplatePress} isHovered={isHovered}>
            <OptionItemText>{t("saveAsTemplate")}</OptionItemText>
          </StyledOptionItem>
        )}
      </Hoverable>
      <Hoverable>
        {(isHovered) => (
          <StyledOptionItem onClick={onEditWidgetPress} isHovered={isHovered}>
            <OptionItemText>{t("editWidget")}</OptionItemText>
          </StyledOptionItem>
        )}
      </Hoverable>
      <Hoverable>
        {(isHovered) => (
          <StyledOptionItem onClick={onDeleteWidgetPress} isHovered={isHovered}>
            {isDeletingWidget ? (
              <Loader color={COLOR_BLUE} size="small" />
            ) : (
              <OptionItemText>{t("deleteWidget")}</OptionItemText>
            )}
          </StyledOptionItem>
        )}
      </Hoverable>
    </Dropdown>
  );
};

export default WidgetOptions;
