import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../colors";
import { Period, PeriodType } from "../../models";
import { CalendarInput } from "../CalendarInput";
import { Checkbox } from "../Checkbox";
import { Section } from "../Section";
import { SectionTitle } from "../SectionTitle";
import { DraggableLineWrapper } from "../styled";
import StyledText from "../StyledText";

interface PeriodSectionProps {
  period: Period;
  withDynamicSelection?: boolean;
  showCalendarAboveInput?: boolean;
  sectionZIndex?: number;
  setFieldValue(name: string, value: Period | dayjs.Dayjs): void;
}

const PeriodRow = styled(DraggableLineWrapper)`
  justify-content: space-between;
  flex-wrap: wrap;
`;

const CalendarTitle = styled(StyledText)`
  color: ${COLOR_MEDIUM_GRAY};
  font-size: 10px;
  margin-bottom: 7px;
  margin-top: 20px;
`;

const DateSelectorControlWrapper = styled.div<{
  withLeftMargin?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: ${({ withLeftMargin }) => (withLeftMargin ? 15 : 0)}px;
`;

export const PeriodSection = ({
  setFieldValue,
  period,
  withDynamicSelection = false,
  showCalendarAboveInput = true,
  sectionZIndex = 1,
}: PropsWithChildren<PeriodSectionProps>) => {
  const { t } = useTranslation();
  const { from, to } = period || {};

  const fromDate = dayjs(from);
  const toDate = dayjs(to);
  const todayDate = dayjs();

  return (
    <Section style={{ zIndex: sectionZIndex }}>
      <SectionTitle title={t("period")} />
      <PeriodRow>
        <Checkbox
          text={t("useSelectionInReport")}
          typeIcon="circle"
          check={() =>
            setFieldValue("period", {
              type: PeriodType.SELECTION,
              from: null,
              to: null,
              isTodaySetAsEnd: false,
            })
          }
          checked={period.type === PeriodType.SELECTION}
        />
        <Checkbox
          text={t("fixedPeriod")}
          typeIcon="circle"
          check={() =>
            setFieldValue("period", {
              type: PeriodType.FIXED,
              from: dayjs().startOf("day"),
              to: dayjs().add(14, "day").endOf("day"),
              isTodaySetAsEnd: false,
            })
          }
          checked={period.type === PeriodType.FIXED}
        />
        {withDynamicSelection && (
          <Checkbox
            text={t("dynamicPeriod")}
            typeIcon="circle"
            check={() =>
              setFieldValue("period", {
                type: PeriodType.DYNAMIC,
                from: dayjs().subtract(1, "month"),
                to: null,
                isTodaySetAsEnd: true,
              })
            }
            checked={period.type === PeriodType.DYNAMIC}
          />
        )}
      </PeriodRow>

      {period.type === PeriodType.FIXED && (
        <DraggableLineWrapper style={{ justifyContent: "flex-start" }}>
          <CalendarInput
            title={t("startDate")}
            from={fromDate}
            to={toDate}
            value={fromDate.startOf("day")}
            set={(value) => {
              if (value.isBefore(toDate)) {
                setFieldValue("period.from", value.startOf("day"));
              }
            }}
            showCalendarAboveInput={showCalendarAboveInput}
          />
          <CalendarInput
            title={t("endDate")}
            from={fromDate}
            to={toDate}
            value={toDate.endOf("day")}
            minDate={fromDate}
            set={(value) => {
              if (fromDate.isBefore(value)) {
                setFieldValue("period.to", value.endOf("day"));
              }
            }}
            withLeftMargin
            showCalendarAboveInput={showCalendarAboveInput}
          />
        </DraggableLineWrapper>
      )}
      {period.type === PeriodType.DYNAMIC && (
        <DraggableLineWrapper style={{ justifyContent: "space-between" }}>
          <CalendarInput
            title={t("startDate")}
            value={fromDate.startOf("day")}
            from={fromDate}
            to={todayDate}
            set={(value) => {
              if (value.isBefore(todayDate)) {
                setFieldValue("period.from", value.startOf("day"));
              }
            }}
            showCalendarAboveInput={showCalendarAboveInput}
          />
          <DateSelectorControlWrapper withLeftMargin>
            <CalendarTitle>{`${t("endDate")}`}</CalendarTitle>
            <Checkbox
              text={t("today")}
              typeIcon="circle"
              check={() => {}}
              checked={period.isTodaySetAsEnd}
              withMargin={false}
            />
          </DateSelectorControlWrapper>
        </DraggableLineWrapper>
      )}
    </Section>
  );
};
