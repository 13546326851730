import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled, { useTheme } from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DEFAULT, COLOR_WHITE } from "../colors";
import { AvatarSelectWrapper } from "../components/AvatarSelectWrapper";
import { Header } from "../components/Header";
import { SearchIcon } from "../components/Icons";
import { CreateItemDropdown } from "../components/Report/CreateItemDropdown";
import ReportsList from "../components/Report/ReportsList";
import { TemplatesList } from "../components/Report/TemplatesList";
import ScreenContainer from "../components/ScreenContainer";
import { SearchBar } from "../components/SearchBar";
import { useCanEditStatistics } from "../contexts/accessLevels/useCanEditStatistics";
import { ReportType } from "../graphql";

const ListTitle = styled.h1`
  color: ${COLOR_TEXT_DEFAULT};
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin: 0;
  margin-left: ${({ theme }) => (!theme.isMobile ? 20 : 0)}px;
  font-family: "Inter", sans-serif;
`;

const ListWrapper = styled.div`
  margin-top: 20px;
  border-radius: 6px;
`;

const SearchIconWrapper = styled.div`
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border-radius: 50%;
    background-color: ${COLOR_WHITE};
  }
`;

const SelectWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  padding-left: ${({ isMobile }) => (isMobile ? 15 : 30)}px;
  padding-right: ${({ isMobile }) => (isMobile ? "15px" : 0)};
  padding-top: 15px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px;
  align-items: center;
`;

const FullWidth = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AthleteWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  width: 100%;
  z-index: 4;
`;

const RightSection = styled.div`
  display: flex;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const TeamWrapper = styled.div<{ isMobileNotEditable: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: ${({ isMobileNotEditable }) =>
    isMobileNotEditable ? "30px" : 0};
  z-index: 1;
`;

const WidgetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 70px;
  z-index: 0;
`;

export function ListReportsScreen() {
  const { t } = useTranslation();
  const { isMobile } = useTheme();

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const canEdit = useCanEditStatistics();

  return (
    <ScreenContainer style={{ maxHeight: "100vh" }}>
      <div style={{ zIndex: 99 }}>
        <Header />
      </div>
      <SelectWrapper isMobile={isMobile}>
        <AvatarSelectWrapper accessLevelArea="statistics" />
      </SelectWrapper>
      <ContentWrapper>
        <FullWidth>
          <AthleteWrapper>
            <ListTitle>{t("athleteReports")}</ListTitle>
            <RightSection>
              <SearchWrapper>
                {isSearching ? (
                  <SearchBar
                    onClear={() => {
                      setSearchText("");
                      setIsSearching(false);
                    }}
                    value={searchText}
                    onChangeText={(text) => setSearchText(text)}
                    withInitialFocus
                  />
                ) : (
                  <SearchIconWrapper onClick={() => setIsSearching(true)}>
                    <SearchIcon width={16} height={16} color={COLOR_BLUE} />
                  </SearchIconWrapper>
                )}
              </SearchWrapper>
              {!isMobile && canEdit && <CreateItemDropdown />}
            </RightSection>
          </AthleteWrapper>

          <ListWrapper style={{ zIndex: 2 }}>
            <ReportsList
              reportType={ReportType.AthleteReport}
              searchQuery={searchText}
            />
          </ListWrapper>

          <TeamWrapper isMobileNotEditable={!canEdit || isMobile}>
            <ListTitle>{t("teamReports")}</ListTitle>
            <ListWrapper>
              <ReportsList
                reportType={ReportType.GroupReport}
                searchQuery={searchText}
              />
            </ListWrapper>
          </TeamWrapper>

          {!isMobile && canEdit && (
            <WidgetsWrapper>
              <ListTitle>{t("widgets")}</ListTitle>
              <ListWrapper>
                <TemplatesList searchQuery={searchText} />
              </ListWrapper>
            </WidgetsWrapper>
          )}
        </FullWidth>
      </ContentWrapper>
    </ScreenContainer>
  );
}
