import React from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_WHITE,
} from "../../colors";
import { useReportsContext } from "../../contexts/Reports";
import { BasicButton } from "../Button";
import Dropdown from "../Dropdown";
import { ChevronRightIcon } from "../Icons";

import { ReportPeriodCalendarComponent } from "./ReportPeriodCalendarComponent";
import { ReportPeriodText } from "./ReportPeriodText";
import { ReportSingleDayCalendar } from "./ReportSingleDayCalendar";

const PeriodWrapper = styled.div<{ hovered: boolean }>`
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ hovered }) =>
    hovered ? COLOR_GREY_ACTIVE : COLOR_GRAY};
  border-radius: 6px;
  padding: 0 10px;
`;

const DownIconWrapper = styled.div<{ show: boolean }>`
  display: flex;
  padding: 4px;
  border-radius: 12px;
  align-self: center;
  margin-left: 10px;
  background-color: ${({ show }) => (show ? COLOR_BLUE : COLOR_WHITE)};
`;

export function ReportPeriod({
  isSingleDay = false,
}: {
  isSingleDay?: boolean;
}) {
  const [state, actions] = useReportsContext();

  const {
    selectedPeriod,
    selectedStartDate,
    selectedEndDate,
    selectedSingleDay,
  } = state;

  const setStartDate = (date) => {
    actions.selectStartDate(dayjs(date));
  };

  const setEndDate = (date) => {
    actions.selectEndDate(dayjs(date));
  };

  const setSingleDay = (date) => {
    actions.selectSingleDay(dayjs(date));
  };

  return (
    <Dropdown
      component={({ toggle, show, hovered }) => (
        <BasicButton onClick={toggle}>
          <PeriodWrapper hovered={hovered || show}>
            <ReportPeriodText isSingleDay={isSingleDay} />
            <DownIconWrapper show={show}>
              <ChevronRightIcon
                tintColor={show ? COLOR_WHITE : COLOR_BLUE}
                direction="bottom"
              />
            </DownIconWrapper>
          </PeriodWrapper>
        </BasicButton>
      )}
      dropdownContainerStyle={{ top: "35px" }}
      dropdownMainContainerStyle={{ position: "relative" }}
      dropdownContentStyle={{ padding: 20, borderRadius: 12 }}
    >
      {isSingleDay ? (
        <ReportSingleDayCalendar
          selectedSingleDay={selectedSingleDay}
          setSingleDay={setSingleDay}
        />
      ) : (
        <ReportPeriodCalendarComponent
          selectedPeriod={selectedPeriod}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          selectPeriod={actions.selectPeriod}
        />
      )}
    </Dropdown>
  );
}
