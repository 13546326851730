import React, { Dispatch, useRef, useState } from "react";
import { createPortal } from "react-dom";

import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import {
  COLOR_BUTTON_GRAY_TEXT,
  COLOR_GRAY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
  COLOR_WHITE,
} from "../colors";
import { useDefaultDateFormat, useHandleClickOutside } from "../hooks";

import { CalendarIcon } from "./Icons";
import { ReactCalendar } from "./ReactCalendar";

type DatePickerProps = {
  label: string;
  date: Date;
  setDate: Dispatch<number>;
};

function getCalendarPosition(dateRef) {
  if (dateRef.current) {
    return {
      left: dateRef.current.getBoundingClientRect().left + window.scrollX || 0,
      top:
        dateRef.current.getBoundingClientRect().bottom + window.scrollY + 5 ||
        0,
    };
  }

  return {
    left: 0,
    top: 0,
  };
}

const Wrapper = styled.div`
  align-self: flex-start;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR_BUTTON_GRAY_TEXT};
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const Date = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  height: 45px;
  border-radius: 6px;
  border: 0;
  cursor: pointer;
  padding: 0px 16px;
  background-color: ${COLOR_GRAY};
  color: ${COLOR_TEXT_DARK};
  font-weight: 700;
  gap: 12px;
`;

const CalendarWrapper = styled.div<{ left: number; top: number }>`
  position: absolute;
  background-color: ${COLOR_WHITE};
  z-index: 9999;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  border-radius: 6px;
  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 30px;
`;

export function DatePicker({ label, date, setDate }: DatePickerProps) {
  const datePickerRef = useRef(null);
  const dateRef = useRef(null);

  const [showCalendar, setShowCalendar] = useState(false);
  const { dateFormat } = useDefaultDateFormat();

  const { top, left } = getCalendarPosition(dateRef);

  const handleDateChange = (newDate: Dayjs) => {
    setDate(newDate.valueOf());
    setShowCalendar(false);
  };

  useHandleClickOutside(datePickerRef, () => setShowCalendar(false));

  return (
    <Wrapper id="date-picker">
      <Label>{label}</Label>
      <Date ref={dateRef} onClick={() => setShowCalendar(true)}>
        <CalendarIcon color={COLOR_TEXT_TABLE_LABEL} />
        {dayjs(date).format(dateFormat)}
      </Date>
      {showCalendar &&
        createPortal(
          <CalendarWrapper ref={datePickerRef} top={top} left={left}>
            <ReactCalendar
              set={handleDateChange}
              value={dayjs(date)}
              from={dayjs(date)}
              to={dayjs(date)}
            />
          </CalendarWrapper>,
          document.body
        )}
    </Wrapper>
  );
}
