import React, { CSSProperties, MouseEvent } from "react";

import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_DEFAULT, COLOR_WHITE } from "../colors";

import { BasicButton } from "./Button";
import StyledText from "./StyledText";

const ItemButton = styled(BasicButton)`
  background-color: ${COLOR_WHITE};

  &:hover {
    background-color: ${COLOR_GRAY};
  }
`;

export const ItemContainer = styled(StyledText)<{ level?: number }>`
  font-style: normal;
  font-weight: bold;
  height: 35px;
  line-height: 15px;
  cursor: pointer;
  font-size: 12px;
  color: ${COLOR_TEXT_DEFAULT};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ level = 1 }) => `0 15px 0 ${level * 15}px`};
`;

interface HoverableItemProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  label: string;
  containerStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
}

export const HoverableItem = ({
  onClick,
  label,
  containerStyle,
  buttonStyle,
}: HoverableItemProps) => {
  return (
    <ItemButton onClick={onClick} style={buttonStyle}>
      <ItemContainer
        numberOfLines={1}
        ellipsizeMode="tail"
        style={containerStyle}
      >
        {label}
      </ItemContainer>
    </ItemButton>
  );
};
