import React, { useMemo } from "react";

import { ReportWidgetTemplate } from "../../../graphql";
import { useWidgetTypes } from "../../../utils/widgetTypes";
import { WidgetType } from "../../ReportCreateTable/models";
import {
  OptionItemText,
  SecondLevelOptionsContentWrapper,
  StyledOptionItem,
} from "../styled";
import { WidgetMode } from "../WidgetWithData";

export enum SecondLevelOptionsWrapperOrientation {
  LEFT = "left",
  RIGHT = "right",
}

interface SecondLevelWidgetOption {
  label: string;
  type: WidgetType;
  id?: string;
}

interface SecondLevelOptionsWrapperProps {
  orientation: SecondLevelOptionsWrapperOrientation;
  mode: WidgetMode;
  onOptionClick: (widgetType: WidgetType, id?: string) => void;
  generalWidgets?: ReportWidgetTemplate[];
  topOrientedDropdown: boolean;
}

export const SecondLevelOptionsWrapper = ({
  orientation,
  mode,
  onOptionClick,
  generalWidgets,
  topOrientedDropdown,
}: SecondLevelOptionsWrapperProps) => {
  const getWidgetTypes = useWidgetTypes();

  const secondLevelWidgetOptions = useMemo((): SecondLevelWidgetOption[] => {
    if (generalWidgets) {
      // @ts-ignore "type" is here
      return generalWidgets.map(({ name, type, id }) => ({
        label: name,
        type,
        id,
      }));
    } else {
      return getWidgetTypes(mode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalWidgets, mode]);

  return (
    <SecondLevelOptionsContentWrapper
      orientation={orientation}
      topOrientated={topOrientedDropdown}
    >
      {secondLevelWidgetOptions.map(({ label, type, id }) => (
        <StyledOptionItem
          key={`${type}-${id}`}
          onClick={() => onOptionClick(type, id)}
        >
          <OptionItemText>{label}</OptionItemText>
        </StyledOptionItem>
      ))}
    </SecondLevelOptionsContentWrapper>
  );
};
