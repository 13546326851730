import React, { memo } from "react";

import styled from "styled-components";

import { Document, Drill } from "../../graphql";
import { useModularContentFetching } from "../../hooks/useModularContentFetching";
import { Spinner, SpinnerSize } from "../Spinner";

import { ImageModule } from "./ImageModule";
import { TextModule } from "./TextModule";
import { VideoModule } from "./VideoModule";

type ModularContentProps = {
  collection: Document | Drill;
  formMethods?: any;
  isPreviewWorkout?: boolean;
  splitAreaId?: string;
  isSplit?: boolean;
};

const ModularContentWrapper = styled.div<{ columnDirection?: boolean }>`
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: ${({ columnDirection }) =>
    columnDirection ? "column" : "row"};
`;

const VisualContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 45%;
  min-width: 30%;
`;

export const ModularContent = memo(
  ({
    collection,
    formMethods,
    isPreviewWorkout,
    splitAreaId,
    isSplit,
  }: ModularContentProps) => {
    const { isLoading, modularContent } = useModularContentFetching(collection);

    if (isLoading) {
      return <Spinner size={SpinnerSize.SMALL} />;
    }

    return (
      <ModularContentWrapper columnDirection={isSplit}>
        {modularContent && (
          <VisualContentWrapper>
            {Object.keys(modularContent ?? {}).map((property) => {
              switch (property) {
                case "images":
                  return (
                    <ImageModule
                      data={modularContent[property]}
                      key={property}
                    />
                  );
                case "videos":
                case "vimeoVideos":
                case "youtubeVideos":
                  return (
                    <VideoModule
                      data={modularContent[property]}
                      type={property}
                      key={property}
                    />
                  );
                default:
                  break;
              }
            })}
          </VisualContentWrapper>
        )}
        <TextModule
          isPreviewWorkout={isPreviewWorkout}
          data={modularContent?.paragraphs}
          formMethods={formMethods}
          id={collection?.id}
          splitAreaId={splitAreaId}
        />
      </ModularContentWrapper>
    );
  },
  // stops rerendering content and reloading video thumbnails
  (prevProps, nextProps) =>
    prevProps.collection?.id === nextProps.collection?.id
);
