import React from "react";
import { useTranslation } from "react-i18next";

import { useCanEditStatistics } from "../../contexts/accessLevels/useCanEditStatistics";
import { useNotificationsContext } from "../../contexts/notifications";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";

import { BaseListDropdownOptionsItemIds, BaseListItem } from "./BaseListItem";
import { useDeleteWidgetWarning } from "./hooks/useDeleteWidgetWarning";
import { useNavigateToWidgetDetail } from "./hooks/useNavigateToWidgetDetail";
import { useSaveAsWidget } from "./hooks/useSaveAsWidget";
import { WidgetMode } from "./WidgetWithData";

interface ITemplatesListItemProps {
  widget: any;
  isLast: boolean;
  curItem: string;
  setCurItem: (id: string) => void;
  deletingWidget: boolean;
}

const TemplatesListItem = ({
  widget,
  deletingWidget,
  isLast,
  curItem,
  setCurItem,
}: ITemplatesListItemProps) => {
  const { t } = useTranslation();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();

  const mode = widget?.isGroupWidget
    ? WidgetMode.GROUP
    : widget.__typename?.includes("Group")
      ? WidgetMode.GROUP
      : WidgetMode.ATHLETE;

  const { navigateToWidgetDetail } = useNavigateToWidgetDetail();
  const modal = useModalContext();
  const { saveWidget } = useSaveAsWidget(widget, mode);

  const canEdit = useCanEditStatistics();

  const onNameClick = () => {
    navigateToWidgetDetail({
      widget: { ...widget, ...JSON.parse(widget.preferences) },
      mode,
    });
  };

  const handleDeleteWidget = useDeleteWidgetWarning({
    widgetId: widget.id,
  });

  const onRenameWidgetPress = () => {
    modal.actions.openModal({
      modal: ModalType.RENAME_WIDGET,
      title: t("renameWidget"),
      params: {
        defaultValue: widget.name,
        onDone: async (templateName) => {
          try {
            await saveWidget(templateName, "", true, false);
            showSuccessNotification(t("renameWidgetSuccess"));
          } catch {
            showErrorNotification(t("renameWidgetError"));
          }
        },
      },
    });
  };

  const getTypeOfWidget = () => {
    switch (widget.__typename) {
      case "ReportWidgetTemplateAttendanceLog":
        return t("attendanceLog");
      case "ReportWidgetTemplateAthleteTable":
        return t("athleteTable");
      case "ReportWidgetTemplateGroupTable":
        return t("teamTable");
      case "ReportWidgetTemplateChart":
        return t("chart");
      case "ReportWidgetTemplateKeyValues":
        return t("keyValues");
      case "ReportWidgetTemplateCombinedTest":
        return t("combinedTest");
      case "ReportWidgetTemplateInformationLog":
        return t("informationLog");
      case "ReportWidgetTemplateActivityLog":
        return t("activityLog");
      case "ReportWidgetTemplateAcuteChronic":
        return t("acuteChronicChart");
      case "ReportWidgetTemplateSchalkeAthleteTable":
        return t("tablePeriodization");
      case "ReportWidgetTemplateSchalkeTeamTable":
        return t("tablePeriodization");
      case "ReportWidgetTemplateBodyChart":
        return t("bodyChart");
      default:
        return "";
    }
  };
  const items = canEdit
    ? [
        {
          id: BaseListDropdownOptionsItemIds.RENAME,
          onItemClick: onRenameWidgetPress,
          title: t("renameWidget"),
        },
        {
          id: BaseListDropdownOptionsItemIds.DELETE,
          onItemClick: handleDeleteWidget,
          title: t("deleteWidget"),
        },
      ]
    : [];

  return (
    <BaseListItem
      onNameClick={onNameClick}
      createdBy={widget.createdBy}
      updatedAt={widget.updatedAt}
      objectName={widget.name}
      setCurrentItem={setCurItem}
      currentItemId={curItem}
      objectId={widget.id}
      objectType={getTypeOfWidget()}
      isDeleting={deletingWidget}
      isLast={isLast}
      items={items}
    />
  );
};

export default TemplatesListItem;
