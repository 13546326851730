import React from "react";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../colors";
import { useUserContext } from "../../contexts/User";
import {
  DocumentPreview,
  useWorkoutEditorContext,
} from "../../contexts/workout/WorkoutEditorProvider";
import { useCreateExerciseMutation, Document } from "../../graphql";
import { ItemTypes as FileItemTypes } from "../Modals/CreateDrill/model";
import { SessionLines } from "../SessionPlan/SessionLines";

import { ItemTypes } from "./model";

const Container = styled.div<{ isHidden?: boolean; isNotEmpty?: boolean }>`
  padding: 15px;
  position: relative;
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  flex: 1;
  justify-content: ${({ isNotEmpty }) =>
    isNotEmpty ? "flex-start" : "center"};
  align-items: ${({ isNotEmpty }) => (isNotEmpty ? "flex-start" : "center")};
`;

const EmptyText = styled.div`
  font-size: 12px;
  color: ${COLOR_MEDIUM_GRAY};
  text-align: center;
  font-weight: 500;
`;

interface PlanTabProps {
  isHidden?: boolean;
  isDocumentEditorMinimized: boolean;
}

export const PlanTab = ({
  isHidden,
  isDocumentEditorMinimized,
}: PlanTabProps) => {
  const { t } = useTranslation();
  const { sessionId, language, timezone } = useUserContext();
  const { workoutData, selectedDocuments, addDocument, sessionGuid } =
    useWorkoutEditorContext();
  const [createExercise] = useCreateExerciseMutation();

  const handleDrop = async (document: DocumentPreview | { file: Document }) => {
    const finalData = "file" in document ? document.file : document;
    if (sessionGuid) {
      addDocument(finalData);
      createExercise({
        variables: {
          sessionId,
          language,
          timezone,
          input: {
            templateId: finalData.id,
            workoutId: sessionGuid,
          },
        },
        refetchQueries: () => ["Workout", "ExerciseSets"],
      });
    } else {
      addDocument({
        ...finalData,
        key: `${finalData.id}-${Math.round(Math.random() * 1000)}`,
      });
    }
  };

  const [, drop] = useDrop({
    accept: [ItemTypes.DOCUMENT, FileItemTypes.FILE],
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isNotEmpty =
    workoutData?.workout?.workoutLines?.length || selectedDocuments?.length;

  return (
    <>
      <Container isHidden={isHidden} isNotEmpty={!!isNotEmpty} ref={drop}>
        {isNotEmpty ? (
          <SessionLines
            data={workoutData?.workout?.workoutLines ?? selectedDocuments}
            isWorkoutEditMode
            isDocumentEditorHidden={isDocumentEditorMinimized}
          />
        ) : (
          <EmptyText>{t("emptyWorkout")}</EmptyText>
        )}
      </Container>
    </>
  );
};
