import React, { Fragment, useCallback, useState } from "react";

import styled, { useTheme } from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../colors";
import { useCanEditStatistics } from "../../contexts/accessLevels/useCanEditStatistics";
import { useAthleteProfileContext } from "../../contexts/athleteProfile";
import {
  MobileModalTypes,
  useMobileModalContext,
} from "../../contexts/mobileModal/MobileModalProvider";
import { useReportContext } from "../../contexts/report";
import { useUserContext } from "../../contexts/User";
import { ReportType } from "../../graphql";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { IconButton } from "../IconButton";
import { PlusInRoundIcon } from "../Icons";
import { MobileGroupSelector } from "../MobileGroupSelector";
import { AthleteSelectorOnMobile } from "../Report/mobile/AthleteSelectorOnMobile";
import { ReportPeriodOnMobile } from "../Report/mobile/ReportPeriodOnMobile";
import ReportGroupSelector from "../Report/ReportGroupSelector";
import { ReportPeriodSelection } from "../Report/ReportPeriodSelection";
import Tabs from "../Tabs";

import { SelectReportsToShowButton } from "./SelectReportsToShowAddButton";

const MobilePeriodWrapper = styled.div`
  flex-grow: 1;
`;

const Container = styled.div<{
  isDesktop: boolean;
  isAthleteProfileReport?: boolean;
}>`
  display: flex;
  align-items: ${({ isDesktop }) => (isDesktop ? "center" : "flex-start")};
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row" : "column")};
  flex-wrap: ${({ isDesktop }) => (isDesktop ? "no-wrap" : "wrap")};
  padding-left: ${({ isDesktop }) => (isDesktop ? "30px" : "15px")};
  padding-right: ${({ isDesktop }) => (isDesktop ? "30px" : "15px")};
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: ${COLOR_GREY_ACTIVE};
  background-color: ${({ isAthleteProfileReport }) =>
    isAthleteProfileReport ? COLOR_WHITE : "inherit"};
  z-index: 2;
`;

const MobileGroupReportSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 35px;
  align-items: center;
`;

const MobileTabsButtonWrapper = styled.div`
  width: 100%;
`;

const MobileSelectorsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const GroupReportsSelectorWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "100%" : "auto")};
  height: ${({ isMobile }) => (isMobile ? "35px" : "auto")};
  z-index: 2;
`;

const MobileControlSection = styled.div`
  display: flex;
`;

const TabsWrapper = styled.div<{
  leftOverflow: boolean;
  rightOverflow: boolean;
  isDesktop: boolean;
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: ${({ isDesktop, isMobile }) =>
    isDesktop ? "0 0 0 20px" : isMobile ? "20px 0 0 0" : "50px 0 0 0"};
  flex: ${({ isDesktop }) => (!isDesktop ? "auto" : "1")};
  ${({ leftOverflow, rightOverflow, isMobile }) => {
    if (isMobile) {
      return `
        mask-image: linear-gradient(to right, transparent 0, black ${
          leftOverflow ? "9" : "0"
        }%, black ${rightOverflow ? "90" : "100"}%, transparent 100%);
        -webkitmask-image: linear-gradient(to right, transparent 0, black ${
          leftOverflow ? "9" : "0"
        }%, black ${rightOverflow ? "90" : "100"}%, transparent 100%);
      `;
    }
  }}
  max-width: 100%;
`;

const ShowButtonWrapper = styled.div`
  margin-left: 12px;
  margin-top: 18px;
`;

const PeriodSelectionContainer = styled.div<{ isMobile: boolean }>`
  padding-left: ${({ isMobile }) => (isMobile ? "15px" : "30px")};
  padding-right: ${({ isMobile }) => (isMobile ? "15px" : "30px")};
  padding-top: ${({ isMobile }) => (isMobile ? "15px" : "18px")};
  padding-bottom: ${({ isMobile }) => (isMobile ? "15px" : "18px")};
  flex-direction: ${({ isMobile }) => (isMobile ? "row" : "column")};
  align-items: ${({ isMobile }) => (isMobile ? "center" : "flex-start")};
  z-index: 99;
`;

const DesktopTabsButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export function AhtleteProfileHeader({
  isAthleteProfileReport,
}: {
  isAthleteProfileReport?: boolean;
}) {
  const { isDesktop, isMobile } = useTheme();
  const { report } = useReportContext();
  const { activeTab, groupTabs, selectTab, configureTabs } =
    useAthleteProfileContext();
  const { isTrainer, isFamilyMember } = useUserContext();
  const { showModal } = useMobileModalContext();
  const [leftOverflow, setLeftOverflow] = useState<boolean>();
  const [rightOverflow, setRightOverflow] = useState<boolean>(true);
  const canEdit = useCanEditStatistics();

  const handleScroll = useCallback((e) => {
    setLeftOverflow(e.target.scrollLeft !== 0);
    setRightOverflow(
      e.target.scrollWidth > e.target.clientWidth + e.target.scrollLeft
    );
  }, []);

  // We want to wrap items only on mobile to enforce correct breaking
  const HeaderBreaker = !isDesktop
    ? MobileTabsButtonWrapper
    : DesktopTabsButtonWrapper;
  const Breaker = !isDesktop ? MobileSelectorsWrapper : Fragment;

  return (
    <>
      <Container
        isDesktop={isDesktop}
        isAthleteProfileReport={isAthleteProfileReport}
      >
        <GroupReportsSelectorWrapper isMobile={isMobile}>
          {isMobile ? (
            <MobileGroupReportSelectorWrapper>
              <MobileGroupSelector />
              {isRealTrainer({ isTrainer, isFamilyMember }) && (
                <IconButton
                  icon={<PlusInRoundIcon />}
                  onClick={() => showModal(MobileModalTypes.TAB_PICKER)}
                />
              )}
            </MobileGroupReportSelectorWrapper>
          ) : (
            <Breaker>
              <ReportGroupSelector />
            </Breaker>
          )}
        </GroupReportsSelectorWrapper>
        <HeaderBreaker>
          {groupTabs.length === 0 && !isMobile ? (
            <ShowButtonWrapper>
              <SelectReportsToShowButton
                configureTabs={configureTabs}
                groupTabs={groupTabs}
              />
            </ShowButtonWrapper>
          ) : (
            <TabsWrapper
              style={{
                marginTop: 18,
                justifyContent: "flex-start",
              }}
              leftOverflow={leftOverflow}
              rightOverflow={rightOverflow}
              isDesktop={isDesktop}
              isMobile={isMobile}
            >
              <Tabs
                tab={activeTab}
                setTab={selectTab}
                tabs={groupTabs}
                onScroll={handleScroll}
                tabStyle={{ width: "max-content" }}
                style={{ overflow: isMobile ? "scroll" : "hidden" }}
                afterLastTabComponent={
                  isTrainer &&
                  !isMobile &&
                  canEdit && (
                    <SelectReportsToShowButton
                      configureTabs={configureTabs}
                      groupTabs={groupTabs}
                    />
                  )
                }
              />
            </TabsWrapper>
          )}
        </HeaderBreaker>
      </Container>

      <PeriodSelectionContainer isMobile={isMobile}>
        {!isMobile && <ReportPeriodSelection disableBackButton />}

        {isMobile && (
          <MobileControlSection>
            <MobilePeriodWrapper>
              <ReportPeriodOnMobile />
            </MobilePeriodWrapper>
            {report?.reportType === ReportType.AthleteReport &&
              isRealTrainer({ isTrainer, isFamilyMember }) && (
                <AthleteSelectorOnMobile />
              )}
          </MobileControlSection>
        )}
      </PeriodSelectionContainer>
    </>
  );
}
