import React, { memo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DARK, COLOR_WHITE } from "../../../colors";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import {
  DocumentPreview,
  useWorkoutEditorContext,
} from "../../../contexts/workout/WorkoutEditorProvider";
import { Exercise } from "../../../graphql";
import { isAcesNationEnv } from "../../../utils/isAcesNationEnv";
import {
  AddRemoveButton,
  ButtonType,
  ButtonColor,
  GeneralButton,
} from "../../Button/";
import { COLORS, convertIdToVariant } from "../../Collections/utils";
import Hoverable from "../../Hoverable";
import {
  LogoAcesNationIcon,
  ArrowIcon,
  LogoXpsIcon,
  RemoveIcon,
} from "../../Icons";

import { WorkoutTable } from "./WorkoutTable";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid rgba(230, 232, 235, 0.35);
  background: ${COLOR_WHITE};
  box-shadow: 0 2px 6px 0 rgba(34, 62, 106, 0.05);
  margin-bottom: 5px;
  padding: 15px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Title = styled.div`
  color: ${COLOR_TEXT_DARK};
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
`;

const Image = styled.img`
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 15px;
`;

const XpsImageWrapper = styled.div<{ colors: string[] }>`
  object-fit: cover;
  border-radius: 6px;
  margin-right: 15px;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ colors }) =>
    `linear-gradient(131.186deg, ${colors[0]} 0%, ${colors[1]} 100%)`}
}
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 5px;
  min-width: 115px;
`;

const ActionButton = styled.div`
  cursor: pointer;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-right: 5px;
  }
`;

type WorkoutItemProps = {
  data: DocumentPreview;
};

export const WorkoutItemPreview = memo(({ data }: WorkoutItemProps) => {
  const { t } = useTranslation();
  const {
    actions: { openModal },
  } = useModalContext();
  const { onMove, removeDocument, onRemoveSet, sets, updateCell, onAddSet } =
    useWorkoutEditorContext();
  const [isImageBroken, setImageBroken] = useState(false);
  const colors = COLORS[convertIdToVariant(data?.id)];

  const hasUrl = !!data?.modularContentUrl || !!data?.jsonUrl || !!data?.url;

  const formMethods = useForm({
    mode: "all",
    defaultValues: {
      minutes: 10,
      time: "12:00 - 12:10",
      intensity: "",
    },
  });
  const handleOpenInfo = () => {
    if (hasUrl) {
      openModal({
        title: data?.name,
        modal: ModalType.DOCUMENT,
        params: {
          document: {
            ...data,
            mcUrl: data?.modularContentUrl,
            itemName: data?.name,
          },
          hidePopularButton: true,
        },
      });

      return;
    }

    if (data?.modularContentUrl) {
      window.open(data?.modularContentUrl, "_blank");
    }
  };

  const getWorkoutImage = () => {
    if (data?.thumbnail && !isImageBroken) {
      return (
        <Image src={data?.thumbnail} onError={() => setImageBroken(true)} />
      );
    }

    if (isImageBroken || !data?.thumbnail) {
      return (
        <XpsImageWrapper colors={colors}>
          {isAcesNationEnv() ? (
            <LogoAcesNationIcon width={45} height={34} />
          ) : (
            <LogoXpsIcon width={45} height={45} />
          )}
        </XpsImageWrapper>
      );
    }
  };
  return (
    <FormProvider {...formMethods}>
      <Hoverable>
        {(isHovered) => (
          <Wrapper id={`planItem-${data?.key}`}>
            <InfoWrapper>
              {getWorkoutImage()}
              <div>
                <TitleWrapper>
                  <Title>{data && "name" in data ? data?.name : ""}</Title>
                  {isHovered ? (
                    <ActionsContainer>
                      <ActionButton onClick={() => onMove("up", data)}>
                        <ArrowIcon />
                      </ActionButton>
                      <ActionButton onClick={() => onMove("down", data)}>
                        <ArrowIcon direction="bottom" />
                      </ActionButton>
                      <ActionButton onClick={() => removeDocument(data)}>
                        <RemoveIcon />
                      </ActionButton>
                    </ActionsContainer>
                  ) : null}
                </TitleWrapper>
                <ButtonWrapper>
                  <GeneralButton
                    colorVariant={ButtonColor.Blue}
                    label={t("info")}
                    isDisabled={!hasUrl}
                    sizeType={ButtonType.Tiny}
                    outlined
                    onClick={handleOpenInfo}
                  />
                  <>
                    <AddRemoveButton
                      label={t("addSet")}
                      onClick={() => onAddSet(data?.key)}
                    />
                    <AddRemoveButton
                      isDisabled={sets[data?.key ?? ""]?.length === 1}
                      label={t("removeSet")}
                      sizeType={ButtonType.Remove}
                      onClick={() => onRemoveSet(data?.key)}
                    />
                  </>
                </ButtonWrapper>
              </div>
            </InfoWrapper>
            <WorkoutTable
              exerciseData={
                {
                  __typename: "Exercise",
                  ...data,
                } as Exercise
              }
              sets={sets[data?.key ?? ""]}
              onCellUpdated={(cell, value, setId) =>
                updateCell({
                  documentKey: data?.key,
                  cell,
                  value,
                  setKey: setId,
                })
              }
            />
          </Wrapper>
        )}
      </Hoverable>
    </FormProvider>
  );
});
