import React, { useEffect, useRef, useState, CSSProperties } from "react";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_MEDIUM_GRAY,
  COLOR_SECONDARY_GREY,
  COLOR_SECONDARY_RED,
  COLOR_TEXT_TABLE_LABEL,
} from "../colors";

import { BasicButton } from "./Button";
import Hoverable from "./Hoverable";
import { UserIcon } from "./Icons";
import StyledText from "./StyledText";

interface Props {
  tab: Tab;
  setTab(item: Tab): void;
  tabs: (Tab & { isShared?: boolean; isVisible?: boolean })[];
  tabStyle?: CSSProperties;
  style?: CSSProperties;
  onScroll?: (_: any) => void;
  afterLastTabComponent?: React.ReactNode;
  spaceBetween?: number;
}

export type Tab = {
  id: string;
  type?: string;
  title: string;
  needsAttention?: boolean;
};

const UserIconWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 2px 8px;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ theme }) => (theme.isMobile ? "no-wrap" : "wrap")};
  max-width: 100%;
  overflow: auto;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TabText = styled(StyledText)<{
  isSelected?: boolean;
  isHovered?: boolean;
}>`
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${COLOR_TEXT_TABLE_LABEL};
  ${({ isHovered }) => (isHovered ? `color: ${COLOR_MEDIUM_GRAY};` : "")}
  ${({ isSelected }) => (isSelected ? `color: ${COLOR_BLUE};` : "")}
`;

const AfterLastTabComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: initial;
  margin-bottom: -13px;
  margin-left: 10px;
  border-bottom-color: ${COLOR_GRAY};
  border-bottom-width: 3px;
  border-bottom-style: solid;
`;

export const TabContainerWrapper = styled.div<{ maxWidthDecrease: number }>`
  display: flex;
  flex-direction: row;
  max-width: ${({ maxWidthDecrease }) => `calc(100% - ${maxWidthDecrease}px)`};
`;

const TabContainer = styled(BasicButton)<{
  isSelected?: boolean;
  hasSpaceBetween: boolean;
  spaceBetween?: number;
}>`
  flex-direction: row;
  align-items: end;
  margin: ${({ theme }) => (theme.isMobile ? "8px" : "8px 15px")};
  padding-bottom: ${({ theme }) => (theme.isMobile ? "6px" : "10px")};
  margin-right: ${({ hasSpaceBetween, spaceBetween }) =>
    hasSpaceBetween ? spaceBetween : "none"};
  border: 3px;
  border-bottom-style: solid;
  border-bottom-color: ${({ isSelected }) =>
    isSelected ? COLOR_BLUE : "transparent"};
  overflow: auto;
  max-width: 100%;
`;

const TabAttention = styled.div`
  width: 5px;
  height: 5px;
  background-color: ${COLOR_SECONDARY_RED};
  border-radius: 2.5px;
  right: -10px;
  top: -3px;
  position: absolute;
`;

const Tabs = ({
  tab,
  setTab,
  tabs,
  tabStyle,
  style,
  onScroll,
  afterLastTabComponent,
  spaceBetween,
}: Props) => {
  const ref = useRef();
  const [afterLastTabComponentIsHovered, setAfterLastTabComponentIsHovered] =
    useState<boolean>(false);

  useEffect(() => {
    if (ref && ref.current && onScroll) {
      // @ts-ignore
      ref.current.addEventListener("scroll", onScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TabsContainer style={style} ref={ref}>
      {tabs?.map((item, index) => {
        const { id, isVisible = true, title, isShared, needsAttention } = item;
        return (
          <Hoverable key={id}>
            {(isHovered) => (
              <TabContainerWrapper maxWidthDecrease={tabs?.length > 1 ? 20 : 0}>
                {isVisible ? (
                  <TabContainer
                    isSelected={id === tab?.id}
                    hasSpaceBetween={spaceBetween && index + 1 !== tabs?.length}
                    spaceBetween={spaceBetween}
                    onClick={() => setTab(item)}
                    style={tabStyle}
                  >
                    <TabText
                      isHovered={isHovered && !afterLastTabComponentIsHovered}
                      isSelected={id === tab?.id}
                    >
                      {title}
                    </TabText>
                    {needsAttention ? <TabAttention /> : null}
                    {isShared ? (
                      <UserIconWrapper>
                        <UserIcon
                          tintColor={
                            id === tab?.id
                              ? COLOR_BLUE
                              : isHovered && !afterLastTabComponentIsHovered
                                ? COLOR_MEDIUM_GRAY
                                : COLOR_SECONDARY_GREY
                          }
                        />
                      </UserIconWrapper>
                    ) : null}
                  </TabContainer>
                ) : null}
                {index === tabs.length - 1 && afterLastTabComponent && (
                  <Hoverable>
                    {(isHovered) => {
                      setAfterLastTabComponentIsHovered(isHovered);
                      return (
                        <AfterLastTabComponentWrapper>
                          {afterLastTabComponent}
                        </AfterLastTabComponentWrapper>
                      );
                    }}
                  </Hoverable>
                )}
              </TabContainerWrapper>
            )}
          </Hoverable>
        );
      })}
    </TabsContainer>
  );
};

export default Tabs;
