import { useCallback } from "react";

import { PracticeLine } from "../../components/Modals/CreateDrill/model";
import { useUserContext } from "../../contexts/User";
import {
  useAddDrillToPracticeMutation,
  useCreateInlinePracticeCommentMutation,
} from "../../graphql";

export function useSaveDrillOrComment() {
  const { sessionId, timezone, language } = useUserContext();

  const [addDrillToPracticeMutation] = useAddDrillToPracticeMutation();
  const [createInlinePracticeCommentMenu] =
    useCreateInlinePracticeCommentMutation();

  const saveDrillOrComment = useCallback(
    async (
      practiceLine: PracticeLine & { index?: number },
      index: number,
      practiceID: string,
      subPracticeID: string
    ) => {
      if (practiceLine.__typename === "Document") {
        const drillTemplateID = practiceLine.id.split(":").pop();

        if (drillTemplateID)
          await addDrillToPracticeMutation({
            variables: {
              sessionId,
              language,
              timezone,
              input: {
                practiceID,
                idx: (practiceLine.index ?? index) + 1,
                drillTemplateID,
                subPracticeID,
                minutes:
                  practiceLine?.localDuration ?? practiceLine?.minutes ?? null,
              },
            },
          });
      }

      if (practiceLine.__typename === "Drill") {
        await addDrillToPracticeMutation({
          variables: {
            sessionId,
            language,
            timezone,
            input: {
              practiceID,
              idx: (practiceLine.index ?? index) + 1,
              subPracticeID,
              templateName: practiceLine.name,
              minutes:
                practiceLine?.localDuration ?? practiceLine?.minutes ?? null,
            },
          },
        });
      }

      // Add comments
      if (practiceLine.__typename === "InlineComment" && practiceLine.txt) {
        await createInlinePracticeCommentMenu({
          variables: {
            sessionId,
            input: {
              practiceID,
              index: (practiceLine.index ?? index) + 1,
              text: practiceLine.txt,
              subPracticeID,
            },
          },
        });
      }
    },
    [
      sessionId,
      language,
      timezone,
      createInlinePracticeCommentMenu,
      addDrillToPracticeMutation,
    ]
  );

  const saveDrillOrCommentPracticeLines = useCallback(
    async ({
      practiceLines,
      practiceID,
      subPracticeID,
    }: {
      practiceLines: (PracticeLine & { index?: number })[];
      practiceID?: string;
      subPracticeID?: string;
    }) => {
      return Promise.all(
        practiceLines.map(async (practiceLine, index) =>
          saveDrillOrComment(
            practiceLine,
            practiceLine?.index ?? index,
            practiceID,
            subPracticeID
          )
        )
      );
    },
    [saveDrillOrComment]
  );

  return { saveDrillOrCommentPracticeLines, saveDrillOrComment };
}
