import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import styled from "styled-components";

import { Checkbox } from "../Checkbox";
import { Section } from "../Section";
import { SectionTitle } from "../SectionTitle";
import { DraggableLineWrapper } from "../styled";
import { StyledSwitch } from "../StyledSwitch";

import { CreateGroupTableFormValue } from "./models";
import { SwitchWrapper } from "./styled";

const LineWrapper = styled(DraggableLineWrapper)`
  justify-content: flex-start;
`;

const CheckboxWrapper = styled.div`
  display: flex;
`;

export const SummaryStatsSection = () => {
  const { t } = useTranslation();

  const { values, setFieldValue } =
    useFormikContext<CreateGroupTableFormValue>();

  return (
    <Section>
      <SectionTitle title={t("summaryStats")} />
      <LineWrapper>
        <SwitchWrapper>
          <StyledSwitch
            value={values.sumRow}
            onValueChange={(value) => setFieldValue("sumRow", value)}
            label={t("sumRow")}
          />
        </SwitchWrapper>
        <SwitchWrapper>
          <StyledSwitch
            value={values.averageRow}
            onValueChange={(value) => {
              setFieldValue("includeZeroValues", value);
              setFieldValue("includeEmptyValues", value);

              setFieldValue("averageRow", value);
            }}
            label={t("averageRow")}
          />
        </SwitchWrapper>
      </LineWrapper>
      {values.averageRow ? (
        <CheckboxWrapper>
          <Checkbox
            text={t("includeZeroValues")}
            check={(isChecked: boolean) =>
              setFieldValue("includeZeroValues", isChecked)
            }
            checked={values.includeZeroValues}
          />
          <Checkbox
            text={t("includeEmptyValues")}
            check={(isChecked: boolean) =>
              setFieldValue("includeEmptyValues", isChecked)
            }
            checked={values.includeEmptyValues}
          />
        </CheckboxWrapper>
      ) : null}
    </Section>
  );
};
