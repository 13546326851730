import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";

import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../contexts/accessLevels";
import { useUserContext } from "../contexts/User";
import {
  Group,
  GroupOrSubGroup,
  useGetTheGroupsImInListQuery,
} from "../graphql";

import { CustomTeamOption } from "./CustomTeamOption";
import { Label } from "./Label";
import { StyledSelect, StylesOverrides } from "./StyledSelect";

export type GroupValue = Partial<GroupOrSubGroup> &
  Pick<Group, "id" | "name" | "athletes" | "requiredAthletes"> &
  Partial<Pick<Group, "trainers" | "requiredTrainers">>;

export type GroupWithAccessValue = GroupValue &
  Partial<Pick<Group, "athleteAccess" | "trainerAccess" | "subGroups">>;

type Option = { label: string; value: GroupValue };

interface GroupSelectorProps {
  value: GroupValue | null;
  onChange(option: GroupValue): void;
  accountId?: string | null;
  hideLabel?: boolean;
  unsetWidth?: boolean;
  disableSearchable?: boolean;
  stylesOverrides?: StylesOverrides;
  shouldLoadAllGroups?: boolean;
  shouldDisplaySubgroups?: boolean;
  requireEditability?: boolean;
  requireStatisticsAccess?: boolean;
}

// Function to group options by ownerName
const groupBy = (array, key) => {
  return array.reduce((result, item) => {
    (result[item[key]] = result[item[key]] || []).push(item);
    return result;
  }, {});
};

const Wrapper = styled.div<{ unsetWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ theme, unsetWidth }) => {
    if (unsetWidth) {
      return "auto";
    }

    return !theme.isMobile ? "260px" : "auto";
  }};
`;

function GroupSelector({
  value,
  onChange,
  hideLabel = false,
  unsetWidth = false,
  disableSearchable = false,
  stylesOverrides,
  shouldDisplaySubgroups = true,
  requireEditability = false,
  requireStatisticsAccess,
  accountId,
}: GroupSelectorProps) {
  const { t } = useTranslation();
  const { sessionId, language } = useUserContext();

  const [searchParams] = useSearchParams();
  const allowedGroupsSearch = searchParams.get("allowedGroups");
  const { selectedAccount } = useAccessLevelsContext();

  const selectedAccountId = accountId ?? selectedAccount?.id;

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const {
    data: { getTheGroupsImIn } = {},
    refetch,
    loading: loadingGroups,
  } = useGetTheGroupsImInListQuery({
    variables: {
      sessionId,
      accountId:
        selectedAccountId !== ALL_ACCOUNTS_ID ? selectedAccountId : undefined,
      language,
      requireEditability,
      requireStatisticsAccess,
    },
    fetchPolicy: "cache-first",
    skip: !sessionId,
  });

  const allowedGroups = useMemo(
    () => (allowedGroupsSearch ? allowedGroupsSearch.split(",") : []),
    [allowedGroupsSearch]
  );

  const options = useMemo(() => {
    return (
      (allowedGroups?.length
        ? getTheGroupsImIn?.filter((group) => allowedGroups?.includes(group.id))
        : getTheGroupsImIn
      )?.map((group) => {
        if (group.subGroups?.length) {
          return {
            value: group,
            label: group.name,
            isGroup: true,
            options: group.subGroups,
          };
        }

        return {
          value: group,
          label: group.name,
          owner: group.owner,
          isGroup: true,
        };
      }) ?? []
    );
  }, [getTheGroupsImIn, allowedGroups]);

  const selectOptions = useMemo(
    () =>
      options?.flatMap(({ value, label, options }) => [
        {
          value: value.id,
          label,
          ownerName: value?.owner?.name,
          isGroup: true,
        },
        ...(options?.length && shouldDisplaySubgroups
          ? options.map(({ id, name, owner }) => ({
              value: id,
              label: name,
              ownerName: owner?.name,
              isSubGroup: true,
            }))
          : []),
      ]) ?? [],
    [options, shouldDisplaySubgroups]
  );

  const handleChange = useCallback((option: Option) => {
    onChangeRef.current(option?.value);
  }, []);

  const onSelectChange = useCallback(
    (value: string) => {
      const newValue =
        [
          ...options,
          ...options.flatMap((option) =>
            option.options?.map((subOption) => ({
              value: subOption,
              label: subOption.name,
            }))
          ),
        ].find((option) => option?.value?.id === value) ?? null;

      handleChange(newValue as any);
    },
    [handleChange, options]
  );

  const groupedOptions = groupBy(selectOptions, "ownerName");

  const groupedSelectOptions = Object.keys(groupedOptions).map(
    (group, key) => ({
      id: key,
      label: group,
      options: groupedOptions[group],
    })
  );

  useEffect(() => {
    refetch({
      sessionId,
      language,
      accountId:
        selectedAccountId !== ALL_ACCOUNTS_ID ? selectedAccountId : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, selectedAccountId]);

  const x =
    selectedAccount?.id !== ALL_ACCOUNTS_ID
      ? selectOptions
      : groupedSelectOptions;

  return (
    <Wrapper unsetWidth={unsetWidth}>
      {!hideLabel && <Label>{t("team").toUpperCase()}</Label>}
      <StyledSelect
        options={x}
        value={value?.id}
        loading={loadingGroups}
        onChange={onSelectChange}
        components={{ Option: CustomTeamOption }}
        stylesOverrides={stylesOverrides}
        disableSearchable={disableSearchable}
      />
    </Wrapper>
  );
}

export default GroupSelector;
