import React from "react";

import { COLOR_SECONDARY_GREY } from "../../colors";
import { PlusIcon } from "../Icons";

import PlaceholderOptions from "./PlaceholderOptions";

export function PlaceholderWidget({
  report,
  generalWidgets,
  layoutIndex,
  readOnly,
  mode,
  activeTabId,
  columnXPosition,
  columnYPosition,
}) {
  return (
    <PlaceholderOptions
      generalWidgets={generalWidgets}
      readOnly={readOnly}
      report={report}
      layoutIndex={layoutIndex}
      iconComponent={
        <PlusIcon
          style={{ transform: `scale(1.6)` }}
          tintColor={COLOR_SECONDARY_GREY}
        />
      }
      mode={mode}
      activeTabId={activeTabId}
      columnXPosition={columnXPosition}
      columnYPosition={columnYPosition}
    />
  );
}
