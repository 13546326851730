import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GRAY } from "../../colors";
import { useAccessLevelsContext } from "../../contexts/accessLevels";
import { useNotificationsContext } from "../../contexts/notifications";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { TimeIcon } from "../Icons";
import { Loader } from "../Loader";
import {
  SessionType,
  useSessionDetailQuery,
} from "../Session/hooks/useSessionDetailQuery";
import { SessionLines } from "../SessionPlan/SessionLines";
import StyledText from "../StyledText";

import { LibraryHeader } from "./LibraryHeader";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SessionWrapper = styled.div`
  background-color: ${COLOR_GRAY};
  padding: 15px;
`;

const NoContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export function SessionDetail({ session, onBack, isTeamPractice }) {
  const {
    actions: { closeTopModal },
  } = useTopModalContext();
  const { actions } = useModalContext();
  const { t } = useTranslation();
  const { showWarningNotification } = useNotificationsContext();
  const { getTeamSessionsRight, selectedAccount } = useAccessLevelsContext();
  const { loading, data } = useSessionDetailQuery({
    sessionType: SessionType.Workout,
    sessionGuid: session?._guid,
  });
  const user = useUserContext();

  const hasWorkoutLines =
    data && "workout" in data && data?.workout?.workoutLines;

  const time = session?._durationMinutes
    ? t("minutesDuration", { x: session._durationMinutes })
    : null;

  if (loading) {
    return (
      <NoContentWrapper>
        <Loader />
      </NoContentWrapper>
    );
  }

  const onAddPress = () => {
    const { edit } = getTeamSessionsRight(selectedAccount?.id);

    if (!edit && isRealTrainer(user)) {
      const label = isTeamPractice
        ? "cannotCreateTeamSession"
        : "cannotCreateAthleteSession";

      return showWarningNotification(t(label));
    }

    actions.closeModal();
    closeTopModal();
    actions.openModal({
      modal: ModalType.ADD_TO_CALENDAR,
      title: t("addToCalendar"),
      onBack: () => onBack(),
      params: {
        title: hasWorkoutLines ? data?.workout?.label : "",
        period: time,
        isTeamPractice,
        isProgram: false,
        workoutLines: hasWorkoutLines ? data?.workout?.workoutLines : [],
        sessionGuid: session?._guid,
      },
    });
  };

  const workoutLinesLength =
    data && "workout" in data && data?.workout?.workoutLines?.length;

  return (
    <Wrapper>
      <LibraryHeader
        title={hasWorkoutLines && data?.workout?.label}
        icon={<TimeIcon />}
        period={time}
        description={hasWorkoutLines && data?.workout?.notes}
        onAdd={onAddPress}
      />

      {workoutLinesLength ? (
        <SessionWrapper>
          <SessionLines
            onBack={onBack}
            data={data.workout.workoutLines}
            hideActionButtons
          />
        </SessionWrapper>
      ) : (
        <NoContentWrapper>
          <StyledText>{t("noContentAvailable")}</StyledText>
        </NoContentWrapper>
      )}
    </Wrapper>
  );
}
