import dayjs, { Dayjs } from "dayjs";

import {
  COLOR_GREEN_EVENT_DEFAULT,
  COLOR_ORANGE_GAME_DEFAULT,
} from "../../colors";
import { ModalType } from "../../contexts/UI/Modal";
import { TopModalType } from "../../contexts/UI/TopModal";
import { BASE_ROUTES, ROUTING_CONFIG } from "../../router/RoutingConfig";
import { convertUnixToHoursMinutes } from "../../utils/date";
import { EventType } from "../Filters/model";

// const ONE_HOUR = 3600000;

export const EXCLUDED_AUTO_CLOSE_MODAL_LIST = [
  ModalType.CREATE_PRACTICE_SESSION,
  ModalType.CREATE_EVENT_OR_GAME,
  TopModalType.ADD_RESOURCE,
  ModalType.TERMS,
];

export function rgbToHex(rgb: string) {
  const [r, g, b] = rgb?.split(",");

  const hexR = Number(r).toString(16).padStart(2, "0");
  const hexG = Number(g).toString(16).padStart(2, "0");
  const hexB = Number(b).toString(16).padStart(2, "0");

  return `#${hexR}${hexG}${hexB}`;
}

export const createEventOrGameModalDefaultValues = (
  session?: any,
  eventType?: EventType,
  selectedStartDate?: Date
) => {
  const today = dayjs();

  const getDefaultColor = () => {
    if (eventType === EventType.EVENT || eventType === EventType.TEAM_EVENT) {
      return COLOR_GREEN_EVENT_DEFAULT;
    }

    if (eventType === EventType.GAME || eventType === EventType.TEAM_GAME) {
      return COLOR_ORANGE_GAME_DEFAULT;
    }
  };

  const startTime = selectedStartDate?.getTime();
  const selectedStartTime =
    startTime && convertUnixToHoursMinutes(startTime) === "00:00"
      ? null
      : startTime;

  return {
    title: session ? session.label : "",
    startDate: session
      ? dayjs(session.start)
      : selectedStartDate
        ? dayjs(selectedStartDate)
        : today,
    startTime: session
      ? convertUnixToHoursMinutes(session.start)
      : selectedStartTime
        ? convertUnixToHoursMinutes(selectedStartTime)
        : "09:30",
    durationMinutes: session?.duration ?? session?.durationMinutes ?? 0,
    attendance: session ? session.attendedMinutesBefore : "",
    color: session ? rgbToHex(session.rgb) : getDefaultColor(),
    location: session ? session.location : "",
    locationDescription: session ? session.xtraLocation : "",
    notes: session ? session.notes : "",
  };
};

export function getTime(startDate: Dayjs | number) {
  let rawHour = 0;
  let rawMinute = 0;

  if (typeof startDate === "number") {
    rawHour = dayjs(startDate).get("hour");
    rawMinute = dayjs(startDate).get("minute");
  } else {
    rawHour = startDate.get("hour");
    rawMinute = startDate.get("minute");
  }

  const hours = `${rawHour < 10 ? "0" : ""}${rawHour}`;
  const minutes = `${rawMinute < 10 ? "0" : ""}${rawMinute}`;

  return `${hours}:${minutes}`;
}

export const MODALS_ALLOWED_ROUTES = {
  [ModalType.CREATE_PRACTICE_SESSION]: [
    ROUTING_CONFIG.session,
    ROUTING_CONFIG.calendar,
    ROUTING_CONFIG.teamsAthlete,
  ],
  [ModalType.USER_PROFILE]: [
    ROUTING_CONFIG.AthleteTeam,
    ROUTING_CONFIG.session,
    BASE_ROUTES.Athletes,
    ROUTING_CONFIG.teamsAthlete,
  ],
};

export const TOP_MODALS_ALLOWED_ROUTES = {
  [TopModalType.SELECT_ATHLETES_FROM_GROUP]: [
    ROUTING_CONFIG.session,
    ROUTING_CONFIG.calendar,
  ],
};

export const getMinWidth = (requestedMinWidth: number): string => {
  const viewportWidth = window.innerWidth;
  const ninetyPercentViewportWidth = viewportWidth * 0.9;

  return ninetyPercentViewportWidth < requestedMinWidth
    ? "90vw"
    : `${requestedMinWidth}px`;
};

export const getMaxWidth = (requestedMaxWidth: number): string => {
  const viewportWidth = window.innerWidth;
  const ninetyPercentViewportWidth = viewportWidth * 0.9;

  return ninetyPercentViewportWidth > requestedMaxWidth
    ? `${requestedMaxWidth}px`
    : "90vw";
};

export function getSessionStart(timestamp: number) {
  if (!timestamp) {
    return null;
  }

  const sessionDate = new Date(timestamp);
  const hours = sessionDate.getHours();
  const minutes = sessionDate.getMinutes();

  return { hours, minutes };
}

export function setDuplicateSessionStart({
  timestamp,
  originalTimestamp,
  hours,
  minutes,
}: {
  timestamp: number;
  originalTimestamp: number;
  hours: number;
  minutes: number;
}) {
  if (!timestamp) {
    return null;
  }

  if (originalTimestamp === timestamp) {
    return originalTimestamp;
  }

  const sessionDate = new Date(timestamp);

  sessionDate.setHours(hours);
  sessionDate.setMinutes(minutes);

  return sessionDate.getTime();
}
