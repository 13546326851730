export enum ModalActionType {
  OPEN = "MODAL/OPEN",
  CLOSE = "MODAL/CLOSE",
  RENAME = "MODAL/RENAME",
}

export enum ModalType {
  SETTINGS = "Settings",
  CONFIRM = "Confirm",
  ATTENDANCE = "Attendance",
  CREATE_NEW_CHAT = "CreateNewChat",
  ATHLETES_SELECTOR = "AthletesSelector",
  CREATE_NEW_GROUP_CHAT = "CreateNewGroupChat",
  EDIT_GROUP_CHAT = "EditGroupChat",
  EDIT_TEAM_CHAT = "EditTeamChat",
  DOCUMENT = "Document",
  COLLECTIONS = "Collections",
  RENAME_WIDGET = "RenameWidget",
  EDIT_ATHLETE_PROFILE_TABS = "EditAthleteProfileTabs",
  CREATE_WIDGET = "CreateWidget",
  MEDIA = "Media",
  ADD_TAB_REPORT_MODAL = "AddTabReportModal",
  ADD_ATHLETE_HEADER_INFO_MODAL = "AddAthleteHeaderInfoModal",
  PRINT_GROUP_REPORT = "PrintGroupReport",
  PRINT_ATHLETE_REPORT = "PrintAthleteReport",
  EDIT_USER_PROFILE = "EditUser",
  USER_PROFILE = "User",
  SEEN_BY = "SeenBy",
  SWITCH_ACCOUNTS = "SwitchAccounts",
  LOGIN = "Login",
  ATHLETE_TRAINING_STATUS = "AthleteTrainingStatus",
  CREATE_EVENT_OR_GAME = "CreateEventOrGame",
  RESOURCE = "Resource",
  CREATE_PRACTICE_SESSION = "CreatePracticeSession",
  QUESTIONNAIRE = "Questionnaire",
  COLLECTION_QUESTIONNAIRE = "CollectionQuestionnaire",
  SESSION_DETAIL = "SessionDetail",
  PROGRAM_ITEM_WORKOUT = "ProgramItemWorkout",
  FORGOT_PASSWORD = "ForgotPassword",
  TERMS = "Terms",
  INVITATIONS = "Invitations",
  SESSION_PAYMENT = "SessionPayment",
  SAVE_GROUP = "SaveGroup",
  ADD_COACH_OR_ATHLETE_TO_GROUP = "AddCoachOrAthleteToGroup",
  DUPLICATE_SESSION = "DuplicateSession",
  DUPLICATE_WORKOUT_SELECT_ATHLETE = "DuplicateWorkoutSelectAthlete",
  SELECT_SESSION_GROUP = "SelectSessionGroup",
  SEND_MANUAL_NOTIFICATION = "SendManualNotification",
  SELECT_ATHLETES_FROM_GROUP = "SelectAthletesFromGroup",
  ADD_TO_CALENDAR = "AddToCalendar",
  WIDGET_FOLDER_STRUCTURE = "WidgetFolderStructure",
}
