import React, { useEffect, useState, useRef } from "react";
import { TFunction, useTranslation } from "react-i18next";

import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_GRAY } from "../../../colors";
import { usePracticeContext } from "../../../contexts/practice/PracticeProvider";
import Tabs, { Tab } from "../../Tabs";

import { DroppableArea } from "./DroppableArea";
import { TabLabel } from "./model";
import { PlanTabSubHeader } from "./PlanTabSubHeader";
import { SpecificPracticeLine } from "./SpecificPracticeLine";

type PlanListProps = {
  isSectionHidden: boolean;
  toggleSectionVisibility: (hide: boolean) => void;
  canChangeOnScroll: boolean;
  isSomeFileDragging: boolean;
};

interface PlanListContentProps {
  localEndTime: dayjs.Dayjs;
}

const TabsWrapper = styled.div`
  padding-left: 15px;
`;

const PlanListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div<{ isSectionHidden: boolean }>`
  background-color: ${COLOR_GRAY};
  width: 100%;
  height: ${({ isSectionHidden }) =>
    `calc(96vh - ${isSectionHidden ? 275 : 440}px)`};
  min-height: 250px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  transition:
    height 0.6s,
    padding-top 0.6s;
  padding-top: ${({ isSectionHidden }) => (isSectionHidden ? 30 : 0)}px;
`;

function getTabs(t: TFunction) {
  return [
    { id: TabLabel.PLAN, title: t("plan").toUpperCase() },
    // { id: TabLabel.ATTACHMENTS, title: t("attachments").toUpperCase() },
    // { id: TabLabel.FORMS, title: t("forms").toUpperCase() },
  ];
}

export const PlanListContent = ({ localEndTime }: PlanListContentProps) => {
  const { practiceLines } = usePracticeContext();

  return (
    <>
      {practiceLines?.map((practiceLine, index) => {
        const duration = practiceLine.localDuration || practiceLine?.minutes;
        const durationToAdd = dayjs.duration(duration, "minute");

        return (
          <SpecificPracticeLine
            key={`${practiceLine.id}-${index}`}
            practiceLine={{
              ...practiceLine,
              endTime: localEndTime.add(durationToAdd).format("HH:mm"),
            }}
            index={index}
          />
        );
      })}
    </>
  );
};

export function PlanList({
  isSectionHidden,
  toggleSectionVisibility,
  canChangeOnScroll,
  isSomeFileDragging,
}: PlanListProps) {
  const { t } = useTranslation();
  const { practiceSession, selectedSlot } = usePracticeContext();

  const tabs = getTabs(t);
  const [tab, setTab] = useState<Tab>(tabs[0]);
  const [isOnInitialOnTop, setIsOnInitialOnTop] = useState(false);

  const contentRef = useRef(null);

  const onScroll = (e) => {
    if (canChangeOnScroll && !isSomeFileDragging) {
      if (e.currentTarget.scrollTop > 30) {
        if (!isSectionHidden) {
          toggleSectionVisibility(true);
        }
      } else if (e.currentTarget.scrollTop === 0 && isSectionHidden) {
        toggleSectionVisibility(false);
      }
    }
  };

  const scrollToItem = (e: any) => {
    if (e?.detail?.id) {
      const timer = setTimeout(() => {
        const element = document.getElementById(`planItem-${e.detail.id}`);
        if (element) {
          element.scrollIntoView();
          return () => clearTimeout(timer);
        }
      }, 100);
    }
  };

  useEffect(() => {
    document.addEventListener("movePracticeLine", scrollToItem);
    return () => document.removeEventListener("movePracticeLine", scrollToItem);
  }, []);

  const startDate = practiceSession?.start || selectedSlot?.start;

  const localEndTime = dayjs(startDate);

  useEffect(() => {
    if (!isOnInitialOnTop && canChangeOnScroll) {
      contentRef.current.scrollTo({ top: 0 });
      setIsOnInitialOnTop(true);
    }
  }, [isOnInitialOnTop, canChangeOnScroll]);

  return (
    <PlanListWrapper>
      <TabsWrapper>
        <Tabs
          tab={tab}
          tabs={tabs}
          setTab={setTab}
          tabStyle={{ marginBottom: 0 }}
        />
      </TabsWrapper>
      {tab.id === TabLabel.PLAN && (
        <>
          <PlanTabSubHeader />
          <ContentWrapper
            ref={contentRef}
            onScroll={onScroll}
            isSectionHidden={isSectionHidden}
          >
            <PlanListContent localEndTime={localEndTime} />
            <DroppableArea />
          </ContentWrapper>
        </>
      )}
    </PlanListWrapper>
  );
}
